import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-apple-payment",
  templateUrl: "./apple.payment.component.html",
})
export class ApplePaymentComponent implements OnInit {
  stripe: any;
  elements: any;
  isPaymentAvailable: boolean = true;
  publish_key='pk_test_51Q2ZvsL2ArYn1w1B9tZurNc4H3pzivAUWfKw2rYO0MecOyQ7eieYYeCxD2nODUN1LK14G7RsexIYEo2fyfnSNxEZ00gJ4W8MKV';

  ngOnInit(): void {
    if (typeof window !== 'undefined' && (window as any).Stripe) {
      this.initializeStripe();
    } else {
      // Cargar el SDK de Stripe de forma asincrónica si no está disponible inmediatamente
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => this.initializeStripe();
      document.body.appendChild(script);
    }
  }

  initializeStripe(): void {
    const stripe = (window as any).Stripe(this.publish_key);
    this.stripe = stripe;
    this.elements = stripe.elements();
    const paymentRequest = stripe.paymentRequest({
      currency: 'usd',
      country: 'US',
      requestPayerName: true,
      requestPayerEmail: true,
      total: {
        label: 'Demo total',
        amount: 1000,
      }
    });
    const prButton = this.elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest
    })

    paymentRequest.canMakePayment().then((result: any) => {
      if (result) {
        this.isPaymentAvailable = true;
        prButton.mount('#payment-request-button');
      }
    });
  }
}