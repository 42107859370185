import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { UserCreateConstraints, UserModel } from '../models/user.model';
import { UserService } from './user.service';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserRoles } from '../data/user.roles.data';
import { PaymentCreateComponent } from '../payment/payment.create.component';
import { NotificationService } from '../common/notification.service';
import {Location} from '@angular/common';
import { StorageService } from '../common/storage.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user.create.component.html',
})
export class UserCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;
  @ViewChild('paymentCreate', { static: false }) paymentCreate: PaymentCreateComponent;

  createRules: GenericFormType[] = UserCreateConstraints;
  stripeValid = false;
  createInProgress: boolean;
  isCreditCardFormOpen: boolean;
  returnUrl: string;
  hidePaymentInfo: boolean;
  hideHeader: boolean;
  userToVerify: {returnUrl: string, user: UserModel, phone?: string, email?: string, userFromGoogle?: any};
  signUpOptions: {verificationCode: string, wasPotential: boolean};

  options: any = {
    hidePostalCode: true
  };

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private location: Location,
    private storageService: StorageService,
  ) { }

  ngAfterViewInit(): void {
    const { phone, email, user, userFromGoogle } = this.userToVerify;
    this.genericForm.myForm.patchValue({
      phone: phone || user && user.phone || '', 
      email: email || user && user.email || '', 
      firstName: user && user.firstName || userFromGoogle && userFromGoogle.given_name || '',
      lastName: user && user.lastName || userFromGoogle && userFromGoogle.family_name || '',
    });
    if(phone) {
      this.genericForm.myForm.get('phone').disable();
    }
    if(email) {
      this.genericForm.myForm.get('email').disable();
    }
  }

  ngOnInit() {
    this.createInProgress = false;
    this.stripeValid = true;
    this.isCreditCardFormOpen = false;
    this.returnUrl =  this.route.snapshot.queryParams.returnUrl || '/search';
    this.hidePaymentInfo = !!this.route.snapshot.data.hidePayment;
    this.hideHeader = !!this.route.snapshot.data.hideHeader;
    this.userToVerify = this.storageService.getItem('userToVerify') || {};
    this.signUpOptions = this.storageService.getItem('signUpOptions') || {};
  }

  toggleCreditCard() {
    this.isCreditCardFormOpen = !this.isCreditCardFormOpen;
  }

  goBack() {
    this.location.back();
  }

  async create() {
    const accountFormValid = this.genericForm.isValid();
    if (!accountFormValid) {
      return null;
    }
    this.createInProgress = true;
    const user: UserModel = this.genericForm.toModel<UserModel>('UserCreateForm', 'UserModel');
    if(this.genericForm.myForm.value.phone) {
      const { nationalNumber, dialCode } = this.genericForm.myForm.value.phone;
      user.phone = this.composePhoneNumber({nationalNumber, dialCode})
    }
    if (this.isCreditCardFormOpen) {
      const paymentFormValid = this.paymentCreate.isValid();
      if (paymentFormValid) {
        const stripeSource = await this.paymentCreate.getPaymentSource(`${user.firstName} ${user.lastName}`);
        if (!stripeSource) {
          this.createInProgress = false;
          this.notificationService.warning('Invalid card', 'Something went wrong, card can not be verified please try again later');
          return null;
        }
        user.payment = {
          source: stripeSource,
        };
      }
    }
    try {
      user.role = UserRoles.REGULAR;
      user.username = user.email;
      user.wasPotential = this.signUpOptions.wasPotential;
      user.phoneNumberVerified = true

      await this.userService.create(user);
      await this.authService.loginWithPhoneOrEmail(user.email, user.phone, this.signUpOptions.verificationCode);
      this.router.navigateByUrl(this.returnUrl);

      this.storageService.deleteItem('userToVerify');
      this.storageService.deleteItem('signUpOptions');
    } catch (err) {
      this.createInProgress = false;
    }
  }
  composePhoneNumber = (phoneNumber: {
    nationalNumber: string;
    dialCode: string;
  }) => {
    return `${phoneNumber.dialCode}${phoneNumber.nationalNumber.replace(/\D/g, "")}`;
  };
}
