import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { LoginComponent } from '../../login/login.component';
import { AuthService } from 'src/app/common/auth.service';
import { UserModel } from 'src/app/models/user.model';
import { MobileService } from '../mobile.service';
import { EventService } from 'src/app/event/event.service';
import { IEvent } from '../price.service';
import { EventModel } from 'src/app/models/event.model';
import { NotificationPopupComponent } from 'src/app/common/notification.popup.component';
import { StorageService } from 'src/app/common/storage.service';
import { SessionStorageService } from 'src/app/common/sessionStorage.service';

@Component({
  selector: 'app-mobile-ordering-home',
  templateUrl: './home.component.html',
  styleUrls: ['../mobile-ordering.styles.scss']
})
export class MobileOrderingHomeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private mobileService: MobileService,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService,
        private eventService: EventService,
  ) { }

  playStoreUrl = 'https://play.google.com/store/apps/details?id=com.cuculi.p.mobile';
  appStoreUrl = 'https://apps.apple.com/us/app/cuculi/id1490415047?mt=8';
  table: string;
  venueSlug: string;
  loggedUser: UserModel;
  eventId: string;
  event: any;

  async ngOnInit() {
    this.table = this.route.snapshot.paramMap.get('table');
    this.venueSlug = this.route.snapshot.paramMap.get('venueSlug');
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.loggedUser = this.authService.getCurrentUser();
    this.event = await this.eventService.getWebEvent(this.eventId);
    
    if(!this.loggedUser) {
      const userData = this.storageService.getItem('userData')
      if(userData && !this.isParticipant(userData.email)){
        this.storageService.deleteItem('userData')
      }else {
        this.loggedUser = userData
      }
    }
    if (this.route.snapshot.fragment) {
      this.eventId = this.route.snapshot.fragment.slice(-24);
    }
    this.mobileService.eventId = this.eventId;
    
  }

  isParticipant(email: string) {
    return this.event.participants.some((x) => x.email === email) || 
          (this.event.potentialUsers && this.event.potentialUsers.some((x) => x.email === email) ) || 
          false;
  }

  openMenuScreen() {
    const orderAsGuest = this.isCurrentUserGuest()
    if (this.event && this.event.entranceFee && this.event.tickets) {
      this.toggleNotificationPopup(orderAsGuest);
      return;
    }
    this.sessionStorageService.deleteItem('temporary-user')
    this.router.navigateByUrl(`/mobile-ordering/menu/${this.venueSlug}/${this.table}/${this.eventId}`);
  }

  toggleNotificationPopup(orderAsGuest) {
    const initialState = {
      title: "*For this event, a ticket purchase is required",
      text: "If you\'ve already bought a ticket, please enter the same email address you used during your purchase.",
      textTwo: " ",
      confirmText: "Continue",
      cancelText: "Go to purchase ticket screen",
      buttonCallback: () => {
        if (orderAsGuest && !this.isCurrentUserGuest()) {
          this.authService.logout();
        }
        this.router.navigateByUrl(`/mobile-ordering/menu/${this.venueSlug}/${this.table}/${this.eventId}`);
      },
      cancelCallback: () => {
        this.router.navigate([`/events/${this.event.eventId}/tickets`], {
          queryParams: {
            from: 'qrScreen',
          }
        });
      }
    };
    const bsModalRef = this.modalService.show(NotificationPopupComponent, {
      initialState,
    });
    bsModalRef.setClass("notification-popup-modal");
  }

  redirectToAppStore() {
    const platform = this.getMobileOperatingSystem();

    if (platform && platform === 'Android') {
      window.open(this.playStoreUrl, '_self');
    }

    if (platform && platform === 'IOS') {
      window.open(this.appStoreUrl, '_self');
    }
  }

  isCurrentUserGuest() {
    return !this.loggedUser || this.loggedUser.role.toLowerCase() === 'guest';
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'IOS';
    }
  }
}
