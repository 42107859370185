import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { VenueService } from './venue.service';
import { VenueModel } from '../models/venue.model';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';
import { MenuService } from '../menu-item/menu.service';
import { MenuItemModel } from '../models/menu-item.model';
import { VenueBookingComponent } from './venue.booking.component';
import { UserRoles } from '../data/user.roles.data';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationService } from '../common/notification.service';
import { MenuModel } from '../models/menu.model';
import { EnumService } from '../common/enum.service';
import { FeatureTypes } from '../data/features.types.data';
import * as Moment from 'moment';
import { EventModel } from '../models/event.model';
import { extendMoment } from 'moment-range';
import { GetTheAppPopupComponent } from '../home/get-the-app-popup.component';
import { neighborhood } from 'src/configs/neighborhood';

const moment = extendMoment(Moment);

@Component({
   selector: 'app-venue-profile',
   templateUrl: './venue.profile.component.html',
   styleUrls: ['./venue.profile.component.scss']
})
export class VenueProfileComponent implements OnInit, AfterViewInit, OnDestroy {

   @Output() created: EventEmitter<EventModel> = new EventEmitter();

   @ViewChild('gmap', { static: false }) gmapElement: any;
   @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

   map: google.maps.Map;
   venue: VenueModel;
   schedule: any[];
   events: any[];
   menuTabKeys: MenuModel[];
   marker: any;
   bsModalRef: BsModalRef;
   currentUser: UserModel;
   menues: MenuModel[];
   lowestPrice: number;
   updateAvailabilityInProgress: boolean;
   showMenuInfo: boolean;
   currentTab: number;
   menueTabs = [];
   venueFeatures: any;
   venueNeighborhood: string;
   featureTypes: any;
   routeSubscription: any;
   seatNumber = 1;
   createTableDays: any;
   createTableTimeSlots: any;
   createInProgress: boolean;
   getTheAppContent = {
      containerClass: 'download-container-venue',
      text: 'You can also ORDER & PAY by ',
      boldText: 'downloading the app',
      buttonText: 'Download here!'
   };
   menusLoading = true;
   private days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

   constructor(
      private venueService: VenueService,
      private route: ActivatedRoute,
      private menuService: MenuService,
      private authService: AuthService,
      private modalService: BsModalService,
      private notificationService: NotificationService,
      private enumService: EnumService,
      private cr: ChangeDetectorRef,
      private router: Router,
   ) { }

   ngOnInit() {}
   async ngAfterViewInit() {
      const mapProp = {
         center: new google.maps.LatLng(44.787197, 20.457273),
         zoom: 13,
         mapTypeId: google.maps.MapTypeId.ROADMAP,
         disableDefaultUI: true,
         streetViewControl: false,
         disableDoubleClickZoom: true,
         zoomControl: true,
         scrollwheel: false
      };
      this.currentUser = this.authService.getCurrentUser();
      const slug: string = this.route.snapshot.paramMap.get('slug');
      this.venue = await this.venueService.getBySlug(slug);
      this.cr.detectChanges();
      this.schedule = this.buildVenueSchedule();
      this.updateAvailabilityInProgress = false;
      this.venueFeatures = {};
      this.featureTypes = FeatureTypes;
      this.menusLoading = true;
      await this.getFeatures();
      await this.loadMenus();
      await this.getMenuTypes();
      await this.getNeighborhood();
      this.showMenuInfo = false;
      this.currentTab = -1;
      setTimeout(() => {
         if (this.gmapElement) {
            this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
            this.prepareMapLocation();
         }
      }, 200);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.routeSubscription = this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd) {
            this.router.navigated = false;
         }
      });
      if (window.innerWidth > 1024 && !!this.staticTabs) {
         this.staticTabs.type = 'pills';
      }
   }

   ngOnDestroy() {
      this.routeSubscription = null;
   }

   getTourAlias(tourName: string) {
      return tourName.toLowerCase().replace(/ /g, '-');
   }

   checkVenueFeatures() {
      return Object.keys(this.venueFeatures).length;
   }

   getVenueName(name: string) {
      const maxLength = window.innerWidth > 1199 ? 29 : 25;
      if (name.length > 32) {
         const shorterName = name.substring(0, maxLength) + '...';
         return shorterName;
      }
      return name;
   }

   openGetTheAppPopup() {
      const initialState = {
        appType: 'user-app'
      };
      const bsModalRef = this.modalService.show(GetTheAppPopupComponent, { initialState });
      bsModalRef.setClass('modal-lg');
    }


   async getFeatures() {
      if (!this.venue.features || !this.venue.features.length) {
         return null;
      }
      const features = await this.enumService.getByTypes(['features-general', 'features-food', 'features-good-for', 'features-parking']);
      features.forEach(feature => {
         if (this.venue.features.indexOf(feature.key) !== -1) {
            if (!this.venueFeatures.hasOwnProperty(feature.type)) {
               this.venueFeatures[feature.type] = [feature.value];
            } else {
               this.venueFeatures[feature.type].push(feature.value);
            }
         }
      });
      return this.venueFeatures;
   }

   isMobile() {
      const userAgent = navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
         return true;
      }
      return false;
   }

   async getMenuTypes() {
      this.menusLoading = true;
      const menuTypes = await this.menuService.getMenuTypes(this.venue.id, true);
      menuTypes
      .filter((menuType) => menuType.disabled === false && menuType.menuItems > 0)
       .forEach(element => {
         const items = this.getMenuItems(element.key);
         const menu = this.menues.filter(item => item. type === element.key)[0];
         if (items && items.length && this.doesMenuContainItems(items[0])) {
            this.menueTabs.push({
               key: element.key,
               label: element.value,
               items,
               priority: menu.priority
            });
         }
      });
      this.menusLoading = false;
   }

   async loadMenus() {
      const allMenues = await this.menuService.getAllMenues(this.venue.id, false);
      this.menues = allMenues
      .filter(m => !m.disabled)
      .sort((a, b) => {
         if (a.priority > b.priority) {
            return 1;
         }
         if (b.priority > a.priority) {
            return -1;
         } else {
            return 0;
         }
      });
   }

   formatVenueCategories(categories) {
      let venueCategories = '';
      categories.forEach((el: string) => {
         const z = el.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
         venueCategories += `${z}, `;
      });
      if (venueCategories.length > 33) {
         venueCategories = venueCategories.slice(0, 33) + '...';
      } else {
         venueCategories = venueCategories.slice(0, venueCategories.length - 2);
      }
      return venueCategories;
   }

   toggleMenuInfo(i) {
      if (this.currentTab !== i) {
         this.currentTab = i;
         this.showMenuInfo = true;
      } else {
         this.showMenuInfo = !this.showMenuInfo;
      }
   }

   getLowestPrice(menu: MenuItemModel) {
      const prices = [];
      if (menu.options) {
         menu.options.forEach((option) => {
            if (option.price) {
               prices.push(option.price.value);
            }
         });
      }
      return Math.min(...prices);
   }

   async getNeighborhood() {
      // tslint:disable-next-line:max-line-length
      const neighborhoods = await this.enumService.getByTypes(neighborhood);
      if (!neighborhoods || !this.venue.neighborhood) {
         return this.venueNeighborhood = 'New York';
      }
      const element = neighborhoods.find((n) => n.key === this.venue.neighborhood);
      this.venueNeighborhood = element && element.value ? element.value : this.venue.neighborhood;
   }

   venueRating() {
      let priceRating = '';
      for (let i = 0; i < Number(this.venue.priceRating); i ++) {
         priceRating += '$';
      }
      return priceRating;
   }

   async openGoogleRatingInBrowser(venue) {
      const googlePlaceId = await this.venueService.getVenuesGoogleInfo(venue.id);
      const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(venue.name)}&query_place_id=${googlePlaceId}`;
      if (!googlePlaceId) {
         return;
      }
      return window.open(url, '_blank');
   }

   async updateMenuItemAvailability(menu: MenuItemModel) {
      this.updateAvailabilityInProgress = true;
      setTimeout(() => {
         this.menuService.updateAvailablity(this.venue.id, menu.id, menu.available, menu.type);
      });
      this.updateAvailabilityInProgress = false;
   }

   getWorkingHours(venue: VenueModel) {
      const day = this.getDayName(new Date());
      if (!venue.workingHours) {
         return 'N/A';
      }
      if (!venue.workingHours[day]) {
         return 'N/A';
      }
      if (!venue.workingHours[day].open) {
         return 'Closed';
      }
      if (!venue.workingHours[day].secondStart && !venue.workingHours[day].secondEnd) {
         return `${venue.workingHours[day].start} - ${venue.workingHours[day].end}`;
      }
      // tslint:disable-next-line: max-line-length
      return `${venue.workingHours[day].start} - ${venue.workingHours[day].end} ${venue.workingHours[day].secondStart} - ${venue.workingHours[day].secondEnd}`;
   }

   getAddress(venue: VenueModel) {
      if (!venue.location || !venue.location.address) {
         return 'N/A';
      }
      return venue.location.address;
   }

   getEventTimestamp(event: any) {
      if (!event.data) {
         return 'N/A';
      }
      if (event.data.date) {
         return event.data.date;
      }
      if (event.data.days && event.data.days.length) {
         const d = event.data.days.map((x) => {
            return this.firstCharToUppercase(x);
         });
         return `Every ${d.join(', ')}`;
      }
      return `${event.data.day}`;
   }

   getTypeLabel(venue: VenueModel): string {
      return 'Restaurant';
   }

   isAdmin() {
      return this.currentUser && this.currentUser.role === UserRoles.ADMIN;
   }

   isOwner() {
      return (this.currentUser && this.currentUser.id === this.venue.ownerId);
   }

   isLoggedIn() {
      return this.currentUser;
   }

   getMenuItems(menu: string) {
      return this.menues.filter((x) => x.type === menu && !x.disabled);
   }

   openBookingModal() {
      if (!this.venue.ownerId) {
         this.notificationService.warning('Restaurant Claim', 'Restaurant\'s not yet in Cuculi network, no booking or ordering available');
         return;
      }
      const initialState = {
         venue: this.venue
      };
      this.bsModalRef = this.modalService.show(VenueBookingComponent, { initialState });
      this.bsModalRef.setClass('modal-lg');
   }


   shouldShowCategory(category) {
      const available = category.items.filter(item => item.available);
      return (category.items && category.items.length && available.length);
   }

   formatTime(time: string) {
      if (!time) {
         return '';
      }
      return time;
   }

   displayDay = (day: string) => {
      const newDay = day.slice(0, 3);
      return `${newDay[0].toUpperCase()}${newDay.slice(1, 3)}`;
   }

   displayHHTime(menuItem: any) {
      // tslint:disable: max-line-length
      if (menuItem && menuItem.startDate && menuItem.startDate.day && menuItem.startDate.workHours[0].start && menuItem.startDate.workHours[0].end) {
         return `${this.displayDay(menuItem.startDate.day)} ${this.formatTime(menuItem.startDate.workHours[0].start)} - ${this.formatTime(menuItem.startDate.workHours[0].end)}`;
      }

      if (menuItem && menuItem.workingHours && menuItem.workingHours.length && menuItem.workingHours[0].day) {
         if (menuItem.workingHours.length > 1) {
            return `${this.displayDay(menuItem.workingHours[0].day)} - ${this.displayDay(menuItem.workingHours[menuItem.workingHours.length - 1].day)} ${this.formatTime(menuItem.workingHours[0].start)} - ${this.formatTime(menuItem.workingHours[0].end)}`;
         }
         if (!menuItem.workingHours[0].start && !menuItem.workingHours[0].end) {
            return `${this.displayDay(menuItem.workingHours[0].day)}`;
         }
         if (menuItem.workingHours[0].start && !menuItem.workingHours[0].end) {
            return `${this.displayDay(menuItem.workingHours[0].day)} from ${this.formatTime(menuItem.workingHours[0].start)}`;
         }
         return `${this.displayDay(menuItem.workingHours[0].day)} ${this.formatTime(menuItem.workingHours[0].start)} - ${this.formatTime(menuItem.workingHours[0].end)}`;
      }
   }

   private doesMenuContainItems(menu: MenuModel) {
      if (menu.categories && menu.categories.length) {
         // tslint:disable-next-line:prefer-for-of
         for (let i = 0; i < menu.categories.length; i++) {
            if (menu.categories[i].items && menu.categories[i].items.length) {
               return true;
            }
         }
      }
      return false;
   }

   private async reloadVenue() {
      const slug: string = this.route.snapshot.paramMap.get('slug');
      this.venue = await this.venueService.getBySlug(slug);
      this.authService.refreshUserData();
   }

   private buildVenueSchedule() {
      const schedule = this.days.map((x) => {
         return {
            label: x,
            key: x.toLowerCase(),
            value: 'NO'
         };
      });
      if (!this.venue.workingHours) {
         return schedule;
      }
      for (const key in this.venue.workingHours) {
         if (!this.venue.workingHours.hasOwnProperty(key)) {
            continue;
         }
         const d = schedule.find((x) => x.key === key);
         if (d) {
            if (this.venue.workingHours[key].open) {
               (this.venue.workingHours[key].secondStart && this.venue.workingHours[key].secondEnd)
                  // tslint:disable-next-line:max-line-length
                  ? d.value = `${this.venue.workingHours[key].start} - ${this.venue.workingHours[key].end} ${this.venue.workingHours[key].secondStart} - ${this.venue.workingHours[key].secondEnd}`
                  : d.value = `${this.venue.workingHours[key].start} - ${this.venue.workingHours[key].end}`;
            }
         }
      }
      return schedule;
   }

   private getDayName(date) {
      const day = date.getDay();
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      return days[day];
   }

   private firstCharToUppercase(s: string) {
      if (typeof s !== 'string') {
         return '';
      }
      return s.charAt(0).toUpperCase() + s.slice(1);
   }

   private prepareMapLocation() {
      const icon = {
         path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
         fillColor: '#ff5d67',
         fillOpacity: .9,
         anchor: new google.maps.Point(0, 0),
         strokeWeight: 0,
         scale: 0.55
      };
      this.marker = new google.maps.Marker({
         position: {
            lat: this.venue.location.latitude,
            lng: this.venue.location.longitude
         },
         map: this.map,
         draggable: false,
         icon
      });
      this.map.setCenter(this.marker.getPosition());
   }
}
