import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HappyHourService } from '../happy-hour.service';
import { HappyHourModel, HappyHourConstraints } from 'src/app/models/happy-hour.model';
import { VenueModel } from 'src/app/models/venue.model';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormComponent, GenericFormType } from 'src/app/generic-form/generic.form.component';
import { FileUploadComponent } from 'src/app/file.upload/file.upload.component';
import { cloneDeep } from 'lodash';
import { NotificationService } from 'src/app/common/notification.service';

@Component({
  selector: 'app-happy-hour-edit-component',
  templateUrl: './happy-hour.edit.component.html',
})
export class HappyHourEditComponent implements OnInit, AfterViewInit {

  @ViewChild('menuItemForm', { static: false }) happyHourForm: GenericFormComponent;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUploadComponent;

  // provided by modal client
  venue: VenueModel;
  happyHour: HappyHourModel;
  loadMenus: any;
  createRules: GenericFormType[];
  updateInProgress: boolean;

  constructor(
    private happyhourService: HappyHourService,
    public bsModalRef: BsModalRef,
    private notificationService: NotificationService
  ) { }

  async ngOnInit() {
    this.createRules = cloneDeep(HappyHourConstraints.slice(0));
  }

  async ngAfterViewInit() {
    this.happyHourForm.fromModel<HappyHourModel>('HappyHourModel', 'HappyHourForm', this.happyHour);
    const raw = this.happyHourForm.toObject();
    console.log('HH', raw);
  }

  cancel() {
    this.bsModalRef.hide();
  }

  async update() {
    if (this.happyHourForm.isValid()) {
      this.updateInProgress = true;
      let happyHour: HappyHourModel = this.happyHourForm.toModel<HappyHourModel>('HappyHourForm', 'HappyHourModel');
      if (!happyHour.startDate.day) {
        this.notificationService.error('Start Day is required');
        return;
      }
      try {
        happyHour.id = this.happyHour.id;
        happyHour.venueId = this.venue.id;
        happyHour = await this.happyhourService.update(happyHour);
        if (this.fileUpload.fileUploaded && this.fileUpload.filesData && this.fileUpload.filesData.length) {
          await this.happyhourService.uploadPhoto(happyHour.id, this.fileUpload.filesData[0]);
        }
        this.updateInProgress = false;
        this.loadMenus();
        this.bsModalRef.hide();
      } catch (err) {
        console.error(err);
        this.updateInProgress = false;
      }
    }
  }
}
