import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { NotificationService } from 'src/app/common/notification.service';
import { MenuItemOptionModel } from 'src/app/models/menu-item.model';
import { OrderModel } from 'src/app/models/order.model';
import { UserModel } from 'src/app/models/user.model';

@Component({
    selector: 'app-split-payment-modal',
    templateUrl: './split-payment-modal.component.html',
    styleUrls: ['./split-payment-modal.component.scss']
})
export class SplitPaymentModal implements OnInit {
    @Input() order!: OrderModel;
    @Input() participants!: UserModel[];
    @Input() onConfirm!: (data: any) => void;
    @Input() item!: MenuItemOptionModel
    confirmationInProgress: boolean;
    payers: { userId: string, percentage: number }[] = []
    totalValue: number = 0;

    constructor(
        private bsModalRef: BsModalRef,
        private notificationService: NotificationService
    ) { }
    async ngOnInit() {
        this.payers = this.order.payers.map(payer => ({ userId: payer.userId, percentage: payer.percentage }))
        this.totalValue = this.item.price.value * this.item.quantity
    }
    isUserChecked(user: UserModel) {
        return this.payers.some(payer => payer.userId === user.id)
    }
    handleUserCheck(event: any, user: UserModel) {
        if (event.target.checked) {
            this.payers.push({ userId: user.id, percentage: 0 })
        } else {
            const index = this.payers.findIndex(payer => payer.userId === user.id)
            this.payers.splice(index, 1)
        }
        this.calculateSplitAmounts()
    }
    calculateSplitAmounts() {
        const totalPercentage = 100 / this.payers.length
        this.payers = this.payers.map(payer => ({ ...payer, percentage: totalPercentage }))

    }
    addAllParticipants() {
        if(this.payers.length === this.participants.length) {
            this.payers = this.order.payers.map(payer => ({ userId: payer.userId, percentage: payer.percentage }))
        }else {
            this.payers = this.participants.map(participant => ({ userId: participant.id, percentage: 0 }))
        }
        this.calculateSplitAmounts()
    }

    getSplitAmount(user: UserModel) {
        const payer = this.payers.find(payer => payer.userId === user.id)
        return payer ? Math.round((payer.percentage / 100 * this.totalValue) * 100) / 100 : 0
    }
    handleSplitAmount(event: any, user: UserModel) {
        const { value } = event.target
        this.payers = this.payers.map(payer => {
            if(payer.userId === user.id){
                payer.percentage = value / this.totalValue * 100
            }
            return payer
        })
        const percentage = this.payers.reduce((acc, payer) => acc + payer.percentage, 0)
        if(!this.isValidForm() && percentage < 100) {
            const diference = 100 - percentage
            if(diference > 0 && diference < 1) {
                this.payers[0].percentage += diference
            }
        }
    }
    isValidForm() {
        const percentage = this.payers.reduce((acc, payer) => acc + payer.percentage, 0)
        return percentage === 100
    }

    async confirmation() {
        this.confirmationInProgress = true;
        try {
            const data = {
                orders: [this.order.id],
                payers: this.payers
            }
            this.onConfirm(data)
        } catch (error) {
            console.error(error)
            this.notificationService.error('There are some error splitting the order');
        }
        this.confirmationInProgress = false;
        this.bsModalRef.hide();
    }

    async cancel() {
        this.confirmationInProgress = false;
        this.bsModalRef.hide();
    }
}
