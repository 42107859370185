import { Injectable } from '@angular/core';
import { BaseService } from '../common/base.service';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';
import { BehaviorSubject } from 'rxjs';
import { Lookup } from '../generic-form/generic.form.component';
declare const navigator: any;

export class SearchFilter {
  label: string;
  type: string;
  name: string;
  selected: any | any[];
  options: Lookup[] | any[];
  date?: { from?: string; to?: string };
  eventStatuses?: any[];
  limit?: number;
  sort?: {};
}

export class Filters {
  type: string; // tables, venues, happyHour, all
  filters: SearchFilter[];
}

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService<any> {

  private filters: BehaviorSubject<Filters> = new BehaviorSubject<Filters>(null);

  constructor(
    apiService: ApiService,
    automapper: AutomapperService
  ) {
    super(apiService, automapper);
  }

  getFiltersObj(): BehaviorSubject<Filters> {
    return this.filters;
  }

  async searchByType(filters: Filters): Promise<any> {
    if (filters.type === 'tables') {
      return await this.searchTables(filters);
    }
    if (filters.type === 'venues') {
      return await this.searchVenues(filters);
    }
    if (filters.type === 'happyHour') {
      return await this.searchHappyHours(filters);
    }
    if (filters.type === 'all') {
      return this.searchHomeOffer(filters);
    }
  }

  updateFilters(newFilters: Filters) {
    this.filters.next(newFilters);
    return this.filters;
  }

  searchTables(filters: Filters, textSearch?: string): Promise<any> {
    const tableQuery: any = {
      coordinates: {
        radius: 10000000
      },
    };
    filters.filters.forEach((filter) => {
      if (filter.type === 'multiSelect') {
        tableQuery[filter.name] = (filter.selected && !!filter.selected.length) ? filter.selected : null;
      }
      if (filter.type === 'boolean') {
        tableQuery[filter.name] = !!filter.selected;
      }
      if (filter.type === 'dropdown') {
        const dropdownData = filter.options.find(o => !!o.selected);
        if (filter.name === 'date') {
          tableQuery[filter.name] = dropdownData ? dropdownData.data : null;
        } else {
          tableQuery[filter.name] = dropdownData ? dropdownData.value : null;
        }
      }
      if (!!filter.date) {
        tableQuery.date = filter.date;
      }
      if (filter.eventStatuses && !!filter.eventStatuses.length) {
        tableQuery.eventStatuses = filter.eventStatuses;
      }
      if (!!filter.limit) {
        tableQuery.limit = filter.limit;
      }
      if (!!filter.sort) {
        tableQuery.sort = filter.sort;
      }
    });
    if (!!textSearch) {
      tableQuery.name = textSearch;
    }
    return this.apiService.post(`/venue/event-info/search`, tableQuery, []);
  }

  async searchVenues(filters: Filters): Promise<any> {
    const venueQuery = {
      coordinates: {},
      sort: null
    };

    filters.filters.forEach((filter) => {
      if (filter.type === 'multiSelect') { // temporary
        const dropdownData = filter.options.find(o => o.selected === true);
        venueQuery[filter.name] = !!dropdownData && !!dropdownData.value ? [dropdownData.value] : null;
        // venueQuery[filter.name] = (filter.selected && !!filter.selected.length) ? filter.selected : null;
      }
      if (filter.type === 'array') {
        venueQuery[filter.name] = (filter.selected && !!filter.selected.length) ? filter.selected : null;
      }
      if (filter.type === 'dropdown') {
        const dropdownData = filter.options.find(o => o.selected === true);
        venueQuery[filter.name] = dropdownData ? dropdownData.value : null;
      }
    });
    let location;
    try {
      location = await this.getGeoPosition({});
    } catch(err) {
      location = false;
    }
    if (location && location.coords && location.coords.latitude && location.coords.longitude) {
      venueQuery.coordinates['latitude'] = location.coords.latitude;
      venueQuery.coordinates['longitude'] = location.coords.longitude;
    }
    let venues = await this.apiService.post('/venues/search', venueQuery, []);
    if(venues && !venues.length) {
      venueQuery.coordinates = {};
      venues = await this.apiService.post('/venues/search', venueQuery, []);
    }
    return venues;
  }

  async searchHappyHours(filters: any): Promise<any> {
    let happyHourQuery = {
      date: filters.date,
      species: filters.species ? filters.species : null,
      features: filters.features && filters.features.length ? filters.features : null,
      coordinates: {},
    };
    let location;
    try {
      location = await this.getGeoPosition({});
    } catch(err) {
      location = false;
    }
    if (location && location.coords && location.coords.latitude && location.coords.longitude) {
      happyHourQuery.coordinates['latitude'] = location.coords.latitude;
      happyHourQuery.coordinates['longitude'] = location.coords.longitude;
    }
    if (filters.radius) {
      happyHourQuery.coordinates['radius'] = filters.radius;
    }
    let happyHour = await this.apiService.post('/venues/happy-hours', happyHourQuery, []);
    if(happyHour && !happyHour.length) {
      happyHourQuery.coordinates = {};
      happyHour = this.apiService.post('/venues/happy-hours', happyHourQuery, []);
    }
    return happyHour;
  }

  searchHomeOffer(filters: any): Promise<any> {
    let allQuery = {
      date: filters.date ? filters.date : null,
      categories: filters.cuisine && filters.cuisine.length ? filters.cuisine : null,
      coordinates: {},
    };
    if (filters.radius) {
      allQuery = {... allQuery, coordinates: {radius: filters.radius}};
    }
    if (filters.latitude) {
      allQuery = {... allQuery, coordinates: {... allQuery.coordinates, latitude: filters.latitude}};
    }
    if (filters.longitude) {
      allQuery = {... allQuery, coordinates: {... allQuery.coordinates, longitude: filters.longitude}};
    }
    return this.apiService.post(`/offer`, allQuery, []);
  }

  getVenuesByName(name: string): Promise<any> {
    return this.apiService.get(`/venue?name=${name}`, []);
  }

  getGeoPosition(options?: PositionOptions): Promise<any> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
      }
    );
  }

  protected getModelName(): any {

  }
  protected getApiName(): any {

  }
  protected getApiPrefix(): any {

  }
}
