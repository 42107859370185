import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { RouterModule } from '@angular/router';
import { LoginModalComponent } from './login.modal.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';

@NgModule({
    declarations: [
        LoginComponent,
        LoginModalComponent
    ],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, LaddaModule, LayoutModule, NgxIntlTelInputModule],
    providers: [],
})
export class LoginModule { }
