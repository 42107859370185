import { Injectable } from '@angular/core';

@Injectable()
export class AutomapperService {

  constructor() {}

  map<TOut>(sourceName: string, toName: string, data: any): TOut {
    return automapper.map(sourceName, toName, data);
  }

  mapArray<TOut>(sourceName: string, toName: string, data: any[]): TOut[] {
    const res: TOut[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      res.push(automapper.map(sourceName, toName, data[i]));
    }
    return res;
  }
  mergeDuplicateItems(items: any[], itemKey: string) {
    const itemMap = new Map();
    
    items.forEach(item => {
        const key = item[itemKey];
        if (itemMap.has(key)) {
            itemMap.get(key).quantity += item.quantity;
        } else {
            itemMap.set(key, { ...item });
        }
    });
    
    return Array.from(itemMap.values());
}

  register(registerCallback: (mapper: AutoMapperJs.AutoMapper) => void) {
    registerCallback(automapper);
  }
}
