import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../common/auth.service';
import { UserModel, UserUpdateConstraints, retypePasswordValidator } from '../models/user.model';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { UserService } from './user.service';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { NotificationService } from '../common/notification.service';
import { PaymentCreateComponent } from '../payment/payment.create.component';
import { EventService } from '../event/event.service';
import { EventModel } from '../models/event.model';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
    selector: 'app-user-settings',
    templateUrl: './user.settings.component.html',
    styleUrls: ['./user.settings.component.scss']
})
export class UserSettingsComponent implements OnInit, AfterViewInit {

    @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;
    @ViewChild('passwordForm', { static: false }) passwordForm: GenericFormComponent;
    @ViewChild('paymentCreate', { static: false }) paymentCreate: PaymentCreateComponent;

    generalInfoRules: GenericFormType[] = UserUpdateConstraints;
    passwordRules: GenericFormType[] = [
        GenericFormBuilder.password('oldPassword', 'Old Password', [Validators.required], null, 'col-md-12 inline-block'),
        GenericFormBuilder.password('newPassword', 'New Password', [Validators.required], null, 'col-md-12 inline-block'),
        // tslint:disable-next-line: max-line-length
        GenericFormBuilder.password('repeatPassword', 'Repeat password', [Validators.required, retypePasswordValidator('newPassword')], null, 'col-md-12 inline-block')
    ];
    user: UserModel;
    currentUser: UserModel;
    updateInProgress: boolean;
    oneAtATime: boolean;
    newCreditCard: boolean;
    cards: any;
    events: EventModel[];
    pastEvents: EventModel[] = [];
    futureEvents: EventModel[] = [];
    config = {
        pagination: { el: '.swiper-pagination', clickable: true },
        slidesPerView: 6,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        spaceBetween: 20
      };

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private notificationService: NotificationService,
        private eventService: EventService,
        private router: Router,
        private cr: ChangeDetectorRef,
    ) {}

    ngOnInit() {

    }
    async ngAfterViewInit() {
        this.oneAtATime = true;
        this.newCreditCard = false;
        this.currentUser = await this.authService.getCurrentUser();
        this.user = await this.userService.getById(this.currentUser.id);
        await this.getAllCards();
        this.cr.detectChanges();
        await this.getAllUserEvents();
        this.genericForm.fromModel<UserModel>('UserModel', 'UserUpdateForm', this.user);
    }

    async getAllUserEvents() {
        this.events = await this.eventService.search({ participants: this.currentUser.email });
        this.pastEvents = this.events.filter(item => {
            if (moment(item.endDate).isBefore(moment())) {
                return item;
            }
        });
        this.futureEvents = this.events.filter(item => {
            if (moment(item.startDate).isAfter(moment())) {
                return item;
            }
        });
        console.log(this.events, 'events');
        console.log(this.pastEvents, 'pastEvents');
        console.log(this.futureEvents, 'futureEvents');
    }

    getEventDate(date) {
        return moment(date).format('DD');
    }

    getEventMonth(date) {
        return moment(date).format('MMM');
    }

    goToBooking(bookingId) {
        this.router.navigateByUrl(`/bookings/${bookingId}`);
    }

    async updateUserInfo() {
        if (this.genericForm.isValid()) {
            this.updateInProgress = true;
            try {
              const userData = this.genericForm.toModel<UserModel>('UserUpdateForm', 'UserModel', this.user);
              this.user = await this.userService.update(this.user.id, userData);
              this.user.username = this.currentUser.username;
              this.notificationService.success('General Information successfully updated');
              this.updateInProgress = false;
            } catch (err) {
              this.notificationService.warning('Something went wrong during update');
              this.updateInProgress = false;
            }
        }
    }

    async getAllCards() {
        this.cards = [];
        this.user.payment.sources.forEach(source => {
            this.cards.push(source.card);
        });
    }

    async deleteCreditCard(card: any) {
        this.updateInProgress = true;
        try {
            await this.userService.deleteCreditCard(this.user.id, card.id);
            this.updateInProgress = false;
            this.user = await this.userService.getById(this.currentUser.id);
            await this.getAllCards();
        } catch (err) {
            this.notificationService.warning('Something went wrong, card could not be deleted. Please try again later');
            this.updateInProgress = false;
        }
    }

    async saveCreditCard() {
        const paymentFormValid = this.paymentCreate.isValid();
        if (!paymentFormValid) {
            return this.notificationService.error('One or more fields in Credit Card info are not correct');
        }
        const stripeSource = await this.paymentCreate.getPaymentSource();
        if (!stripeSource) {
            this.notificationService.warning('Invalid card', 'Something went wrong, card can not be verified please try again later');
            return null;
        }
        this.updateInProgress = true;
        try {
            await this.userService.addNewCreditCard(this.user.id, stripeSource);
            this.newCreditCard = false;
            this.updateInProgress = false;
            this.user = await this.userService.getById(this.currentUser.id);
            await this.getAllCards();
        } catch (err) {
            this.updateInProgress = false;
        }
    }

    async updateUserPassword() {
        if (this.passwordForm.isValid()) {
            const passwordData = this.passwordForm.toObject();
            this.updateInProgress = true;
            try {
                await this.userService.updatePassword(this.user.id, passwordData.oldPassword, passwordData.newPassword);
                this.passwordForm.reset();
                this.updateInProgress = false;
            } catch (err) {
                this.updateInProgress = false;
            }
        }
    }
}
