import { Lookup, GenericFormControl, GenericFormGroup, GenericFormArray, GenericFormDatepicker } from './generic.form.component';
import { ValidatorFn } from '@angular/forms';

export class GenericFormBuilder {

    // tslint:disable-next-line:max-line-length
    public static text(name: string, label: string, validators: ValidatorFn[], readonly?: boolean, visibleIf?: (x: any) => boolean, gridClass?: string, value?: string, placeHolder?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'text',
            placeHolder: placeHolder ? placeHolder : label,
            validators,
            readonly,
            gridClass,
            visibleIf,
            value,
        };
    }

    // tslint:disable-next-line: max-line-length
    public static autocomplete(name: string, label: string, validators: ValidatorFn[], lookups?: Lookup[], search?: (text: string) => Promise<Lookup[]>, gridClass?: string, readonly?: boolean, visibleIf?: (x: any) => boolean, displayButton?: boolean, onChange?: (text) => Promise<any>, placeholder?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'autocomplete',
            validators,
            lookups,
            filteredLookups: lookups,
            search,
            gridClass,
            visibleIf,
            readonly,
            displayButton,
            onChange,
            placeholder
        };
    }

    // tslint:disable-next-line:max-line-length
    public static number(name: string, label: string, validators: ValidatorFn[], readonly?: boolean, visibleIf?: (x: any) => boolean, gridClass?: string, step?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'number',
            placeHolder: label,
            validators,
            readonly,
            gridClass,
            visibleIf,
            step,
        };
    }

    // tslint:disable-next-line:max-line-length
    public static textarea(name: string, label: string, validators: ValidatorFn[], readonly?: boolean, visibleIf?: (x: any) => boolean, gridClass?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'textarea',
            placeHolder: label,
            validators,
            readonly,
            gridClass,
            visibleIf
        };
    }

    // tslint:disable-next-line:max-line-length
    public static dropdown(name: string, label: string, validators: ValidatorFn[], lookups: Lookup[], readonly?: boolean, enumSource?: string | string[], visibleIf?: (x: any) => boolean, gridClass?: string, customVisibleIfField?: boolean): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'dropdown',
            placeHolder: label,
            validators,
            lookups,
            readonly,
            visibleIf,
            gridClass,
            enumSource,
            customVisibleIfField
        };
    }

    // tslint:disable-next-line:max-line-length
    public static multiselect(name: string, label: string, validators: ValidatorFn[], lookups: Lookup[], readonly?: boolean, enumSource?: string | string[], visibleIf?: (x: any) => boolean, gridClass?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'multiselect',
            placeHolder: label,
            validators,
            lookups,
            readonly,
            visibleIf,
            gridClass,
            enumSource
        };
    }


    // tslint:disable-next-line:max-line-length
    public static group(name: string, title: string, controls: GenericFormControl[], visibleIf?: (x: any) => boolean, style?: 'row' | 'inline', gridClass?: string, description?: string, descriptionClass?: string, descriptionIcon?: boolean, descriptionIconClass?: string, descriptionTooltip?: string): GenericFormGroup {
        return {
            type: 'group',
            name,
            groupTitle: title,
            controls,
            visibleIf,
            gridClass,
            style,
            description,
            descriptionClass,
            descriptionIcon,
            descriptionIconClass,
            descriptionTooltip
        };
    }

    // tslint:disable-next-line:max-line-length
    public static password(name: string, label: string, validators: ValidatorFn[], visibleIf?: (x: any) => boolean, gridClass?: string, invalidText?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'password',
            placeHolder: label,
            gridClass,
            validators,
            visibleIf,
            invalidText
        };
    }

    public static switch(name: string, label: string, visibleIf?: (x: any) => boolean, gridClass?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'switch',
            placeHolder: label,
            validators: [],
            gridClass,
            visibleIf
        };
    }

    // tslint:disable-next-line:max-line-length
    public static array(name: string, label: string, groups: GenericFormGroup[], visibleIf?: (x: any) => boolean, extendable?: boolean, gridClass?: string): GenericFormArray {
        return {
            arrayTitle: label,
            name,
            groups,
            type: 'array',
            visibleIf,
            gridClass,
            extendable,
            label,
            inputType: 'array',
            placeHolder: label,
            validators: [],
            lookups: null,
            readonly: false
        };
    }

    // tslint:disable-next-line:max-line-length
    public static phone(name: string, label: string, validators: ValidatorFn[], readonly?: boolean, visibleIf?: (x: any) => boolean, gridClass?: string, value?: any, separateDialCode?: boolean, enablePlaceholder: boolean = true): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'phone',
            placeHolder: label,
            validators,
            readonly,
            gridClass,
            visibleIf,
            value,
            separateDialCode,
            enablePlaceholder
        };
    }

    // tslint:disable-next-line:max-line-length
    public static datepicker(name: string, label: string, validators: ValidatorFn[], readonly?: boolean, visibleIf?: (x: any) => boolean, gridClass?: string, min?: Date, max?: Date): GenericFormDatepicker {
        return {
            type: 'control',
            name,
            label,
            inputType: 'datepicker',
            placeHolder: label,
            validators,
            readonly,
            gridClass,
            visibleIf,
            minDate: min,
            maxDate: max
        };
    }

    // tslint:disable-next-line:max-line-length
    public static timePicker(name: string, label: string, validators: ValidatorFn[], readonly?: boolean, visibleIf?: (x: any) => boolean, gridClass?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'timepicker',
            placeHolder: label,
            validators,
            readonly,
            gridClass,
            visibleIf
        };
    }

    // tslint:disable-next-line:max-line-length
    public static richText(name: string, label: string, validators: ValidatorFn[], visibleIf?: (x: any) => boolean, gridClass?: string): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'rich-text',
            placeHolder: label,
            validators,
            readonly: false,
            gridClass,
            visibleIf
        };
    }

    // tslint:disable-next-line:max-line-length
    public static json(name: string, label: string, validators: ValidatorFn[], value?: any, visibleIf?: (x: any) => boolean): GenericFormControl {
        return {
            type: 'control',
            name,
            label,
            inputType: 'json',
            validators,
            visibleIf,
            value
        };
    }
}
