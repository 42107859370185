import {
  BrowserModule,
  BrowserTransferStateModule,
  Meta,
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutHeaderComponent } from './layout/layout.header.component';
import { LayoutFooterComponent } from './layout/layout.footer.component';
import { PageNotFoundComponent } from './routing/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  CarouselModule,
  BsDropdownModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';
import { ApiService } from './common/api.service';
import { HttpClientModule } from '@angular/common/http';
import { EnumService } from './common/enum.service';
import { PagesService } from './common/pages.service';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { Module as StripeModule } from 'stripe-angular';
import { PaymentService } from './payment/payment.service';
import { HomeComponent } from './home/home.component';
import { CustomerService } from './customer/customer.service';
import { LaddaModule } from 'angular2-ladda';
import { ParallaxModule } from 'ngx-parallax';
import { FormsModule } from '@angular/forms';
import { LoginModule } from './login/login.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import {} from 'automapper-ts';
import { AutomapperService } from './common/automapper.service';
import { AuthService } from './common/auth.service';
import { MappersModule } from './mappers/mappers.module';
import { GenericFormModule } from './generic-form/generic.form.module';
import { VenueModule } from './venue/venue.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserModule } from './user/user.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { IntroComponent } from './intro/intro.component';
import { AgmCoreModule } from '@agm/core';
import { MenuItemModule } from './menu-item/menu-item.module';
import { FileUploadModule } from './file.upload/file.upload.module';
import { TabsModule } from 'ngx-bootstrap';
import { EventModule } from './event/event.module';
import { OrderModule } from './order/order.module';
import { ToasterModule } from 'angular2-toaster';
import { NotificationService } from './common/notification.service';
import { ConfirmationDialogService } from './common/confirmation.dialog.service';
import { AuthGuard } from './routing/auth.guard';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ReservationsModule } from './reservation-list/reservation-list.module';
import { WaiterModule } from './waiter/waiter.module';
import { AboutComponent } from './about/about.component';
import { ForRestaurantComponent } from './for-restaurant/for-restaurant.component';
import { PageComponent } from './pages/page.component';
import { GetTheAppPopupComponent } from './home/get-the-app-popup.component';
import { SearchModule } from './search/search.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MailService } from './common/mail.service';
import { ContactModalComponent } from './contact/contact.modal.component';
import { MobileOrderingModule } from './mobile-ordering/mobile-ordering.module';
import { RestaurantSignUpModalComponent } from './for-restaurant/restaurant-signup-modal.component';
import { LoginModalComponent } from './login/login.modal.component';
import { GetTheAppScreenComponent } from './home/get-the-app-screen.component';
import { PaymentModule } from './payment/payment.module';
import { HomeBannerComponent } from './home/home.banner.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ContactComponent } from './contact/contact.component';
import { ReferralComponent } from './referral/referral.component';
import { CaptureUserFromSocialMediaComponent } from './capture-user-from-social-media/capture-user-from-social-media.component';
import { SendToDownloadAppComponent } from './send-to-download-app/send-to-download-app.component';
import { DeleteAccountRequestComponent } from './delete-account-request/delete-account-request.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SoulCampaignComponent } from './soul-campaign/soul-campaign.component';
import { SoulCampaignDetailsComponent } from './soul-campaign/soul-campaign-details/soul-campaign-details.component';
import { UnsubscribEmailsComponent } from './unsubscribe-emails/unsubscribe-emails.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    PageNotFoundComponent,
    HomeComponent,
    HomeBannerComponent,
    IntroComponent,
    AboutComponent,
    ForRestaurantComponent,
    PageComponent,
    GetTheAppPopupComponent,
    RestaurantSignUpModalComponent,
    ContactModalComponent,
    GetTheAppScreenComponent,
    VerifyEmailComponent,
    ContactComponent,
    ReferralComponent,
    CaptureUserFromSocialMediaComponent,
    SendToDownloadAppComponent,
    DeleteAccountRequestComponent,
    SubscriptionComponent,
    SoulCampaignComponent,
    SoulCampaignDetailsComponent,
    UnsubscribEmailsComponent,
  ],
  imports: [
    ParallaxModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.agmCoreApiKey,
    }),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    FileUploadModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    TimepickerModule.forRoot(),
    TabsModule.forRoot(),
    BrowserTransferStateModule,
    StripeModule.forRoot(),
    ToasterModule.forRoot(),
    AccordionModule.forRoot(),
    LaddaModule,
    GenericFormModule,
    FormsModule,
    LoginModule,
    ResetPasswordModule,
    VenueModule,
    MenuItemModule,
    UserModule,
    EventModule,
    OrderModule,
    MappersModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UiSwitchModule,
    DeviceDetectorModule,
    ReservationsModule,
    WaiterModule,
    SearchModule,
    NgxSpinnerModule,
    MobileOrderingModule,
    PaymentModule,
  ],
  entryComponents: [
    GetTheAppPopupComponent,
    ContactModalComponent,
    RestaurantSignUpModalComponent,
    LoginModalComponent,
    HomeBannerComponent,
  ],
  providers: [
    // tslint:disable-next-line:max-line-length
    ApiService,
    EnumService,
    PaymentService,
    CustomerService,
    PagesService,
    AutomapperService,
    AuthService,
    NotificationService,
    ConfirmationDialogService,
    AuthGuard,
    MailService,
    Meta,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
