import { cloneDeep } from 'lodash';
import { Component, OnInit, ViewChild, OnDestroy,  Output, EventEmitter, AfterViewInit } from '@angular/core';
import { GenericFormComponent, GenericFormType, Lookup, GenericFormControl } from '../generic-form/generic.form.component';
import { MenuItemCreateConstraints, MenuItemModel } from '../models/menu-item.model';
import { MenuService } from './menu.service';
import { VenueModel } from '../models/venue.model';
import { FileUploadComponent } from '../file.upload/file.upload.component';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { GenericMenuCategories } from '../data/generic.menu.categories.data';
import { HappyHourModel } from '../models/happy-hour.model';
import { HourSlots } from '../data/hour.slots.data';
import { getDaysFromRange } from '../data/week.days.data';

@Component({
  selector: 'app-menu-item-create',
  templateUrl: './menu-item.create.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuItemCreateComponent implements OnInit, AfterViewInit {
  @ViewChild('menuItemForm', { static: false }) menuItemForm: GenericFormComponent;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUploadComponent;
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  createRules: GenericFormType[] = cloneDeep(MenuItemCreateConstraints.slice(0));
  venue: VenueModel;
  createInProgress: boolean;
  venueId: string;
  loadMenus: any;
  categories: Lookup[];
  happyHours: HappyHourModel[];
  selectedHH: HappyHourModel;
  happyHourLookups: Lookup[];
  currentType: string;
  species: any;
  menues: Lookup[];
  selectedTab: any;

  constructor(
    public bsModalRef: BsModalRef,
    private menuService: MenuService
  ) { }

  async ngOnInit() {
    this.venueId = this.venue.id;
    this.createInProgress = false;
    this.currentType = this.selectedTab.key;
    // add autocomplete rule for category on top of the create rules
    // tslint:disable-next-line:max-line-length
    this.createRules.unshift(GenericFormBuilder.autocomplete('category', 'Category', [], [GenericMenuCategories.UNKNOWN], this.searchItems.bind(this), null, false, (x) => x.type !== 'happyHour'));
    const typeLookup = this.createRules.find((x) => x.name === 'type');
    if (typeLookup) {
      (typeLookup as GenericFormControl).lookups = this.menues;
      (typeLookup as GenericFormControl).lookups.unshift({ value: null, label: 'Select Type' });
    }
  }

  ngAfterViewInit() {
    this.menuItemForm.myForm.get('species').setValue(this.species.type);
    this.menuItemForm.myForm.get('type').setValue(this.selectedTab.key);
    this.happyHourLookups = this.happyHours.map((x) => {
      return {
        value: x.name,
        // tslint:disable-next-line:max-line-length
        label: `${x.name} @ ${x.startDate.day} ${(x.startDate.dayUntil ? '- ' + x.startDate.dayUntil : '')} ${this.getHourSlot(x.startDate.start)} - ${this.getHourSlot(x.startDate.end)}`
      };
    });
    this.happyHourLookups.unshift({ value: null, label: 'Select Happy Hour' });
  }

  searchItems(text: string) {
    // const type = this.menuItemForm.myForm.value.type;
    const lookups = this.categories;
    if (!text) {
      return lookups;
    }
    return lookups.filter((c) => {
      return c.value.indexOf(text) !== -1;
    });
  }

  happyHourSelected($event) {
    const selected = $event.value;
    this.selectedHH = this.happyHours.find((x) => x.name === selected);
    if (this.selectedHH) {
      const menuModel = this.menuItemForm.toModel<MenuItemModel>('MenuItemCreateForm', 'MenuItemModel');
      menuModel.workingHours = [];
      const days = getDaysFromRange(this.selectedHH.startDate.day, this.selectedHH.startDate.dayUntil);
      days.forEach((day) => {
        menuModel.workingHours.push({
          day: day.value,
          open: true,
          splitWorkingHours: false,
          start: this.selectedHH.startDate.start,
          end: this.selectedHH.startDate.end
        });
      });
      this.menuItemForm.fromModel('MenuItemModel', 'MenuItemEditForm', menuModel);
    }
  }

  dataChanged(data) {
    this.currentType = data.type;
  }

  async create() {
    if (this.menuItemForm.isValid()) {
      this.createInProgress = true;

      try {
        let menuItem: MenuItemModel = this.menuItemForm.toModel<MenuItemModel>('MenuItemCreateForm', 'MenuItemModel');
        if (this.currentType === 'happyHour' && this.selectedHH) {
          menuItem.category = this.selectedHH.name;
        }
        if (!menuItem.category) {
          menuItem.category = 'Uncategorized';
        }
        menuItem = await this.menuService.create(menuItem, this.venueId);
        if (this.fileUpload.fileUploaded && this.fileUpload.filesData && this.fileUpload.filesData.length) {
          await this.menuService.uploadPhoto(this.venue.id, menuItem.id, this.fileUpload.filesData[0]);
        }
        this.createInProgress = false;
        this.loadMenus();
        this.bsModalRef.hide();
      } catch (err) {
        this.createInProgress = false;
      }
    }
  }

  cancel() {
    this.createRules = cloneDeep(MenuItemCreateConstraints.slice(0));
    this.bsModalRef.hide();
  }

  getHourSlot(timestamp: string) {
    const hour = HourSlots.find((x) => x.value === timestamp);
    if (hour) {
      return hour.label;
    }
    return timestamp;
  }
}
