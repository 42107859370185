import { GenericFormType } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators, AbstractControl, FormGroup } from '@angular/forms';

export class UserModel {
  id: string;
  createdAt: string;
  status: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  role: string;
  token?: string;
  payment: any;
  credit: number;
  venueIds: string[];
  address?: string;
  identificationType?: string;
  identificationNumber?: string;
  wasPotential?: boolean;
  phoneNumberVerified?: boolean;
  skipPreAuthorization?: boolean;
  birthDay?: string
}

export const retypePasswordValidator = (passwordFieldName: string) => {
  return (control: AbstractControl): any => {
    if (control.parent) {
      const form: FormGroup = control.parent as FormGroup;
      const password = form.get(passwordFieldName).value;
      const repeatPassword = control.value;
      return (password !== repeatPassword) ? { invalidRetypePassword: { value: control.value } } : null;
    }
  };
};

export const cuculiAmbassadors: string[] = [
  "None",
  "Frankie G",
  "Trenton V",
  "Tim Barney",
  "Kayla Rudess",
  "Ron (Omgitsbomb)",
  "Pierreson Miracle"
];

export const UserCreateConstraints: GenericFormType[] = [
  GenericFormBuilder.text('firstName', 'Name', [Validators.required], false, null, 'col-md-6 inline-block claim-restaurant'),
  GenericFormBuilder.text('lastName', 'Last Name', [Validators.required], false, null, 'col-md-6 inline-block claim-restaurant'),
  GenericFormBuilder.text('email', 'Email', [Validators.required], false, null, 'col-md-6 inline-block claim-restaurant'),
  GenericFormBuilder.phone('phone', 'Phone', [], false, null, 'col-md-6 inline-block claim-restaurant', '', null, false),
  // tslint:disable-next-line: max-line-length
  GenericFormBuilder.text("promoCode", "Promo Code", [], false, null, 'col-md-6 inline-block claim-restaurant'),
];

export const UserUpdateConstraints: GenericFormType[] = [
  GenericFormBuilder.text('email', 'Email', [Validators.required], false, null, 'col-md-6 inline-block'),
  GenericFormBuilder.text('firstName', 'First Name', [Validators.required], false, null, 'col-md-6 inline-block'),
  GenericFormBuilder.text('lastName', 'Last Name', [Validators.required], false, null, 'col-md-6 inline-block'),
  GenericFormBuilder.phone('phone', 'Phone', [], false, null, 'col-md-6 inline-block')
];
