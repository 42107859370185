import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { MailService } from '../common/mail.service';
import { NotificationService } from '../common/notification.service';
import { BsModalRef } from 'ngx-bootstrap';
import { UserModel } from '../models/user.model';
import { AuthService } from '../common/auth.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact.modal.component.html'
})
export class ContactModalComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;

  sendInProgress: boolean;
  currentUser: UserModel;

  formRules: GenericFormType[];

  constructor(
    private mailService: MailService,
    private notificationService: NotificationService,
    private modalInstance: BsModalRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.sendInProgress = false;
    this.currentUser = this.authService.getCurrentUser();
    this.formRules = [
      GenericFormBuilder.text('name', 'Name', [Validators.required], null, null, null, this.currentUser ? this.getUserName()  : ''),
      GenericFormBuilder.text('from', 'Email', [Validators.required], null, null, null, this.currentUser ? this.currentUser.email : ''),
      GenericFormBuilder.textarea('message', 'Message', [Validators.required]),
    ];
  }

  cancel() {
    this.modalInstance.hide();
  }

  getUserName() {
    let username = '';
    if (this.currentUser.firstName) {
      username = this.currentUser.firstName;
      if (this.currentUser.lastName) {
        username = `${username} ${this.currentUser.lastName}`;
      }
    }
    return username;
  }

  async send() {
    if (!this.genericForm.isValid()) {
      return;
    }
    this.sendInProgress = true;
    try {
      const contactData = this.genericForm.toObject();
      await this.mailService.sendContactMail(contactData);
      this.notificationService.success('Thank you for contacting cucucli');
      this.sendInProgress = false;
      this.modalInstance.hide();
    } catch (err) {
      console.error(err);
      this.sendInProgress = false;
    }
  }
}
