import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StripeScriptTag } from 'stripe-angular';
import { environment } from '../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  title = 'cuculi-payments';
  private publishableKey = environment.stripeKey;

  constructor(
    private router: Router,
    public stripeScriptTag: StripeScriptTag,
    private meta: Meta,
    private titleMeta: Title
  ) {
    this.stripeScriptTag.setPublishableKey(this.publishableKey);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // TODO: Check why this doesn't work!!
        // window.scrollTo(0, 0);
        $('html, body').animate({ scrollTop: 0 }, 'fast');
      }
    });
  }

  ngOnInit() {
    /* this.titleMeta.setTitle('Cuculi');

    this.meta.addTags([
      { charset: 'UTF-8' },
      {
        name: 'description',
        property: 'description',
        content:
          'Spend more time connecting than waiting. No more waiting, no more bill splitting & earn restaurant points',
      },
      { property: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'keywords',
        property: 'keywords',
        content:
          'Cuculi, booking, restaurant, restaurants, bill splitting, app, New York, Apple Store, Play Store, order, ordering, cuculi, meal, dine, drinks',
      },
      {
        property: 'og:title',
        name: 'og:title',
        content: 'Cuculi',
      },
      {
        property: 'og:type',
        name: 'og:type',
        content: 'website',
      },
      {
        property: 'og:image',
        name: 'og:image',
        content: 'https://cuculi.com/assets/images/Landing_People.png',
      },
      {
        property: 'og:url',
        name: 'og:url',
        content: 'https://cuculi.com',
      },
      {
        property: 'og:description',
        name: 'og:description',
        content:
          'Spend more time connecting than waiting. No more waiting, no more bill splitting & earn restaurant points',
      },
      {
        name: 'og:locale',
        property: 'og:locale',
        content: 'en_US',
      },
      {
        name: 'twitter:title',
        property: 'twitter:title',
        content: 'Cuculi',
      },
      {
        name: 'twitter:description',
        property: 'twitter:description',
        content:
          'Spend more time connecting than waiting. No more waiting, no more bill splitting and earn restaurant points',
      },
      {
        name: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
    ]); */
  } 
}
