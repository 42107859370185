import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { VenueModel } from '../models/venue.model';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { WaiterCreateConstraints, WaiterModel } from '../models/waiter.model';
import { WaiterService } from './waiter.service';

@Component({
    selector: 'app-waiter-create',
    templateUrl: './waiter.create.component.html'
})
export class WaiterCreateComponent implements OnInit {
    @ViewChild('waiterForm', { static: false }) waiterForm: GenericFormComponent;

    createRules: GenericFormType[] = WaiterCreateConstraints;
    venue: VenueModel;
    venueId: string;
    createInProgress: boolean;
    onClaimed: any;

    constructor(
        public bsModalRef: BsModalRef,
        private waiterService: WaiterService
    ) { }

    async ngOnInit() {
        this.venueId = this.venue.id;
        this.createInProgress = false;
    }

    async create() {
        if (this.waiterForm.isValid()) {
            try {
                this.createInProgress = true;
                let waiter: WaiterModel = this.waiterForm.toModel<WaiterModel>('WaiterCreateForm', 'WaiterModel');
                waiter = await this.waiterService.create(this.venueId, waiter);
                this.createInProgress = false;
                this.onClaimed();
                this.bsModalRef.hide();
            } catch (err) {
                this.createInProgress = false;
            }
        }
    }

    cancel() {
        this.bsModalRef.hide();
    }
}
