import { Component, OnInit, inject, ViewChild } from '@angular/core';
import { HappyHourService } from '../happy-hour.service';
import { HappyHourModel, HappyHourConstraints } from 'src/app/models/happy-hour.model';
import { VenueModel } from 'src/app/models/venue.model';
import { BsModalRef } from 'ngx-bootstrap';
import { GenericFormType, GenericFormComponent, GenericFormControl } from 'src/app/generic-form/generic.form.component';
import { cloneDeep } from 'lodash';
import { FileUploadComponent } from 'src/app/file.upload/file.upload.component';

@Component({
  selector: 'app-happy-hour-create-component',
  templateUrl: './happy-hour.create.component.html',
})
export class HappyHourCreateComponent implements OnInit {

  @ViewChild('menuItemForm', { static: false }) happyHourForm: GenericFormComponent;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUploadComponent;

  // provided by modal client
  venue: VenueModel;

  createRules: GenericFormType[];
  createInProgress: boolean;

  constructor(
    private happyhourService: HappyHourService,
    public bsModalRef: BsModalRef
  ) { }

  async ngOnInit() {
    this.createRules = cloneDeep(HappyHourConstraints.slice(0));
    const speciesSelection = this.createRules.find((x) => x.name === 'species');
    if (speciesSelection) {
      (speciesSelection as GenericFormControl).lookups[0].selected = true;
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  async create() {
    if (this.happyHourForm.isValid()) {
      this.createInProgress = true;
      try {
        let happyHour: HappyHourModel = this.happyHourForm.toModel<HappyHourModel>('HappyHourForm', 'HappyHourModel');
        happyHour.venueId = this.venue.id;
        console.log('ITEM', happyHour);
        happyHour = await this.happyhourService.create(happyHour);
        if (this.fileUpload.fileUploaded && this.fileUpload.filesData && this.fileUpload.filesData.length) {
          await this.happyhourService.uploadPhoto(happyHour.id, this.fileUpload.filesData[0]);
        }
        this.createInProgress = false;
        this.bsModalRef.hide();
      } catch (err) {
        console.error(err);
        this.createInProgress = false;
      }
    }
  }
}
