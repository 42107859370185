import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { VenueModel } from "../../models/venue.model";
import { ActivatedRoute, Router } from "@angular/router";
import { EventModel } from "src/app/models/event.model";
import { StorageService } from "src/app/common/storage.service";
import { MenuItemModel } from "src/app/models/menu-item.model";
import { VenueService } from "src/app/venue/venue.service";
import { EventService } from "src/app/event/event.service";
import { UserModel } from "src/app/models/user.model";
import { OrderService } from "src/app/order/order.service";
import { NotificationService } from "src/app/common/notification.service";
import { OrderModel } from "src/app/models/order.model";
import { UserService } from "src/app/user/user.service";
import { BasketService } from "src/app/mobile-ordering/basket/basket.service";
import { QRTabMyOrders } from "../tab-my-order/tab.my.order.payment.component";
import { QRTabSplitOrders } from "../tab-split-order/tab.split.order.payment.component";
import { environment } from "src/environments/environment";
import { SessionStorageService } from "src/app/common/sessionStorage.service";

@Component({
  selector: "app-qr-payment-tab",
  templateUrl: "./tab.payment.component.html",
  styleUrls: ["./tab.payment.component.scss"],
})
export class QRPaymentTabComponent implements OnInit, OnDestroy {
  @ViewChild(QRTabMyOrders, { static: false }) myOrdersComponent!: QRTabMyOrders;
  @ViewChild(QRTabSplitOrders, { static: false }) splitOrdersComponent!: QRTabSplitOrders;
  venue: VenueModel;
  venueSlug: string;
  event: EventModel;
  tableNumber: string;
  cart: any = [];
  isModalShown = false;
  orders!: OrderModel[]
  tips: string;
  tax: string;
  user: UserModel
  sessionId: string;
  session: any;
  bsModalRef?: BsModalRef;
  maxVisibleUsers: number = 2;
  allOrders!: OrderModel[]
  myOrders!: OrderModel[];
  eventSource!: EventSource
  host = environment.homePage;
  TABS = {
    MY_ORDERS: "myOrders",
    SPLIT_ORDERS: "splitOrders",
    PAY: "pay",
  };
  currentTab = this.TABS.MY_ORDERS
  constructor(
    private route: ActivatedRoute,
    private venueService: VenueService,
    private eventService: EventService,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService,
    private orderService: OrderService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private basketService: BasketService,
  ) {}
  async ngOnInit() {
    const slug = this.route.snapshot.paramMap.get("slug");
    const tableNumber = this.route.snapshot.paramMap.get("tableNo")
    this.sessionId = this.route.snapshot.paramMap.get("sessionId");
    this.tips = this.route.snapshot.queryParamMap.get("tips");
    this.tax = this.route.snapshot.queryParamMap.get("tax");
    const eventId = this.route.snapshot.paramMap.get("eventId");

    const cart = this.storageService.getItem("checkout-items");
    this.orders = this.storageService.getItem('orders')

    this.venue = await this.venueService.getBySlug(slug);
    this.venueSlug = slug;
    
    await this.getSession()
    
    await this.initializeUser()

    const event = await this.eventService.getById(eventId, this.user.token);

    this.event = event;
    this.cart = cart;
    
    this.tableNumber = tableNumber;

    if(!this.user.email && this.session ) {
      const { skipPreAuthorization, token, ...userToUpdate } = this.user 

      userToUpdate.email = this.session.customer_details.email
      userToUpdate.username = this.session.customer_details.email
      userToUpdate.lastName = userToUpdate.lastName ? userToUpdate.lastName : ""
      this.userService.update(this.user.id, userToUpdate, token)
      this.user.email = userToUpdate.email
      this.sessionStorageService.setItem("temporary-user", this.user)
    }
    
    await this.createOrder();
    await this.getOrders()
    await this.openConectionForEvents()
    this.checkEventFinished()
  }

  ngOnDestroy() {
    this.closeConection();
  }

  checkEventFinished() {
    const status = this.event.eventStatus
    const slug = this.venueSlug
    const tableNumber = this.tableNumber

    if(["paid", "completed", "rejected"].includes(status)){
      this.sessionStorageService.deleteItem('temporary-user')
      this.router.navigate([`/mobile-qr/${slug}/${tableNumber}`])
      this.notificationService.info("Event Finished")
    }
  }
  async initializeUser() {
    try {
      const user = this.sessionStorageService.getItem("temporary-user");
      this.user = await this.userService.getById(user.id, user.token)
      user.payment = this.user.payment

      this.sessionStorageService.setItem("temporary-user", user);
      this.user.token = user.token
    } catch (error) {
      console.error(error);
    }
  }
  async getSession() {
    let session = await this.orderService.getCheckoutInfo(this.sessionId)
    this.session = session
  }

  async createOrder() {
    if(!this.cart) {
      return
    }
    if (!this.session) {
      this.notificationService.error('There are some error creating the order');
      window.scroll(0, 150);
      return;
    }
    try {
      await this.orderService.createOrderRegular(
        this.event.id,
        this.tableNumber,
        this.cart,
        this.user.token
      );
     this.storageService.deleteItem("checkout-items");
     this.basketService.clearBasket()
    } catch (error) {
      console.error(error);
    }
  }
  async getMyOrders() {
    this.myOrders = await this.orderService.getOrders(this.event.id, this.user.id, this.user.token)
  }
  async getAllOrders() {
    this.allOrders = await this.orderService.getOrders(this.event.id, null, this.user.token)
  }
  
  async getOrders(){
    await this.getMyOrders()
    await this.getAllOrders()
  }
  getLowestPrice(menu: MenuItemModel) {
    const prices = [];
    if (menu.options) {
      menu.options.forEach((option) => {
        if (option.price) {
          prices.push(option.price.value);
        }
      });
    }
    return Math.min(...prices);
  }
  changeTab(event, tab: string) {
    this.getOrders()
    if(this.currentTab === tab) {
      return
    }
    const target = event.target;
    this.currentTab = tab

    document
      .querySelector(`.paymentTab-header-button.active`)
      .classList.remove("active");
    target.closest('.paymentTab-header-button').classList.add("active");
  }
  closeModal() {
    this.isModalShown = false;
  }
  async openConectionForEvents() {
    this.eventSource = await this.eventService.subscribeForEvent(this.event.id)
    this.eventSource.onmessage = async (event) => {
      const eventData = JSON.parse(event.data)
      const type = eventData.type

      if(type === "ORDER"){
        this.currentTab === this.TABS.MY_ORDERS ?
          this.myOrdersComponent.reloadOrders() :
          this.splitOrdersComponent.reloadOrders()
      }else {
        this.event = eventData.data
        this.checkEventFinished()
      }
    }

    this.eventSource.onerror = (error) => {
      console.error(`Error en SSE`, error);
      this.closeConection()
    }
  }
  closeConection(){
    this.eventSource.close()
  }


  goToPay(type: string) {
    return this.router.navigate([`/mobile-qr/${this.venue.slug}/${this.tableNumber}/${this.event.id}/${this.sessionId}/pay`], {
      queryParams: {type}
    })
  }
  navigateToMenu() {
    return this.router.navigate([`/mobile-ordering/menu/${this.venue.slug}/${this.tableNumber}/${this.event.id}`]);
  }

  $(selector: string) {
    return document.querySelector(selector);
  }

}
