import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { CommonModule } from '@angular/common';
import { Module as StripeModule } from 'stripe-angular';
import { PaymentCreateComponent } from '../payment/payment.create.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { LayoutModule } from '../layout/layout.module';
import { ApplePaymentComponent } from '../apple-payment/apple.payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { PaymentMethodComponent } from './payment.method.component';

@NgModule({
    declarations: [
        PaymentCreateComponent,
        ApplePaymentComponent,
        PaymentMethodComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GenericFormModule,
        AccordionModule,
        LaddaModule,
        StripeModule,
        NgxUsefulSwiperModule,
        LayoutModule,
        NgxStripeModule.forRoot('pk_test_51Q2ZvsL2ArYn1w1B9tZurNc4H3pzivAUWfKw2rYO0MecOyQ7eieYYeCxD2nODUN1LK14G7RsexIYEo2fyfnSNxEZ00gJ4W8MKV')
    ],
    providers: [],
    entryComponents: [PaymentCreateComponent],
    exports: [PaymentCreateComponent, ApplePaymentComponent, PaymentMethodComponent]
})
export class PaymentModule { }
