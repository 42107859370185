import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EventService } from '../event/event.service';

@Injectable({
  providedIn: 'root'
})
export class EventGuard implements CanActivate {
  constructor(private eventService: EventService, private router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    const id = route.params.id;

    try {
      const event = await this.eventService.getWebEvent(id);
      if (event.type === 'public') {
        this.router.navigate(['events', id]);
        return false;
      } else {
        this.router.navigate(['private-event', id]);
        return false;
      }
    } catch (error) {
      this.router.navigate(['events']);
      return false;
    }

  }

}
