import { Component, OnInit, inject } from '@angular/core';
import { BasketService, IBasket, IBasketItem } from './basket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MobileService } from '../mobile.service';
import { NotificationService } from 'src/app/common/notification.service';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/common/auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-mobile-ordering-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class MobileOrderingBasketComponent implements OnInit {

  basket: IBasket;
  private table: string;
  private venueSlug: string;
  private eventId: string;
  tableNumber: string = this.mobileService.tableNumber;
  tableNumberMandatory: boolean = false;
  loggedInUser: UserModel;

  constructor(
    private basketService: BasketService,
    private route: ActivatedRoute,
    private mobileService: MobileService,
    private router: Router,
    private toasterService: NotificationService,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    this.venueSlug = this.route.snapshot.paramMap.get('slug');
    this.table = this.route.snapshot.paramMap.get('table');
    this.eventId = this.route.snapshot.paramMap.get('id');
    if (this.route.snapshot.fragment) {
       this.eventId = this.route.snapshot.fragment.slice(-24);
    }
    this.basketService.getBasket().subscribe((value) => {
      this.basket = value;
    });
    this.tableNumberMandatory = await this.mobileService.getEventTableNumberMandatory(this.eventId);
  }

  async ngAfterViewInit() {
    // this.loggedInUser = await this.authService.isLoggedIn(); //await this.authService.isLoggedIn();
    this.loggedInUser = await this.authService.getCurrentUser();
    if (this.loggedInUser) {
      // aqui traer el numero de la mesa 
      const number = await this.mobileService.getUserTableNumber(this.mobileService.eventId, this.loggedInUser.id);
      this.mobileService.tableNumber = number;
      this.tableNumber = number;
    }
  }

  handleChange(e: any) {
    this.changeTableNumber(e.target.value)
  }

  changeTableNumber(tableNumber: string){
    this.mobileService.tableNumber = tableNumber;
  }

  getBasketItemPrice(basketItem: IBasketItem) {
    let price = basketItem.price;
    if (basketItem.addons && basketItem.addons.length) {
      const addonsWithPrice = basketItem.addons.filter((addon) => addon.includeAddon && !!addon.addonTotal);
      if (addonsWithPrice && addonsWithPrice.length) {
        addonsWithPrice.forEach(a => price += a.addonTotal * a.quantity * basketItem.quantity);
      }
    }
    return price.toFixed(2);
  }

  goBack() {
    this.router.navigateByUrl(`/mobile-ordering/menu/${this.venueSlug}/${this.table}/${this.eventId}`);
  }

  removeItemFromBasket(item: IBasketItem) {
    this.basketService.removeFromBasket(item);
  }

  goToCheckout() {
    if (this.tableNumberMandatory && !this.mobileService.tableNumber) {
      this.toasterService.warning("This event requires a table number");
      return;
    }
    this.router.navigateByUrl(`/mobile-ordering/checkout/${this.venueSlug}/${this.table}/${this.eventId}`);
  }
}
