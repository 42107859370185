import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotificationService } from '../common/notification.service';
import { UserService } from '../user/user.service';
import { StorageService } from '../common/storage.service';
import { UserModel } from '../models/user.model';

@Component({
  selector: 'app-verfication-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent implements OnInit {
    redirectingUrl: string;
    userToVerify: {returnUrl: string, user: any, phone?: string, email?: string, verificationCode?: string};
    verificationMedia: { method: string; value: string };
    verificationCodeForm: FormGroup;
    isVerificationInProgress = false
    phone?: string;
    email?: string;
    returnUrl: string;
    user: UserModel;
    registerUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private bsModalRef: BsModalRef,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private userService: UserService,
    private fb: FormBuilder,

  ) { }

  ngOnInit() {
    const userToVerify = this.storageService.getItem('userToVerify');
    if(!userToVerify) {
      this.router.navigateByUrl('/login');
    }
    this.user = userToVerify.user;
    this.verificationMedia = userToVerify.email ? { method: 'email', value: userToVerify.email } : { method: 'phone', value: userToVerify.phone };
    this.returnUrl = userToVerify.returnUrl;
    this.verificationCodeForm = this.fb.group({
        verificationCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
    this.verificationCodeForm
      .get('verificationCode')
      .valueChanges.subscribe((value: string) => {
        if (value && value.length === 6) {
          this.onSubmit();
        }
      });

  }
  isFormValid() {
    return this.verificationCodeForm.get('verificationCode').invalid && (this.verificationCodeForm.get('verificationCode').dirty || this.verificationCodeForm.get('verificationCode').touched)
  }
  async onSubmit() {
    this.isVerificationInProgress = true;
    try {
      const verificationCode = this.verificationCodeForm.get(
        'verificationCode'
      ).value;

      const query = this.verificationMedia.method === 'email' ? { email: this.verificationMedia.value } : { phoneNumber: this.verificationMedia.value };

      const result = await this.userService.verifyPhoneOrEmail(this.user ? this.user.id : null, verificationCode, query);
      if (result) {
        this.user && this.user.id ? null : this.notificationService.success('Verification', 'You have successfully verified your phone number.');
        
        await this.redirectUser();
      } else {
        this.notificationService.error('Verification', 'Invalid verification code. Please try again.');
      }
      this.isVerificationInProgress = false;
    } catch (error) {
      console.error('Verification Error', error);
      this.isVerificationInProgress = false;
    }
  }
  async redirectUser() {
    if(!this.user || (this.user && this.user.role === "POTENTIAL" && !this.user.birthDay)) {
      this.isVerificationInProgress = false;
      const options = {
        phone: this.phone,
        email: this.email,
        verificationCode: this.verificationCodeForm.get('verificationCode').value,
        wasPotential: this.user && this.user.role === "POTENTIAL" ? true : false,
      }
      this.storageService.setItem('signUpOptions', options)
      return this.signUp();
    }
    return this.signIn()
  }
  cleanUserToVerify() {
    this.storageService.deleteItem('userToVerify');
  }
  signUp() {
    console.log('Signing up...');
    this.registerUrl = '/register-user';
    if (this.returnUrl) {
      this.router.navigateByUrl(`${this.registerUrl}?returnUrl=${this.returnUrl}`);
    } else {
      this.router.navigateByUrl(`${this.registerUrl}`);
    }
    this.bsModalRef.hide();
  }
  async signIn() {
    try {
      const user = await this.authService.loginWithPhoneOrEmail(
        this.user.email, 
        this.user.phone, 
        this.verificationCodeForm.get('verificationCode').value
      );
      if (user && user.token) {
        this.isVerificationInProgress = false;
        this.router.navigateByUrl(this.returnUrl);
        this.cleanUserToVerify();
      }
    } catch (error) {
      this.isVerificationInProgress = false;
      console.error("Error", error);
    }
  }

  async resendCode() {
    try {
      const payload = this.verificationMedia.method === 'email' ? 
        { email: this.verificationMedia.value } : 
        { phone: this.verificationMedia.value };

      await this.userService.sendVerificationMessage(
        null,
        { ...payload, deviceId: this.storageService.getItem('deviceId') }
      );
    } catch (error) {
      console.error('Verification Error', error);
    }
  }
  restrictLength(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value && input.value.length > 6) {
      input.value = input.value.slice(0, 6);
    }
  }
}
