import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { VenueService } from './venue.service';
import { BsModalService, BsModalRef, TabsetComponent } from 'ngx-bootstrap';
import { VenueModel } from '../models/venue.model';
import { ActivatedRoute } from '@angular/router';
import { MenuService } from '../menu-item/menu.service';
import { MenuItemModel, MenuTypeSpeciesMap } from '../models/menu-item.model';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';
import { UserRoles } from '../data/user.roles.data';
import { MenuItemCreateComponent } from '../menu-item/menu-item.create.component';
import { MenuItemEditComponent } from '../menu-item/menu-item.edit.component';
import { MenuCategoryEditComponent } from '../menu-item/menu-category.edit.component';
import { MenuModel } from '../models/menu.model';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GenericMenuCategories } from '../data/generic.menu.categories.data';
import { Lookup } from '../generic-form/generic.form.component';
import { EnumService } from '../common/enum.service';
import { HappyHourModel } from '../models/happy-hour.model';
import { HappyHourlistComponent } from '../happy-hour/happy-hour.list/happy-hour.list.component';
import { MenuCategoryCopyComponent } from '../menu-category/menu-category.copy.component';

@Component({
  selector: 'app-venue-menu',
  templateUrl: './venue.menu.component.html',
  styleUrls: ['./venue.menu.component.scss']
})
export class VenueMenuComponent implements AfterViewInit {

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  constructor(
    private venueService: VenueService,
    private modalService: BsModalService,
    private menuService: MenuService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private enumService: EnumService
  ) { }
  currentCategories: any[];
  venue: VenueModel;
  currentUser: UserModel;
  menues: MenuModel[];
  bsModalRef: BsModalRef;
  lowestPrice: number;
  updateAvailabilityInProgress: boolean;
  updatingMenuCategory: string;
  showMenuInfo: boolean;
  menueTabs = [];
  menuTypes: any[];
  currentTab: number;
  currentDraggableEvent: DragEvent;
  currentTabIndex: number;

  async ngAfterViewInit() {
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.venue = await this.venueService.getBySlug(slug);
    this.currentUser = this.authService.getCurrentUser();
    this.updateAvailabilityInProgress = false;
    this.showMenuInfo = false;
    await this.getMenuTypes();
    await this.loadMenus();
    this.currentTab = -1;
  }

  async loadMenus() {
    const categories = [];
    this.menues = await this.menuService.getAllMenues(this.venue.id);
    this.menues.forEach(item => {
      const mt = this.menuTypes.find((x) => x.key === item.type);
      item.name = mt ? mt.value : item.type;
      item.categories.forEach((childItem, index) => {
        childItem.id = `${item.id}${index}`;
        categories.push(childItem);
      });
    });
    await this.getMenuTypes();
    if (this.staticTabs) {
      const activeTab = this.staticTabs.tabs.find((x) => x.active);
      const tabIndex = this.staticTabs.tabs.indexOf(activeTab);
      this.currentTabIndex = tabIndex;
    }
    this.currentCategories = categories;
  }

  async getMenuTypes() {
    // const menuTypes = await this.enumService.getByType('menu-types');
    this.menueTabs = [];
    this.menuTypes = await this.menuService.getMenuTypes(this.venue.id, false);
    this.menuTypes.forEach((element, index) => {
      this.menueTabs.push({
        key: element.key,
        label: element.value,
        active: index === this.currentTabIndex
      });
    });
    if (!this.currentTabIndex || this.currentTabIndex === -1) {
      this.menueTabs[0].active = true;
    }
  }

  isAdmin() {
    return this.currentUser && this.currentUser.role === UserRoles.ADMIN;
  }

  isOwner() {
    return (this.currentUser && this.currentUser.id === this.venue.ownerId);
  }

  toggleMenuInfo(i) {
    if (this.currentTab !== i) {
      this.currentTab = i;
      this.showMenuInfo = true;
    } else {
      this.showMenuInfo = !this.showMenuInfo;
    }
  }

  getLowestPrice(menu: MenuItemModel) {
    const prices = [];
    if (menu.options) {
      menu.options.forEach((option) => {
        prices.push(option.price.value);
      });
    }
    return Math.min(...prices);
  }

  async updateMenuItemAvailability(menu: MenuItemModel) {
    this.updateAvailabilityInProgress = true;
    setTimeout(() => {
      this.menuService.updateAvailablity(this.venue.id, menu.id, menu.available, menu.type);
    });
    this.updateAvailabilityInProgress = false;
  }

  async openAddMenuItemModal() {
    this.setActiveTab();
    const activeTab = this.staticTabs.tabs.find((x) => x.active);
    const initialState = {
      venue: this.venue,
      loadMenus: this.loadMenus.bind(this),
      categories: this.getAllCategories(),
      happyHours: await this.getAllHappyHours(),
      species: MenuTypeSpeciesMap[activeTab.heading] || MenuTypeSpeciesMap.default,
      selectedTab: this.menueTabs.find((x) => x.label === activeTab.heading),
      menues: this.menues.map((x) => {
        return { label: x.name, value: x.type };
      })
    };
    this.bsModalRef = this.modalService.show(MenuItemCreateComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  async openEditMenuCategoryModal(menu) {
    const initialState = {
      venue: this.venue,
      menu,
      loadMenus: this.loadMenus.bind(this),
      getMenuTypes: this.getMenuTypes.bind(this)
    };
    this.bsModalRef = this.modalService.show(MenuCategoryEditComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  async openCopyMenuCategoryModal(menu) {
    const initialState = {
      venue: this.venue,
      menu,
      loadMenus: this.loadMenus.bind(this),
      menuTypes: this.menuTypes
    };
    this.bsModalRef = this.modalService.show(MenuCategoryCopyComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  async openEditMenuItemModal(menu: MenuItemModel, category: string) {
    this.setActiveTab();
    const initialState = {
      venue: this.venue,
      menu,
      loadMenus: this.loadMenus.bind(this),
      categories: this.getAllCategories(),
      currentCategory: category,
      happyHours: await this.getAllHappyHours(),
      menues: this.menues.map((x) => {
        return { label: x.name, value: x.type };
      })
    };
    this.bsModalRef = this.modalService.show(MenuItemEditComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  async deleteMenuItem(item: MenuItemModel) {
    const res = await this.confirmationDialogService.popup(
      `Delete ${item.name}`,
      `Are you sure you want to delete ${item.name}?`
    );
    if (res) {
      await this.menuService.delete(item.id);
      this.setActiveTab();
      this.loadMenus();
    }
  }

  setActiveTab() {
    const activeTab = this.staticTabs.tabs.indexOf(this.staticTabs.tabs.find(t => t.active));
    this.currentTab = activeTab;
    this.currentTabIndex = activeTab;
  }

  getMenuItems(menu: string) {
    const menuItems = this.menues.filter((el) => el.type === menu);
    return menuItems;
  }

  updateMenuItemsPosition(tab: string) {
    const menuItem = this.getMenuItems(tab)[0];
    menuItem.venueId = this.venue.id;
    menuItem.categories.forEach((c, index) => {
      c.position = index + 1;
    });
    this.menuService.updateMenuByType(this.venue.id, menuItem);
  }

  deleteMenuCategory(tabName: string, categoryName: string) {
    const menu = this.getMenuItems(tabName)[0];
    menu.categories = menu.categories.filter((item) => item.name !== categoryName);
    this.updateMenuItemsPosition(tabName);
  }

  enableCategoryDescription(menuIndex: number, index: number) {
    this.updatingMenuCategory = `categoryDescription${menuIndex}${index}`;
    setTimeout(() => {
      document.getElementById(`categoryDescription${menuIndex}${index}`).focus();
    }, 500);
  }

  async onDescriptionBlur(event, menu, categoryIndex) {
    const value = event.target.value;
    if (!this.updatingMenuCategory) {
      return;
    }
    menu.categories[categoryIndex].description = value;
    menu.venueId = this.venue.id;
    this.updatingMenuCategory = null;
    await this.menuService.updateMenuByType(this.venue.id, menu);
  }

  drop(event: CdkDragDrop<string[]>, tab: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.updateMenuItemsPosition(tab);
  }

  getConnectedList(key: string): any[] {
    const menuItem = this.getMenuItems(key)[0];
    return menuItem.categories.map(x => `${x.id}`);
  }

  dropGroup(event: CdkDragDrop<string[]>, tab: string) {
    const menuItem = this.getMenuItems(tab)[0];
    moveItemInArray(menuItem.categories, event.previousIndex, event.currentIndex);
    this.updateMenuItemsPosition(tab);
  }

  shouldShowCategory(category) {
    if (category.name === GenericMenuCategories.UNKNOWN.value) {
      return (category.items && category.items.length);
    }
    return true;
  }

  openHappyHourlistModal() {
    const initialState = {
      venue: this.venue,
      loadMenus: this.loadMenus.bind(this),
    };
    this.bsModalRef = this.modalService.show(HappyHourlistComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
  }

  private getAllCategories(): Lookup[] {
    const allCategories = [];
    this.menues.forEach((x: MenuModel) => {
      x.categories.forEach((c) => {
        if (allCategories.indexOf(c.name) === -1) {
          allCategories.push(c.name);
        }
      });
    });
    return allCategories.map((c) => {
      return {
        value: c,
        label: c
      };
    });
  }

  private async getAllHappyHours(): Promise<HappyHourModel[]> {
    const happyHours: HappyHourModel[] = await this.venueService.getVenueHappyHours(this.venue.id);
    // return happyHours.map((x) => {
    //   return {
    //     value: x.name,
    //     label: x.name
    //   };
    // });
    return happyHours;
  }
}
