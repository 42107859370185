import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { VenueService } from '../venue.service';
import { GenericFormType, GenericFormComponent } from '../../generic-form/generic.form.component';
import { GenericFormBuilder } from '../../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { AuthService } from '../../common/auth.service';
import { UserModel } from '../../models/user.model';
import { UserService } from '../../user/user.service';
import { UserRoles } from '../../data/user.roles.data';
import { NotificationService } from '../../common/notification.service';
import { retypePasswordValidator } from '../../models/user.model';

import { ActivatedRoute, Router } from '@angular/router';
import { VenueModel } from '../../models/venue.model';
import { StorageService } from '../../common/storage.service';
@Component({
  selector: 'app-venue-claim',
  templateUrl: './venue.claim.component.html',
  styleUrls: ['./venue.claim.component.scss']
})
export class VenueClaimComponent implements OnInit{

  @ViewChild('claimVenueForm', { static: false }) claimVenueForm: GenericFormComponent;

  venue: VenueModel;
  claimInProgress: boolean;
  currentUser: UserModel;
  claimVenueFormRules: GenericFormType[];
  country!: string;
  private geoCoder;
  constructor(
    private venueService: VenueService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.geoCoder = new google.maps.Geocoder();
  }
  
  getCountryCode(lat:number, long:number) {
    this.geoCoder.geocode({ location: { lat: lat, lng: long } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const countryComponent = results[0].address_components.find((component) =>
            component.types.includes('country')
          );
          if(countryComponent){
            this.country = countryComponent.short_name;
          }
        }
      }
    });
  }
  async ngOnInit() {
    if (this.authService.isLoggedIn().value) {
      this.authService.logout();
    }
    this.currentUser = this.authService.getCurrentUser();
    const slug: string = this.route.snapshot.paramMap.get('slug');
    const venue = await this.venueService.getBySlug(slug);
    this.venue = venue
    this.getCountryCode(venue.location.latitude, venue.location.longitude);
    if (this.venue.ownerId) {
      this.navigateBack();
      return;
    }
  }

  navigateBack = () => this.router.navigateByUrl(`/venues/${this.venue.slug}`);

}
