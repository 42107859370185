export const environment = {
  production: process.env["PRODUCTION"],
  api: {
    host: process.env["HOST"]
  },
  dateFormat: process.env["DATE_FORMAT"],
  datTimeFormat: process.env["DATTIMEFORMAT"],
  stripeKey: process.env["STRIPEKEY"],
  intercomId: process.env["INTERCOMID"],
  homePage: process.env["HOMEPAGE"],
  agmCoreApiKey: process.env["AGMCOREAPIKEY"],
  secondaryCuculiDomain: process.env["SECONDARYCUCULIDOMAIN"],
  firebaseConfig: {
    apiKey: process.env["FIREBASE_APIKEY"],
    authDomain: process.env["FIREBASE_AUTHDOMAIN"],
    projectId: process.env["FIREBASE_PROJECTID"],
    storageBucket: process.env["FIREBASE_STORAGEBUCKET"],
    messagingSenderId: process.env["FIREBASE_MESSAGINGSENDERID"],
    appId: process.env["FIREBASE_APPID"],
    measurementId: process.env["FIREBASE_MEASUREMENTID"]
  }
};