import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  MenuItemModel,
  MenuItemOptionModel,
} from "src/app/models/menu-item.model";
import { OrderModel } from "src/app/models/order.model";
import { UserModel } from "src/app/models/user.model";

@Component({
  selector: "app-qr-payment-item",
  templateUrl: "./payment.item.component.html",
  styleUrls: ["../tab.payment/tab.payment.component.scss"],
})
export class QRItemComponent implements OnInit {
  @Input() item!: MenuItemOptionModel;
  @Input() participants!: UserModel[];
  @Input() order!: OrderModel;
  @Input() showAvatar!: boolean;
  @Input() showDelete!: boolean;
  @Input() showLock!: boolean;
  @Input() showAdd!: boolean;
  @Input() user!: UserModel;
  @Input() isSplitScreen?: boolean;
  maxVisibleUsers = 2;

  @Output() onAction = new EventEmitter<{
    order: OrderModel;
    item: MenuItemModel;
  }>();
  @Output() onRemove = new EventEmitter<{
    order: OrderModel;
    item: MenuItemModel;
  }>();

  ngOnInit(): void {
  }
  onClick(order: OrderModel, item: MenuItemModel) {
    this.onAction.emit({ order, item });
  }
  remove(event: Event, order: OrderModel, item: MenuItemModel) {
    event.stopPropagation();
    this.onRemove.emit({order, item})
  }
  getPrice() {
    if(this.isSplitScreen) {
      return this.item.price.value;
    }
    if(this.order && this.user) {
      const payer = this.order.payers.find(p => p.userId === this.user.id)
      if(payer) {
        return this.item.price.value * this.item.quantity * (payer.percentage / 100)
      }
      return this.item.price.value
    }
  }

  getPayers(order: OrderModel) {
    return this.participants
      ? this.participants.filter((participant) =>
          order.payers.some((payer) => payer.userId === participant.id)
        )
      : [];
  }
}
