import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotificationService } from '../common/notification.service';
import { LoginModalComponent } from './login.modal.component';
import { UserService } from '../user/user.service';
import { StorageService } from '../common/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginInProgress: boolean;
  googleLoginInProgress: boolean;
  shouldShowModal: boolean;
  isPhoneNumberLogin = true;
  verificationInProgress = false;
  isInputValid = true;
  userExists = null;
  deviceId: string;


  @Input() returnUrl: string;
  @Input() registerUrl: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.loginInProgress = false;
    this.resetForm();
    this.shouldShowModal = false;
    let deviceId = this.storageService.getItem('deviceId')
    if(!deviceId) {
      deviceId = this.generateCompactUUID();
      this.storageService.setItem('deviceId', deviceId);
    }
    this.deviceId = deviceId;

    if (this.router.url.indexOf('returnUrl') !== -1) {
      // tslint:disable-next-line:no-string-literal
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    } else {
      this.returnUrl = '/events';
    }

    const currentUser = this.authService.getCurrentUser();
    if (currentUser) {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  toggleLoginModal() {
    const initialState = {
      class: 'modal-dialog-border-bottom',
      loginFn: this.login,
      signUpFn: this.signUp,
      forgotPasswordFn: this.forgotPassword,
      returnUrl: this.returnUrl,
      registerUrl: this.registerUrl,
    };
    const bsModalRef = this.modalService.show(LoginModalComponent, { initialState });
    bsModalRef.setClass(initialState.class);
  }

  signUp() {
    this.registerUrl = this.registerUrl || '/register-user';
    if (this.returnUrl) {
      this.router.navigateByUrl(`${this.registerUrl}?returnUrl=${this.returnUrl}`);
    } else {
      this.router.navigateByUrl(`${this.registerUrl}`);
    }
    this.bsModalRef.hide();
  }
  composePhoneNumber = (phoneNumber: {
    nationalNumber: string;
    dialCode: string;
  }) => {
    return `${phoneNumber.dialCode}${phoneNumber.nationalNumber.replace(/\D/g, "")}`;
  };
  async login(email?: string, isGoogleLogin?: boolean, userFromGoogle?: any) {
    try {
      if (this.verificationInProgress) {
        return;
      }
      this.verificationInProgress = true;
      const response = this.isPhoneNumberLogin ? await this.validatePhoneNumber() : await this.validateEmail(email);
      const queryPayload = this.isPhoneNumberLogin ? { phone: this.composePhoneNumber(this.loginForm.get('phone').value) } : { email: email ? email : this.loginForm.get('email').value.toLowerCase() };
      queryPayload['deviceId'] = this.deviceId;

      if(response.valid) {
        const verificationSent = await this.userService.sendVerificationMessage(null, queryPayload)

        if(!verificationSent) {
          this.notificationService.error('Verification', 'Verification could not be sent');
          this.verificationInProgress = false;
          return;
        }
        let user = null
        if(response.user){
          if(response.user.length > 1) {
            user = response.user.find(u => u.role !== "POTENTIAL") || response.user[0]
          }else {
            user = response.user[0]
          }
        }
        this.storageService.setItem('userToVerify', { user, ...queryPayload, returnUrl: this.returnUrl, isGoogleLogin, userFromGoogle });
        this.router.navigateByUrl(`/verification-code`);
      }
      this.verificationInProgress = false;
    } catch (error) {
      this.verificationInProgress = false;
      console.error("Login error", error);
    }
  }
  async validatePhoneNumber() {
    const phone = this.composePhoneNumber(this.loginForm.get('phone').value);
    let response = await this.userService.validatePhoneNumber(phone);
    if(response) {
      this.userExists = response.user;
      this.isInputValid = response.valid;
      return response;
    }
    return response
  }
  async validateEmail(email?: string) {
    const emailToLog = email ? email.toLowerCase() : this.loginForm.get('email').value.toLowerCase()
    const response = await this.userService.validateEmail(emailToLog);
    this.userExists = response.user;
    this.isInputValid = response.valid;
    return response
  }

  async loginWithGoogle() {
    if (this.googleLoginInProgress) {
      return;
    }
    this.googleLoginInProgress = true;
    try {
      let user = await this.authService.loginWithGoogle();
      if(!user) {
        this.googleLoginInProgress = false
        return
      }
      this.isPhoneNumberLogin = false
      const loggedUser = this.storageService.getItem('user')
      if(loggedUser && loggedUser.token) {
        this.googleLoginInProgress = false
        return this.router.navigateByUrl(this.returnUrl);
      };
      this.googleLoginInProgress = false
      this.login(user.email, true, user)
      return 
    } catch (error) {
      this.googleLoginInProgress = false;
      console.error(`Error`, error);
    }
  }
  generateCompactUUID(): string {
    return Array.from({ length: 16 }, () =>
      Math.floor(Math.random() * 16).toString(16)
    ).join('');
  }

  public togglePhoneNumberLogin() {
    this.isPhoneNumberLogin = !this.isPhoneNumberLogin;
    this.resetForm();
  }

  async forgotPassword() {
    this.router.navigateByUrl('/reset-password');
    this.bsModalRef.hide();
  }

  private resetForm() {
    this.loginForm = this.fb.group({
      email: [null, []],
      phone: [null, []],
    });
  }
}
