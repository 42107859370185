import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';
import { BaseService } from '../common/base.service';
import { EventModel } from '../models/event.model';
import { Statuses } from '../data/statuses.data';

@Injectable()
export class EventService extends BaseService<EventModel> {

  constructor(
    apiService: ApiService,
    automapper: AutomapperService) {
    super(apiService, automapper);
  }

  protected getModelName(): string {
    return 'EventModel';
  }
  protected getApiName(): string {
    return 'EventApi';
  }
  protected getApiPrefix(): string {
    return 'event';
  }

  public async inviteParticipant(event: EventModel, email: string) {
    const payload = {
      participant: email
    };
    return this.apiService.post(`/${this.getApiPrefix()}/${event.id}/invited-participants`, payload, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async acceptInvitation(event: EventModel, email: string) {
    const payload = {
      id: event.id,
      participant: email
    };
    return this.apiService.put(`/${this.getApiPrefix()}/${event.id}/participants`, payload, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async removeInvitation(event: EventModel, email: string) {
    const index = event.invitedParticipants.indexOf(email);
    event.invitedParticipants.splice(index, 1);
    const payload = {
      id: event.id,
      invitedParticipants: event.invitedParticipants
    };

    return this.apiService.put(`/${this.getApiPrefix()}/${event.id}/participants`, payload, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async leaveEvent(event: EventModel, email: string) {
    return this.apiService.delete(`/${this.getApiPrefix()}/${event.id}/participants/${email}`, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async updateEvent(event: EventModel) {
    const payload = {
      id: event.id,
      payload: {
        id: event.id,
        ownerId: event.ownerId,
        name: event.name,
        phone: event.phone,
        minParticipants: event.minParticipants,
        maxParticipants: event.maxParticipants,
        notes: event.notes,
        email: event.email,
      },
    };
    return this.apiService.put(`/${this.getApiPrefix()}/${event.id}`, payload, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async approveEvent(event: EventModel) {
    return this.apiService.post(`/${this.getApiPrefix()}/${event.id}/status`, { status: Statuses.APPROVED }, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async rejectEvent(event: EventModel) {
    return this.apiService.post(`/${this.getApiPrefix()}/${event.id}/status`, { status: Statuses.REJECTED }, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async activateEvent(event: EventModel) {
    return this.apiService.post(`/${this.getApiPrefix()}/${event.id}/status`, { status: Statuses.ACTIVE }, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async completeEvent(event: EventModel) {
    return this.apiService.post(`/${this.getApiPrefix()}/${event.id}/status`, { status: Statuses.COMPLETED }, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async noShowEvent(event: EventModel) {
    return this.apiService.post(`/${this.getApiPrefix()}/${event.id}/status`, { status: Statuses.NOSHOW }, [])
      .then((res) => {
        return this.automapper.map<EventModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async getEventTickets(eventId: string) {
    return this.apiService.get(`/event/${eventId}/tickets`, []);
  }

  public async purchaseTicket(eventId: string, ticketId: string, sourceId: string, shippingInfo?: any[], quantity?: number, platform?: string, forGuestUser?: boolean) {
    if (forGuestUser) {
      return this.apiService.post(`/tickets`, {
        eventId,
        ticketId,
        sourceId,
        shipping: shippingInfo,
        quantity,
        platform,
        forGuestUser
      }, []);
    } else {
      return this.apiService.post(`/tickets`, {
        eventId,
        ticketId,
        sourceId,
        shipping: shippingInfo,
        quantity,
        platform,
      }, []);
    }
  }

  async getDeliveryMenu(eventId: string): Promise<any> {
    const url = `/event/${eventId}/delivery-menu`;
    return this.apiService.get(url, []);
  }

  async JoinPotentialUSer(eventId: string, email: string): Promise<any> {
    const url = `/event/${eventId}/potential`;
    const res = this.apiService.put(url, {userEmail: email}, []);
    return res;
  }

  async getEventEntranceCharges(eventId: string) {
    const url = `/event/${eventId}/entrance-charges`;
    return this.apiService.get(url, []);
  }

  async userHasTicket(eventId: string, userId) {
    // get if user has ticket for a event (needs auth)
    const url = `/event/${eventId}/user/${userId}/is-ticket-purchased`;
    const res = this.apiService.get(url, []);
    return res;
  }

  async guestUserHasTicket(eventId: string, userEmail: string) {
    // get if user has ticket for a event (needs auth)
    const url = `/event/${eventId}/user/${userEmail}/guest-user-ticket`;
    const res = this.apiService.get(url, []);
    return res;
  }

  async getWebEvent(id: string): Promise<EventModel> {
    return this.apiService.get(`/${this.getApiPrefix()}/web/${id}`, [])
      .then((res) => {
        return res;
      });
  }
}
