import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/common/auth.service';
import { NotificationService } from 'src/app/common/notification.service';
import { UserModel } from 'src/app/models/user.model';
import { OrderService } from 'src/app/order/order.service';
import { BasketService } from '../basket/basket.service';

@Component({
  selector: 'app-cancel-payment',
  templateUrl: './cancel-payment.component.html',
  styleUrls: ['./cancel-payment.component.scss']
})
export class CancelPaymentComponent implements OnInit {

  loading: boolean = false;
  session = null;
  eventId: string = null;
  venueId: string = null;
  table: string = null;
  loggedInUser: UserModel;
  venueSlug: string = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private orderService: OrderService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private basketService: BasketService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.loading = true;
    const sessionId = this.activeRoute.snapshot.paramMap.get('sessionId');
    this.session = await this.orderService.getCheckoutInfo(sessionId);
    this.eventId = this.activeRoute.snapshot.paramMap.get('id');
    this.venueId = this.session.metadata.venueId;
    this.table = this.session.metadata.table;
    this.venueSlug = this.session.metadata.venueSlug;
    this.loggedInUser = this.authService.getCurrentUser();
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!this.loggedInUser) {
      if (userData) {
        this.loggedInUser = this.authService.setCurrentUser(userData);
        this.loggedInUser = await this.authService.refreshUserData();
      }else{
        return;
      }
    }
    // create order
    if (!this.session) {
      this.notificationService.error('There are some creating the order');
      window.scroll(0, 150);
      return;
    }
    try {
      const response = await this.orderService.cancelCheckoutSession(this.loggedInUser.id, this.eventId, this.session.id);
      if (response) {
        this.basketService.clearBasket();
        this.router.navigateByUrl(`/mobile-ordering/home/${this.venueSlug}/${this.table}/${this.eventId}`);
      }
      
    } catch (error) {
      this.notificationService.error('There are some canceling your order');
    }
  }

}
