import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { UserModel } from '../models/user.model';
import { AutomapperService } from '../common/automapper.service';
import { BaseService } from '../common/base.service';
import { NotificationService } from '../common/notification.service';
import { INotificationConfigType } from '../data/notification.config.types';

@Injectable()
export class UserService extends BaseService<UserModel> {

  constructor(
    apiService: ApiService,
    private toasterService: NotificationService,
    automapper: AutomapperService) {
      super(apiService, automapper);
  }

  create(data: UserModel): Promise<any> {
    return this.apiService.post(`/users`, data, [])
        .then((res) => {
            return this.automapper.map('UserApi', 'User', res);
        });
  }

  createTemporaryUser(data: UserModel): Promise<any> {
    return this.apiService.post(`/temp/users`, {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password
    }, [])
        .then((res) => {
            return this.automapper.map('UserApi', 'User', res);
        });
  }

  getActiveEvent(userId: string): Promise<any> {
    return this.apiService.get(`/${this.getApiPrefix()}/${userId}/events/active`, [])
      .then((res) => {
        return res;
      });
  }

  findByKeyword(keyword: string): Promise<UserModel[]> {
    return this.apiService.get(`/${this.getApiPrefix()}/search/${keyword}`, [])
      .then((res) => {
        return this.automapper.mapArray<UserModel>('UserApi', 'User', res);
      });
  }

  updatePassword(userId: string, oldPassword: string, newPassword: string): Promise<any> {
    return this.apiService.put(`/${this.getApiPrefix()}/${userId}/password`, {oldPassword, newPassword}, [])
      .then((res) => {
        this.toasterService.success('Password successfully changed.');
      });
  }

  addNewCreditCard(userId: string, creditCard: any): Promise<any> {
    return this.apiService.post(`/${this.getApiPrefix()}/${userId}/new-credit-card`, creditCard, [])
      .then((res) => {
        console.log(res);
        return this.automapper.mapArray<UserModel>('UserApi', 'User', res);
      });
  }

  deleteCreditCard(userId: string, creditCardId: string): Promise<any> {
    return this.apiService.delete(`/${this.getApiPrefix()}/${userId}/credit-card/${creditCardId}`, [])
      .then((res) => {
        if (res) {
          this.toasterService.success('Credit Card successfully deleted.');
        }
      });
  }

  getUserEvents(userId: string, payload?: any): Promise<any> {
    let query = `?`;
    if (payload && payload.fromDate) {
      query += `fromDate=${payload.fromDate}`;
    }
    if (payload && payload.statuses) {
      const x = payload.statuses.map(item => `statuses=${item}`).join('&');
      query += `&${x}`;
    }
    return this.apiService.get(`/user/${userId}/events${query}`, []);
  }

  createGuestUser(firstName: string, lastName: string, email: string, phone: string) {
    return this.apiService.post(`/users/guest`, {
      firstName,
      lastName,
      email,
      phone
    }, [])
      .then((res) => {
          return this.automapper.map('UserApi', 'User', res);
      });
  }

  createPotentialUser(firstName: string, lastName: string, email: string, phone: string, eventId?: string) {
    return this.apiService.post(`/users/potential`, {
      firstName,
      lastName,
      email,
      phone,
      eventId,
    }, [])
      .then((res) => {
          return this.automapper.map('UserApi', 'User', res);
      });
  }

  updateNotificationConfig(userId: string, notificationConfig: any): Promise<any> {
    return this.apiService.post(`/user/${userId}/notifications`, {
      notificationConfig
    });
  }

  updateUserNotificationConfigs(userId: string, notificationConfigs: INotificationConfigType[], unsubReason: string): Promise<boolean> {
    return this.apiService.post(
      `/user/notification-configs`,
      {
        userId,
        configs: notificationConfigs,
        unsubReason,
      },
      [],
    );
  }

  unsubscribeFromMailChimp(userId: string): Promise<any> {
    return this.apiService.post(`/mailchimp/unsubscribe-user`, {
      userId
    },
    [],
  );
  }

  protected getModelName(): string {
    return 'User';
  }
  protected getApiName(): string {
    return 'UserApi';
  }
  protected getApiPrefix(): string {
    return 'users';
  }
}
