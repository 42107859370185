import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import { IBasketItem } from './basket/basket.service';
import { ApiService } from '../common/api.service';
import { NotificationService } from '../common/notification.service';

export interface IEvent {
  id: string;
  ownerId: string;
  venueId: string;
  name: string;
  type: string;
  email?: string;
  phone?: string;
  notes?: string;
  venueName?: string;
  venueImage?: string;
  features?: string[];
  categories?: string[];
  neighborhood?: string;
  maxParticipants?: number;
  minParticipants?: number;
  startDate: string;
  endDate: string;
  participants: string[];
  seatNumbers?: any[];
  invitedParticipants?: string[];
  formerParticipants?: string[];
  coHosts?: {
      invited: string[];
      accepted: string[];
      rejected: string[];
  };
  eventStatus: string;
  orders: string[];
  autoApproveOrders?: boolean;
  table?: string;
  waiterId?: string;
  specialRequest?: string;
  menuOptions?: string;
  age?: { from: number, to: number };
  gender?: string;
  description?: string;
  internalDescription?: string;
  splitEven?: boolean;
  autoJoin?: boolean;
  geoJSON: {
      type: string;
      coordinates: number[];
  };
  priceRating: number;
  active?: boolean;
  chatReference?: string;
  groupId?: string;
  groupName?: string;
  groupImage?: string;
  mainImage?: string;
  discounts?: IDiscount[];
  waitlist?: IWaitlist[];
  oldCoreData?: {
      maxParticipants?: number;
      minParticipants?: number;
      startDate?: string;
      endDate?: string;
      location?: {
          latitude?: number;
          longitude?: number;
          address?: string;
          name?: string;
      }
  };
  isTakeout?: boolean;
  isSitAndEat?: boolean;
  isVirtual?: boolean;
  entranceFee?: {
      enabled: boolean,
      taxApplied?: boolean,
      serviceFeeApplied?: boolean,
      value: number,
      total?: number,
      description?: string,
      type?: string,
      startDate?: string,
      endDate?: string,
      absorbFees?: boolean,
      venueFee?: number,
      price?: number,
  };
  paymentConfig?: IPaymentConfig;
  tickets?: {
      totalAmount: number;
      available?: number;
      isSoldOut?: boolean;
      serviceComission?: number;
      tax?: number;
      serviceFee?: number;
  };
  registrationMandatory?: boolean;
  shippingMandatory?: boolean;
  meeting?: IMeeting;
  images?: string[];
  seatMandatory?: boolean;
  tableNumberMandatory?: boolean;
  updated?: boolean;
  allowsStudentDiscount?: boolean;
  needsVenueReapproval?: boolean;
  venueRevenue?: number;
  posSystemIdOrder?: string;
  isAnonymousTable?: boolean;
  isPremiumTable?: boolean;
  canChargeInRoom?: boolean;
  potentialUsers?: string[];
  venueTz?: string;
  getNotifications?: boolean;
  location?: {
      latitude?: number;
      logitude?: number;
      address?: string;
      name?: string;
      googlePlaceId?: string;
      googleURL?: string;
  }
};

export interface IWaitlist {
  email: string;
  joinedAt: string;
};

export interface IPaymentConfig {
  transactionFeeIncluded?: boolean;
  serviceFee?: number;
  transactionFeeRelative?: number;
  transactionFeeFixed?: number;
  organizerFeeIncluded?: boolean;
  organizerComission?: number;
  venueSurcharge?: number;
  startDay?: number;
  endDay?: number;
  startTime?: string;
  endTime?: string;
  minParticipant?: number;
  maxParticipant?: number;
  timeZone?: string;
}

export interface IMeeting {
  id: number;
  startUrl: string;
  joinUrl: string;
  hostId: string;
  hostEmail: string;
  topic: string;
  startTime: string;
  duration: number;
  password: string;
}

export interface IMenuPrice {
  value: number;
  taxIncluded: boolean;
  tipsIncluded: boolean;
  taxTotal?: number;
  tipsTotal?: number;
  total?: number;
  discountTotal?: number;
  discounts?: IDiscount[];
  surcharge?: number;
  tableServiceFee?: number;
  tableServiceFeeCharged?: boolean;
};

export interface IDiscount {
  id?: string;
  amount: number;
  type: string;
  name: string;
  userId?: string;
  usedAmount?: number;
  orderSpecific?: boolean;
  userSpecific?: boolean;
  voucherRef?: string;
  platformOwned?: boolean;
}

export interface ITips {
  amount?: number;
  type?: TipsType;
  strategy: TipsStrategy;
}

export enum TipsType {
  percentage = "percentage",
  amount = "amount",
}

export enum TipsStrategy {
  automatic = "automatic",
  regular = "regular",
  ignore = "ignore",
}

enum OrderStatuses {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  COMPLETED = "completed",
  PAID = "paid",
};

enum DiscountType {
  FIXED = "fixed",
  PERCENT = "percent",
}

enum PlatformDiscounts {
  CUCULI_CREDIT = "Cuculi Credit",
  STUDENT_DISCOUNT = "Student Discount",
}

const serviceFeeTableTypesEnum = ["public"];


@Injectable({
  providedIn: 'root'
})
export class PriceService {

  taxValue = 8.875;
  tableServiceFee = 200;
  automaticTip = 0.18;

  constructor(
    protected apiService: ApiService,
    private notificationService: NotificationService,
  ) { }

  public async calculatePrice(
    user: UserModel,
    items: IBasketItem[],
    event: any,
    tips: ITips,
    taxIncluded?: boolean,
    useCredit?: boolean,
    tableServiceFeeCharged?: boolean
  ): Promise<IMenuPrice> {
    const price: IMenuPrice = {
      value: 0,
      taxIncluded: false,
      taxTotal: 0,
      tipsIncluded: true,
      tipsTotal: 0,
      total: 0,
      discounts: [],
      surcharge: 0,
    };

    // get user price from all orders
    for (let i = 0; i < items.length; i++) {
      const order = items[i];
      price.value += order.price;
      price.total += order.price;

      // if (
      //   !!order.price &&
      //   !!order.price.discounts &&
      //   !!order.price.discounts.length
      // ) {
      //   order.price.discounts.forEach((x) => {
      //     this.applyDiscountAmount(price, x, user, order);
      //   });
      //   }
    }

    const discountAdded = price.value - price.total;

    // set surcharge
    price.surcharge = !!event.paymentConfig.venueSurcharge
      ? price.value * event.paymentConfig.venueSurcharge
      : 0;
    if (price.surcharge > 0) {
      price.total = price.value + price.surcharge - discountAdded;
    }
    // apply tax
    if (taxIncluded) {
      const taxRate = (this.taxValue / 100);
      // let premiumFee = 0;
      // if (event && event.isPremiumTable && user && (!user.premiumSubscription || user.premiumSubscription?.status !== 'active')) {
      //   premiumFee = config.get<number>("preAuthorization.premiumTable");
      // }
      price.taxIncluded = true;
      price.taxTotal = (price.value + price.surcharge) * taxRate;
      price.total =
        price.value + price.surcharge - discountAdded + price.taxTotal;
    }

    // apply credit
    // if (useCredit) {
    //   const creditDiscount: IDiscount = {
    //     name: PlatformDiscounts.CUCULI_CREDIT,
    //     type: DiscountType.FIXED,
    //     amount: user.credit ? user.credit / 100 : 0,
    //     platformOwned: true,
    //   };
    //   let discountAmount = 0;
    //   if (creditDiscount.amount > price.value) {
    //     creditDiscount.usedAmount = price.value;
    //     discountAmount = price.value;
    //   } else {
    //     discountAmount = creditDiscount.amount;
    //     creditDiscount.usedAmount = creditDiscount.amount;
    //   }
    //   const priceWithDiscount = price.total - discountAmount;
    //   price.total = priceWithDiscount <= 0 ? 0 : priceWithDiscount;
    //   price.discountTotal = price.discountTotal || 0;
    //   price.discountTotal += discountAmount;
    //   price.discounts.push(creditDiscount);
    // }

    // apply tableServiceFee
    const serviceFeeTableTypes: string[] = serviceFeeTableTypesEnum;
    if (serviceFeeTableTypes.includes(event.type)) {
      let tableServiceFee: number = this.tableServiceFee;
      // if (user && user.premiumSubscription && user.premiumSubscription.status === 'active') {
      //   tableServiceFee = 0;
      // }
      if (!tableServiceFeeCharged) {
        price.total = price.total + tableServiceFee / 100;
      }
      price.tableServiceFee = tableServiceFee / 100;
      price.tableServiceFeeCharged = tableServiceFeeCharged;
    }

    // apply tips startey
    if (tips.strategy === TipsStrategy.ignore) {
      return price;
    }
    if (tips.strategy === TipsStrategy.automatic) {
      return await this.applyAutomaticTipsStrategy(user, event, price, tips);
    }
    if (tips.strategy === TipsStrategy.regular) {
      return await this.applyRegularTipsStrategy(user, event, price, tips);
    }
  }

  public async getTipRule(event: any) {
    const eventId = event.eventId;

    return this.apiService.get(`/venues/${eventId}/tips-rule?guests=${1}`, []);
  }
  
  private applyDiscountAmount(
    price: IMenuPrice,
    discount: IDiscount,
    user: UserModel,
    order: IBasketItem
  ) {
    const existingDiscount = price.discounts.find(
      (x) => x.name === discount.name && x.type === discount.type
    );
    if (existingDiscount) {
      if (existingDiscount.type === DiscountType.FIXED) {
        existingDiscount.amount += discount.amount;
      }
    } else {
      price.discounts.push(discount);
    }
    let discountAmount = 0;
    if (
      discount.name === PlatformDiscounts.STUDENT_DISCOUNT 
    ) {
      return;
    }
    if (discount.type === DiscountType.FIXED) {
      discountAmount = discount.amount;
    } else {
      discountAmount = order.price * (discount.amount / 100);
    }
    const priceWithDiscount = price.total - discountAmount;
    price.total = priceWithDiscount < 0 ? 0 : priceWithDiscount;
    price.discountTotal = price.discountTotal || 0;
    price.discountTotal += discountAmount;
  }

  private async applyAutomaticTipsStrategy(
    user: UserModel,
    event: IEvent,
    price: IMenuPrice,
    tips: ITips
  ): Promise<IMenuPrice> {
    const tipMultiplier = this.automaticTip;
    let percentage = tipMultiplier * 100;

    const tipRule = await this.getTipRule(event);
    if (
      tipRule &&
      tipRule.required &&
      tipRule.percentages &&
      tipRule.percentages &&
      tipRule.percentages[0] > percentage
    ) {
      percentage = tipRule.percentages[0];
    }

    price.tipsTotal = price.value * (percentage / 100);
    price.tipsIncluded = true;
    price.total += price.tipsTotal;

    return price;
  }

  private async applyRegularTipsStrategy(
    user: UserModel,
    event: IEvent,
    price: IMenuPrice,
    tips: ITips
  ): Promise<IMenuPrice> {
    let percentage = 0;
    if (tips.amount) {
      if (tips.type === TipsType.percentage) {
        percentage = tips.amount;
      }
      if (tips.type === TipsType.amount) {
        percentage = (tips.amount / price.value) * 100;
      }
    }

    const tipRule = await this.getTipRule(event);

    if (
      tipRule &&
      tipRule.required &&
      tipRule.percentages &&
      tipRule.percentages &&
      tipRule.percentages[0] > percentage &&
      price.value > 0
    ) {
      this.notificationService.error("Smaller Tip from Tip Rule Minimum",
        `Minimum tip for this reservation is ${tipRule.percentages[0]}%`);
      return;
    }

    price.tipsTotal = price.value * (percentage / 100);
    price.tipsIncluded = true;
    price.total += price.tipsTotal;

    return price;
  }

}
