import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, stagger, query, keyframes } from '@angular/animations';
import { OrderService } from 'src/app/order/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/common/notification.service';
import { BasketService } from '../basket/basket.service';
import { MobileService } from '../mobile.service';
import { AuthService } from 'src/app/common/auth.service';
import { UserModel } from 'src/app/models/user.model';
import { VenueService } from 'src/app/venue/venue.service';
import { VenueModel } from 'src/app/models/venue.model';

interface OrderItem {
  name: string;
  quantity: number;
  price: number;
  total: number;
}

@Component({
  selector: 'app-checkout-redirect',
  templateUrl: './checkout-redirect.component.html',
  styleUrls: ['./checkout-redirect.component.scss'],
  animations: [
    trigger('itemsAnimation', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0, transform: 'translateX(-50px)' }),
          stagger(200, [
            animate('0.8s cubic-bezier(0.35, 0, 0.25, 1)', keyframes([
              style({ opacity: 0, transform: 'translateX(-50px)', offset: 0 }),
              style({ opacity: 0.5, transform: 'translateX(10px)', offset: 0.7 }),
              style({ opacity: 1, transform: 'translateX(0)', offset: 1 })
            ]))
          ])
        ], { optional: true })
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-40px)' }),
        animate('1s cubic-bezier(0.35, 0, 0.25, 1)', 
          style({ opacity: 1, transform: 'translateY(0)' })
        )
      ])
    ]),
    trigger('itemDetails', [
      transition(':enter', [
        animate('0.4s ease', keyframes([
          style({ transform: 'scale(1)', offset: 0 }),
          style({ transform: 'scale(1.1)', offset: 0.5 }),
          style({ transform: 'scale(1)', offset: 1 })
        ]))
      ])
    ]),
    trigger('checkmarkAnimation', [
      transition(':enter', [
        style({ strokeDashoffset: 100, strokeDasharray: 100 }),
        animate('1.5s cubic-bezier(0.35, 0, 0.25, 1)', 
          style({ strokeDashoffset: 0 })
        )
      ])
    ])
  ]
})
export class CheckoutRedirectComponent implements OnInit {

  orderItems: OrderItem[] = [
    { name: 'Producto 1', quantity: 2, price: 19.99, total: 39.98 },
    { name: 'Producto 2', quantity: 1, price: 29.99, total: 29.99 },
    // Aquí puedes obtener los items reales de tu servicio
  ];

  orderTotal: number = 0;
  orderNumber: string = 'ORD-' + Math.random().toString(36).substr(2, 9).toUpperCase();
  showItems: boolean = false;
  loading: boolean = false;

  checkoutItems: [];
  session = null;
  eventId: string = null;
  venueId: string = null;
  table: string = null;
  tax: number = 0;
  tip: number = 0;
  surcharge: number = 0;
  serviceFee: number = 0;
  loggedInUser: UserModel;
  tableNumber: string = this.mobileService.tableNumber;
  tableNumberMandatory: boolean = false;
  venue: VenueModel;

  constructor(
    private orderService: OrderService,
    private activeRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private basketService: BasketService,
    private mobileService: MobileService,
    private authService: AuthService,
    private router: Router,
    private venueService: VenueService,
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.checkoutItems = JSON.parse(localStorage.getItem('checkout-items'));
    const sessionId = this.activeRoute.snapshot.paramMap.get('sessionId');
    this.session = await this.orderService.getCheckoutInfo(sessionId);
    this.eventId = this.activeRoute.snapshot.paramMap.get('id');
    this.venueId = this.session.metadata.venueId;
    this.table = this.session.metadata.table;
    this.orderTotal = (this.session.amount_total / 100) || 0;
    this.tax = Number(this.session.metadata.taxs || 0);
    this.tip = Number(this.session.metadata.tips || 0);
    this.surcharge = Number(this.session.metadata.surcharge || 0);
    this.serviceFee = Number(this.session.metadata.serviceFee || 0);
    this.loggedInUser = this.authService.getCurrentUser();
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.tableNumberMandatory = await this.mobileService.getEventTableNumberMandatory(this.eventId);
    if (!this.loggedInUser) {
      if (userData) {
        this.loggedInUser = this.authService.setCurrentUser(userData);
        this.loggedInUser = await this.authService.refreshUserData();
      }else{
        return;
      }
    }
    // create order
    if (!this.session) {
      this.notificationService.error('There are some creating the order');
      window.scroll(0, 150);
      return;
    }
    try {
      const venueSlug = this.session.metadata.venueSlug;
      await this.loadVenue(venueSlug);
      if (this.eventId) {
        if (this.tableNumberMandatory && !this.mobileService.tableNumber) {
          this.changeTableNumber(this.session.metadata.tableNumber);
        }
        const stripePi = this.session.payment_intent.id;
        const ordersIds = this.session.metadata.ordersId;
        if (this.mobileService.tableNumber) {
          const res = await this.orderService.createOrder(this.loggedInUser.id, this.venueId, ordersIds, this.table, this.eventId , null, this.mobileService.tableNumber, this.checkoutItems, null, this.tip, null, stripePi, this.session.id);
        } else {
          const res = await this.orderService.createOrder(this.loggedInUser.id, this.venueId, ordersIds, this.table, this.eventId , null, null, this.checkoutItems, null, this.tip, null, stripePi, this.session.id);
        }
        console.log('app order');
      }else{
        console.log('mobile order');
      }

      this.basketService.clearBasket();
    } catch (err) {
      console.log(err, 'mobile ordering error');
      this.loading = false;
    }
    this.loading = false;
    // end create order
    setTimeout(() => {
      this.showItems = true;
    }, 500);
  }

  changeTableNumber(tableNumber: string){
    this.mobileService.tableNumber = tableNumber;
  }

  goBack() {
    this.router.navigateByUrl(`/mobile-ordering/home/${this.venue.slug}/${this.table}/${this.eventId}`);
  }

  private async loadVenue(slug) {
    this.venue = await this.venueService.getBySlug(slug);
  }

}
