import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { GenericFormType, GenericFormComponent } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { UserModel } from '../models/user.model';
import { VenueModel } from '../models/venue.model';
import { LocationModel } from '../models/location.model';

import { LocationInputComponent } from '../location/location.input.component';
import { AuthService } from '../common/auth.service';
import { BsModalService } from 'ngx-bootstrap';
import { VenueService } from './venue.service';
import { AutomapperService } from '../common/automapper.service';
import { NotificationPopupComponent } from '../common/notification.popup.component';

@Component({
  selector: 'app-venue-register',
  templateUrl: './venue.register.component.html',
  styleUrls: ['./venue.register.component.scss']
})
export class VenueRegisterComponent implements OnInit {

  @ViewChild('registerVenueForm', { static: false }) registerVenueForm: GenericFormComponent;
  @ViewChild('locationInput', { static: false }) locationInput: LocationInputComponent;

  registerVenueFormRules: GenericFormType[];
  createInProgress: boolean;
  currentLocation: any;
  location: any;
  latitude: number;
  longitude: number;
  venues = [];
  showMap: boolean;
  zoom: number;

  private geoCoder;
  private places;

  constructor(
    private router: Router,
    private authService: AuthService,
    private venueService: VenueService,
    private modalService: BsModalService,
    private automapper: AutomapperService
  ) {
    this.showMap = false;
    this.geoCoder = new google.maps.Geocoder();
    this.places = new google.maps.places.AutocompleteService();
    this.zoom = 12;
  }

  ngOnInit() {
    this.latitude = 40.730610;
    this.longitude = -73.935242;
    this.createInProgress = false;

    if (this.authService.isLoggedIn().value) {
      this.authService.logout();
    }
    this.registerVenueFormRules = [
      // tslint:disable: max-line-length
      GenericFormBuilder.group('venue', '', [], () => false, 'row', 'col-md-12 inline-block claim-restaurant align-self-center'),
      GenericFormBuilder.text('name', 'Restaurant Name', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', null, 'Restaurant Name'),
      GenericFormBuilder.text('website', 'Website', [Validators.required], false, null, 'col-md-6 inline-block claim-restaurant', null, 'www.example.com'),
      GenericFormBuilder.phone('phone', 'Phone Number', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', null, true),
      GenericFormBuilder.autocomplete('address', 'Address', [Validators.required], this.venues, (text) => this.locationSearch(text), 'col-md-6 inline-block claim-restaurant', false, () => true, false, async (placeId) =>  await this.getAddress(placeId), 'Enter restaurant name to find address'),
      GenericFormBuilder.text('companyName', 'Legal Business Name (Associated with Tax ID)', [], null, null, 'col-md-6 inline-block claim-restaurant', null, 'Skip if same as Restaurant Name'),
      GenericFormBuilder.text('taxId', 'Tax ID (numbers only)', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', null, '123456789'),
    ];
  }


  async locationSearch(text: string) {
    if (!text || !text.length) {
      return this.venues;
    }
    await this.places.getPlacePredictions({input: text, types: ['establishment'], componentRestrictions: {country: 'us'}}, (venues) => {
      if (!venues) {
        return;
      }
      this.venues = venues.map((item, index) => ({label: item.description, value: item.place_id}));
    });
    return this.venues;
  }

  toggleNotificationPopup(venue: VenueModel) {
    const initialState = {
      title: 'Success!',
      message: 'Restaurant',
      messageBold: ' Page Created!',
      imgSrc: '../../assets/images/Success.png',
      buttonCallback: () => this.router.navigateByUrl(`/venue-register/${venue.slug}/claim-restaurant`),
    };
    const bsModalRef = this.modalService.show(NotificationPopupComponent, { initialState });
    bsModalRef.setClass('notification-popup-modal');
  }


  public async doRegister() {
    if (this.registerVenueForm.isValid()) {
      this.createInProgress = true;
      const selected = this.registerVenueForm.myForm.controls.address.value;
      const loc = this.currentLocation || await this.getAddress(selected.value);
      try {
        let venue: VenueModel = this.registerVenueForm.toModel<VenueModel>('VenueRegisterForm', 'VenueModel');
        if (!venue.companyName) {
          venue.companyName = venue.name;
        }
        venue.location = loc;
        venue.public = true;
        venue = await this.venueService.create(venue as any);
        this.toggleNotificationPopup(venue);
        // this.notificationService.success('You have succesfully created a venue!');
      } catch (err) {
        console.log(err, 'ERROR');
        this.createInProgress = false;
      }
    }
  }

  private async getAddress(placeId: string): Promise<any> {
    return this.geoCoder.geocode({ placeId }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const newLocation = this.automapper.map('GoogleResult', 'LocationModel', results[0]);
          this.currentLocation = newLocation;
          return newLocation;
        } else {
          this.currentLocation = new LocationModel();
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  navigateBack() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(`/for-restaurants`);
  }
}
