import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/common/auth.service";
import { NotificationService } from "src/app/common/notification.service";
import { Countries } from "src/app/data/countries.data";
import { GenericFormBuilder } from "src/app/generic-form/generic.form.builder";
import { GenericFormComponent, GenericFormType } from "src/app/generic-form/generic.form.component";
import { GiftCard } from "src/app/models/gift-card.model";
import { UserModel } from "src/app/models/user.model";
import { StripeCard } from "stripe-angular";
import { VoucherService } from "../voucher.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-soul-campaign-details",
  templateUrl: "./soul-campaign-details.component.html",
  styleUrls: ["./soul-campaign-details.component.scss"],
})
export class SoulCampaignDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('mayInfoForm', { static: false }) mayInfoForm: GenericFormComponent;
  @ViewChild('addressForm', { static: false }) addressForm: GenericFormComponent;
  @ViewChild('stripeCard', { static: false }) stripeCard: StripeCard;

  myInfoRules: GenericFormType[] = [
    GenericFormBuilder.text('firstName', 'First Name', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('lastName', 'Last Name', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('email', 'Email', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.phone('phone', 'Phone', [Validators.required], false, null, 'col-md-6 inline-block'),
  ];

  addressFormRules: GenericFormType[] = [
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown('state', 'Country', [Validators.required], [{ value: null, label: 'Please select country' }, ...Countries.map((x) => ({ value: x.code, label: x.name }))], false, null),
    GenericFormBuilder.text('postalCode', 'Zip Code', [Validators.required], false, null, ),
  ];

  options: any = {
    hidePostalCode: true,
    disableLink:true
  };
  hideInfo: boolean = false;
  isMobile: boolean;
  items: string[] = [];
  filteredItems: string[] = [...this.items];
  filterText: string = '';
  showPayInfo:boolean = false;
  selectedCard: any;
  selectedVenue: string;
  paymentOptions: any[];
  saveCard:boolean = false;
  payInProgress: boolean;
  loggedInUser: UserModel;
  giftCards: GiftCard[] = [
    {
      price: 25,
      value: 27.5,
      link: "25-gift-card",
      title: "$25.00 Gift Card",
      img: "/assets/images/Help_Banner_25.png",
      next: "50-gift-card",
      photos: [
        "/assets/images/Help_Banner_25.png",
        "/assets/images/banner2.jpg",
        "/assets/images/banner.jpg",
      ],
    },
    {
      price: 50,
      value: 55,
      link: "50-gift-card",
      title: "$50.00 Gift Card",
      img: "/assets/images/Help_Banner_50.png",
      prev: "25-gift-card",
      next: "100-gift-card",
      photos: ["/assets/images/Help_Banner_50.png"],
    },
    {
      price: 100,
      value: 110,
      link: "100-gift-card",
      title: "$100.00 Gift Card",
      img: "/assets/images/Help_Banner_100.png",
      prev: "50-gift-card",
      next: "200-gift-card",
      photos: ["/assets/images/Help_Banner_100.png"],
    },
    {
      price: 200,
      value: 200,
      link: "200-gift-card",
      title: "$200.00 Gift Card",
      img: "/assets/images/Help_Banner_200.png",
      prev: "100-gift-card",
      photos: ["/assets/images/Help_Banner_200.png"],
    },
  ];
  actualCard: GiftCard;
  newCard: boolean = true;

  currentIndex: number = 0;
  visibleSlides: number = 5;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private voucherService: VoucherService,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  async ngOnInit() {
    const getScreenWidth = window.innerWidth;
    this.isMobile = getScreenWidth < 990;
    this.route.paramMap.subscribe((item) => {
      const giftPath = item.get("slug");
      this.actualCard = this.giftCards.find((card) => card.link === giftPath);
    });
    const venues = await this.voucherService.getAllActivesVenuesNames();
    this.filteredItems = venues;
    this.items = venues;
  }
  async ngAfterViewInit() {
    this.loggedInUser = this.authService.getCurrentUser();
    if (this.loggedInUser) {
      this.loggedInUser = await this.authService.refreshUserData();
      // tslint:disable-next-line:max-line-length
      this.paymentOptions = this.loggedInUser.payment && this.loggedInUser.payment.sources ? this.loggedInUser.payment.sources.filter(x => !x.hidden) : [];
      this.selectedCard = this.paymentOptions && this.paymentOptions.length ? this.paymentOptions[0] : null;
    }
    this.showSlide(this.currentIndex);
  }

  filterItems() {
    this.selectedVenue = "",
    this.filteredItems = this.items.filter(item =>
      item.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }

  selectVenue(item: string) {
    this.selectedVenue = item;
    this.filterText = item;
    this.filteredItems = [item];
  }

  selectCard(card: any) {
    if (!card) {
      this.newCard = true;
    } else {
      this.newCard = false;
    }
    this.selectedCard = card;
  }

  handleShowPayInfo() {
    if (this.isMobile) {
      this.hideInfo=!this.hideInfo;
    }
    this.showPayInfo=!this.showPayInfo;
  }

  async handlePayment() {
    if (this.payInProgress) {
      return;
    }
    if (!this.selectedVenue) {
      this.notificationService.error('Please select a venue');
      return;
    }
    if (!this.mayInfoForm.isValid()) {
      this.notificationService.error('There are some information missing');
      window.scroll(0, 150);
      return;
    }
    const infoData = this.mayInfoForm.toRaw();
    try {
      this.payInProgress = true;
      this.spinner.show();

      let source = null;
      if (!this.selectedCard) {
        const address = this.addressForm.toRaw();
        source = await this.stripeCard.createToken({
          name: `${infoData.firstName} ${infoData.lastName}`,
          address_state: `${address.state}`,
          address_zip: `${address.postalCode}`
        });
      } else {
        source = this.selectedCard;
      }

      if (!source) {
        this.notificationService.error('Please check your credit card data');
        window.scroll(0, 150);
        this.payInProgress = false;
        return;
      }

      const payload = {
        loggedUser: null,
        amount: this.actualCard.price,
        venueName: this.selectedVenue,
        validUntil: null,
        quantity: 1,
        customer: {
          firstName: infoData.firstName,
          lastName: infoData.lastName,
          phone: infoData.phone.number,
          email: infoData.email,
          cardToken: source.id,
          cardId: source.card.id,
        },
      };
      if (this.loggedInUser) {
        payload.loggedUser = this.loggedInUser.id;
      }
      const response = await this.voucherService.buyVoucher(payload);

      this.notificationService.success('thank you for purchasing this Gift Card');
      this.payInProgress = false;
      this.spinner.hide();

      setTimeout(() => {
        this.router.navigateByUrl(`/gift-card`);
      }, 2000);

    } catch (err) {
      console.log(err, 'error');
      this.notificationService.error('Failed');
      this.payInProgress = false;
    }
  }

  showSlide(index: number): void {
    const carouselInner = document.querySelector(
      ".card-details-carousel-inner"
    ) as HTMLElement;
    const totalSlides = this.giftCards.length;

    this.currentIndex = (index + totalSlides) % totalSlides;

    const offset = -this.currentIndex * (150 / this.visibleSlides);
    carouselInner.style.transform = `translateX(${offset}%)`;
  }

  nextSlide(): void {
    const carouselInner = document.querySelector(
      ".card-details-carousel-inner"
    ) as HTMLElement;
    carouselInner.style.transition = "transform 0.5s ease";
    this.showSlide(this.currentIndex + 1);
    setTimeout(() => {
      const firstSlide = carouselInner.querySelector(
        ".card-details-carousel-item"
      ) as HTMLElement;
      carouselInner.appendChild(firstSlide);
      carouselInner.style.transition = "none";
      this.currentIndex =
        (this.currentIndex - 1 + this.giftCards.length) % this.giftCards.length;
      this.showSlide(this.currentIndex);
    }, 500);
  }

  prevSlide(): void {
    const carouselInner = document.querySelector(
      ".card-details-carousel-inner"
    ) as HTMLElement;
    carouselInner.style.transition = "none";
    const slides = carouselInner.querySelectorAll(".card-details-carousel-item");
    const lastSlide = slides[slides.length - 1];
    carouselInner.insertBefore(lastSlide, slides[0]);
    this.currentIndex = (this.currentIndex + 1) % slides.length;
    this.showSlide(this.currentIndex);
    setTimeout(() => {
      carouselInner.style.transition = "transform 0.5s ease";
      this.showSlide(this.currentIndex - 1);
    }, 0);
  }
  changePhoto(photoPath: string) {
    document.getElementById("photo_container").setAttribute("src", photoPath);
    this.getActualSlide(photoPath);
  }
  getActualSlide(path: string) {
    const items = document.querySelectorAll(".card-details-carousel-item img");
    items.forEach((item: HTMLImageElement) => {
      item.classList.remove("active");
      if (item.name === path) {
        item.classList.add("active");
      }
    });
  }
}
