import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutomapperService } from '../common/automapper.service';
import { EnumService } from '../common/enum.service';

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic.form.component.html'
})
export class GenericFormComponent implements OnInit, OnDestroy {

  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  myForm: FormGroup;
  isFormValidated: boolean;
  // tslint:disable-next-line:no-input-rename
  @Input('fields') fields: Array<GenericFormType> = [];
  private changeSubscriptions: Array<Subscription> = [];

  constructor(private fb: FormBuilder, private automapper: AutomapperService, private enumService: EnumService) { }

  async ngOnInit() {
    this.myForm = this.fb.group({});
    await this.initFormFields();
    this.initFormConstraints();
    this.isFormValidated = false;
    this.toggleTooltip();
  }

  ngOnDestroy() { this.changeSubscriptions.map(cs => cs.unsubscribe()); }

  isValid(): boolean {
    this.isFormValidated = true;
    this.myForm.markAsDirty({ onlySelf: false });
    return this.myForm.valid;
  }

  toModel<T>(sourceName: string, nameOfModel: string, model?: any): T {
    if (!model) {
      model = {};
    }
    const rawData = this.fillEntity(this.myForm, this.fields, model);
    return this.automapper.map(sourceName, nameOfModel, rawData);
  }

  fromModel<T>(nameOfModel: string, destinationName: string, data: T): void {
    const formData = this.automapper.map(nameOfModel, destinationName, data);
    this.fields.forEach((f) => {
      switch (f.type) {
        case 'control':
          this.setControlFormData(f as GenericFormControl, formData, this.myForm);
          break;
        case 'group':
          this.setGroupFormData(f as GenericFormGroup, formData[f.name], this.myForm.get(f.name) as FormGroup);
          break;
        case 'array':
          this.setArrayFormData(f as GenericFormArray, formData, this.myForm.get(f.name) as FormGroup);
          break;
      }
    });
  }

  formChanged() {
    const rawValues = this.toRaw();
    this.dataChange.emit(rawValues);
  }

  toObject(): any {
    return this.fillEntity(this.myForm, this.fields, {});
  }

  toRaw(): any {
    const raw = {};
    Object.keys(this.myForm.controls).forEach(key => {
      const value = this.myForm.get(key).value;
      raw[key] = value;
    });
    return raw;
  }

  reset() {
    this.myForm.reset();
    this.isFormValidated = false;
    return;
  }

  checkVisibility(field: GenericFormType): boolean {
    return field.visibleIf(this.toRaw());
  }

  addNewGroup(array: GenericFormArray, formGroup?: FormGroup, initializeNewArray?: boolean) {
    const fromFormGroup = formGroup ? formGroup : this.myForm;
    const arrayForm: FormArray = fromFormGroup.get(array.name) as FormArray;
    const newGroup: FormGroup = this.fb.group({});
    const groupToClone = this.ObjectAssign(array.groups[0]);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array.groups[0].controls.length; i++) {
      const ctrl = { ...array.groups[0].controls[i] };
      if (ctrl.type === 'array') {
        const isNewArray = initializeNewArray ? initializeNewArray : null;
        newGroup.addControl(ctrl.name, this.initArray(ctrl, isNewArray));
        if (isNewArray) {
          groupToClone.controls[i]['groups'] = [{ ...array.groups[0].controls[i]['groups'][0] }];
        }
        continue;
      }
      newGroup.addControl(ctrl.name, this.initControl(ctrl));
    }
    arrayForm.push(newGroup);
    array.groups.push(groupToClone);
  }

  removeGroup(array: GenericFormArray, index: number, formGroup?: FormGroup) {
    const fromFormGroup = formGroup ? formGroup : this.myForm;
    const arrayForm: FormArray = fromFormGroup.get(array.name) as FormArray;
    arrayForm.removeAt(index);
    array.groups.splice(index, 1);
  }

  toggleTooltip() {
    const tooltipButtons = document.getElementsByClassName("button-tooltip");
    for (let i = 0; i < tooltipButtons.length; i++) {
      tooltipButtons[i].addEventListener('click', function () {
        const tooltip = this.querySelector(".tooltip");
        tooltip.classList.toggle("d-none");
      }, false)
    }
  }

  private setControlFormData(control: GenericFormControl, formData: any, form: FormGroup) {
    const cData = formData[control.name];
    try {
      form.get(control.name).setValue(cData);
    } catch { }
  }

  private setGroupFormData(group: GenericFormGroup, gData: any, form: FormGroup) {
    group.controls.forEach((c) => {
      if (c.inputType === "array") {
        this.setArrayFormData(c, gData, form.get(c.name) as FormGroup, form);
      } else {
        this.setControlFormData(c, gData, form);
      }
    });
  }

  private setArrayFormData(array: GenericFormArray, formData: any, form: FormGroup, formGroup?: FormGroup) {
    const aData: any[] = formData[array.name];
    if (array.groups.length > aData.length) {
      for (let y = 0; y <= array.groups.length - aData.length; y++) {
        if (array.groups.length !== 1) {
          this.removeGroup(array, y, formGroup || null);
        }
      }
    }

    if (array.groups.length < aData.length) {
      const groupsToAdd = aData.length - array.groups.length;
      for (let i = 0; i < groupsToAdd; i++) {
        this.addNewGroup(array, formGroup || null);
      }
    }
    const fgArray = form.controls;
    array.groups.forEach((g, i) => {
      if (aData[i]) {
        this.setGroupFormData(g, aData[i], fgArray[i] as FormGroup);
      }
    });
  }

  private fillEntity(form: FormGroup, rules: GenericFormType[], entity: any): any {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].type === 'control') {
        entity = this.fillFromControl(form, rules[i], entity);
        continue;
      }
      if (rules[i].type === 'group') {
        entity[rules[i].name] = this.fillFromGroup(form, rules[i], {});
        continue;
      }
      if (rules[i].type === 'array') {
        entity[rules[i].name] = this.fillFromArray(form, rules[i] as GenericFormArray, []);
        continue;
      }
    }
    return entity;
  }

  private fillFromGroup(form: FormGroup, rule: GenericFormGroup, entity: any): any {
    const formSubGroup = form.get(rule.name) as FormGroup;
    rule.controls.forEach(r => {
      if (r.inputType === "array" || r.type === "array") {
        entity[r.name] = this.fillFromArray(formSubGroup || form, r, []);
      } else {
        entity = this.fillFromControl(formSubGroup || form, r, entity);
      }
    });
    return entity;
  }

  private fillFromArray(form: FormGroup, rule: GenericFormArray, entity: any[]): any[] {
    const formSubArray = form.get(rule.name) as FormGroup;
    rule.groups.forEach((g, i) => {
      const formSubGroup = formSubArray.controls[i] as FormGroup;
      entity.push(this.fillFromGroup(formSubGroup, g, {}));
    });
    return entity;
  }

  private fillFromControl(form: FormGroup, rule: GenericFormType, entity: any): any {
    if ((rule as any).inputType === 'dropdown') {
      const selectedLookup = (rule as any).lookups.find((l) => {
        return l.value === form.get(rule.name).value;
      });
      entity[rule.name] = selectedLookup ? selectedLookup.value : entity[rule.name];
    } else {
      entity[rule.name] = form.get(rule.name).value;
    }
    return entity;
  }

  private initFormFields() {
    for (const field of this.fields) {
      this.addFormField(field);
    }
  }

  private async addFormField(field: GenericFormType) {
    if (GenericHelper.isControl(field)) {
      const f: any = field;
      if (f.inputType === 'json') {
        f.value = f.value ? f.value : {};
        this.myForm.addControl(f.name, this.initControl(f as GenericFormControl));
      } else if (f.enumSource) {
        this.myForm.addControl(f.name, this.initControl(f as GenericFormControl));
        if (Array.isArray(f.enumSource)) {
          const enums = await this.enumService.getByTypes(f.enumSource);
          f.lookups = this.automapper.map('Enum', 'Lookup', enums);
        } else {
          const enums = await this.enumService.getByType(f.enumSource);
          f.lookups = this.automapper.map('Enum', 'Lookup', enums);
        }
        if(f.inputType === "multiselect") {
          f.lookups.unshift({
            value: 'other',
            label: 'Other'
          })
        }else{
          f.lookups.unshift({
            value: null,
            label: ''
          });
        }
      } else {
        this.myForm.addControl(field.name, this.initControl(field as GenericFormControl));
      }
      if (['dropdown', 'select'].indexOf(f.inputType) !== -1) {
        const selected: Lookup = (f as GenericFormControl).lookups.find((x) => x.selected);
        if (selected) {
          this.myForm.get(f.name).setValue(selected.value);
        }
      }
      if (['multiselect'].indexOf(f.inputType) !== -1) {
        const selected: Lookup = (f as GenericFormControl).lookups.find((x) => x.selected);
        if (selected) {
          let selectedValue = this.myForm.get(f.name).value;
          if (!selectedValue) {
            selectedValue = [];
          }
          selectedValue.push(selected.value);
          this.myForm.get(f.name).setValue(selectedValue);
        }
      }
    }

    if (GenericHelper.isGroup(field)) {
      this.myForm.addControl(field.name, this.initGroup(field as GenericFormGroup));
    }

    if (GenericHelper.isArray(field)) {
      this.myForm.addControl(field.name, this.initArray(field as GenericFormArray));
    }
  }

  private initFormConstraints() {
    for (const field of this.fields) {
      if (GenericHelper.isControl(field)) {
        this.initChangeSubscription((field as GenericFormControl));
      }

      if (GenericHelper.isGroup(field)) {
        console.warn('todo - check constraints on group');
        for (const control of (field as GenericFormGroup).controls) {
          this.initChangeSubscription(control);
        }
      }

      if (GenericHelper.isArray(field)) {
        console.warn('todo - check constraints on array');
      }
    }
  }

  private initControl(control: GenericFormControl): FormControl {
    return this.fb.control(control.value, control.validators);
  }

  private initGroup(group: GenericFormGroup): FormGroup {
    const newFormGroup = this.fb.group({});
    for (const control of group.controls) {
      if (control.type === "array") {
        newFormGroup.addControl(control.name, this.initArray(control));
        continue;
      }
      newFormGroup.addControl(control.name, new FormControl(control.value ? control.value : '', control.validators));
    }
    return newFormGroup;
  }

  private initArray(array: GenericFormArray, isNewArray?: boolean): FormArray {
    const newFormArray = this.fb.array([]);
    const groupNames = [];
    for (const group of array.groups) {
      if (isNewArray && groupNames.indexOf(group.name) !== -1) {
        continue;
      }
      newFormArray.push(this.initGroup(group));
      groupNames.push(group.name);
    }
    return newFormArray;
  }

  private initChangeSubscription(control: GenericFormControl) {
    if (control.constraints && control.constraints.length > 0) {
      this.changeSubscriptions.push(
        this.myForm.get(control.name).valueChanges.subscribe((value) => {
          for (const constraint of control.constraints) {
            for (const executeValue of constraint.executeValues) {
              if (executeValue.srcValue === value) {
                this.myForm.get(constraint.targetFieldName).setValue(executeValue.targetValue);
              }
            }
          }

        })
      );
    }
  }

  private ObjectAssign(object: any): any {
    const newObject = Array.isArray(object) ? [] : {};
    for (const o in object) {
      if (object.hasOwnProperty(o)) {
        if (object[o] && typeof object[o] === 'object') {
          newObject[o] = this.ObjectAssign(object[o]);
        } else {
          newObject[o] = object[o];
        }
      }
    }
    return newObject;
  }

}

export class GenericHelper {
  static isControl(c: GenericFormType): boolean {
    return (c as GenericFormControl).inputType !== undefined && (c as GenericFormControl).inputType !== "array";
  }

  static isGroup(c: GenericFormType): boolean {
    return (c as GenericFormGroup).controls !== undefined;
  }

  static isArray(c: GenericFormType) {
    return (c as GenericFormArray).groups !== undefined;
  }
}

export type GenericFormType = GenericFormControl | GenericFormGroup | GenericFormArray;

export interface GenericFormBase {
  type: 'control' | 'group' | 'array';
  name: string;
  visibleIf?: (x: any) => boolean;
  customVisibleIfField?: boolean;
  gridClass?: string;
}

export interface GenericFormControl extends GenericFormBase {
  // tslint:disable-next-line:max-line-length
  inputType: 'text' | 'textarea' | 'number' | 'checkbox' | 'select' | 'dropdown' | 'autocomplete' | 'json' | 'password' | 'switch' | 'phone' | 'multiselect' | 'datepicker' | 'timepicker' | 'rich-text' | 'array';
  label: string;

  value?: any;
  placeHolder?: string;
  validators?: Array<ValidatorFn>;
  lookups?: Array<Lookup>;
  filteredLookups?: Array<Lookup>;
  constraints?: Array<Constraint>;
  readonly?: boolean;
  enumSource?: string | string[];
  step?: string;
  search?: (text: string) => Promise<Lookup[]>;
  displayButton?: boolean;
  onChange?: (text: string) => Promise<Lookup[]>;
  placeholder?: string;
  invalidText?: string;
  separateDialCode?: boolean;
  enablePlaceholder?: boolean;
}

export interface GenericFormDatepicker extends GenericFormControl {
  minDate?: Date;
  maxDate?: Date;
}

export interface GenericFormGroup extends GenericFormBase {
  groupTitle?: string;
  controls?: Array<GenericFormControl>;
  style?: 'row' | 'inline';
  description?: string;
  descriptionClass?: string;
  descriptionIcon?: boolean;
  descriptionIconClass?: string;
  descriptionTooltip?: string;
}

export interface GenericFormArray extends GenericFormControl {
  arrayTitle?: string;
  groups?: Array<GenericFormGroup>;
  extendable?: boolean;
}


export interface Lookup {
  value: string;
  label: string;
  selected?: boolean;
  index?: number;
  data?: any;
}

export interface Constraint {
  targetFieldName: string;
  executeValues: Array<ExecuteValue>;
}

export interface ExecuteValue {
  srcValue: string;
  targetValue: string;
}

class Namify<T> {
  private TName: string;
  constructor(x: new () => T) {
    this.TName = x.name;
  }
}
