import { Component, OnInit, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import {
  GenericFormComponent,
  GenericFormType,
} from "../generic-form/generic.form.component";
import { GenericFormBuilder } from "../generic-form/generic.form.builder";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-delete-account-request",
  templateUrl: "./delete-account-request.component.html",
})
export class DeleteAccountRequestComponent implements OnInit {
  @ViewChild("genericForm", { static: false }) genericForm: GenericFormComponent;

  titleLabel = "Request deletion of my data";

  fields: GenericFormType[] = [
    GenericFormBuilder.text(
      "email",
      "Email",
      [Validators.required, Validators.email],
      false,
      null,
      "col-md-6 inline-block claim-restaurant claim-restaurant"
    ),
    GenericFormBuilder.text(
      "name",
      "name",
      [Validators.required],
      false,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
  ];

  private host: string;
  public getScreenWidth: any;
  public getScreenHeight: any;
  public isMobile: boolean;

  constructor(private httpClient: HttpClient) {
    this.host = environment.api.host;
  }

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.getScreenWidth < 768;
  }

  createRegisterRequestData() {
    this.httpClient
      .post(this.host + "/users/request-delete-data", {
        email: this.genericForm.myForm.get("email").value,
        name: this.genericForm.myForm.get("name").value,
      })
      .subscribe({
        next: (response) => {
          if (response) {
            console.log("Response success");
          }
        },
        error: (error) => console.error(error),
      });
  }
}
