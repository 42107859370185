import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-get-the-app-screen",
  templateUrl: "./get-the-app-screen.component.html",
})
export class GetTheAppScreenComponent implements OnInit {
  iOS: boolean;

  constructor(private router: Router) {}

  async ngOnInit() {
    this.iOS = this.checkIfIOS();
    const urlRef = window.location.href.slice(-24);
    if (urlRef === "6684149774994f34f8dc65c7") {
      window.location.href = "http://cuculi.com/astoriaconnect";
    }
  }

  checkIfIOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  cancel() {
    this.router.navigateByUrl("");
  }
}
