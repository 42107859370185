import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { EventCreateConstraints, EventModel, EventEditConstraints } from '../models/event.model';
import { EventService } from './event.service';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event.edit.component.html'
})
export class EventEditComponent implements OnInit, AfterViewInit {

  @Input() event: EventModel;
  @Output() saved: EventEmitter<EventModel> = new EventEmitter();
  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;

  updateRules: GenericFormType[] = EventEditConstraints;
  createInProgress: boolean;
  currentUser: UserModel;

  constructor(
    private eventService: EventService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.createInProgress = false;
    this.currentUser = this.authService.getCurrentUser();
  }

  ngAfterViewInit() {
    this.genericForm.fromModel<EventModel>('EventModel', 'EventEditForm', this.event);
  }

  async update() {
    if (this.genericForm.isValid()) {
      this.createInProgress = true;
      try {
        this.event = this.genericForm.toModel<EventModel>('EventEditForm', 'EventModel', this.event);
        this.event = await this.eventService.updateEvent(this.event);
        this.saved.emit(this.event);
        this.createInProgress = false;
      } catch (err) {
        this.createInProgress = false;
      }
    }
  }
}
