import { MenuItemOptionModel, PriceModel } from './menu-item.model';
import { GenericFormType } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';

export class OrderModel {
    id: string;
    userId: string;
    venueId: string;
    eventId: string;
    orderStatus?: string;
    usedCredit?: boolean;
    usedDiscount?: boolean;
    items?: MenuItemOptionModel[];
    price?: PriceModel;
    userFullName?: string;
    charge: any;
    stripePi?: string;
}
