import { Component, OnInit, AfterViewInit, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HourSlots } from '../../data/hour.slots.data';
import { EventModel } from '../../models/event.model';
import { AuthService } from '../../common/auth.service';
import { NotificationService } from '../../common/notification.service';
import { AutomapperService } from '../../common/automapper.service';
import { EventService } from '../../event/event.service';
import { extendMoment } from 'moment-range';
import { VenueModel } from '../../models/venue.model';
import { EventTypes } from '../../data/event.types.data';
import * as Moment from 'moment';
import { VenueService } from '../venue.service';
const moment = extendMoment(Moment);

@Component({
  selector: 'app-create-table-component',
  templateUrl: './create-table-component.component.html',
  styleUrls: ['./create-table-component.component.scss']
})
export class CreateTableComponentComponent implements OnInit, AfterViewInit {

  @Output() created: EventEmitter<EventModel> = new EventEmitter();
  @ViewChild('gmap', { static: false }) gmapElement: any;

  createTableForm = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    time: new FormControl(null, [Validators.required])
  });

  map: google.maps.Map;
  venue: VenueModel;
  createTableDays: any;
  createTableTimeSlots: any;
  seatNumber = 1;
  currentUser: any;
  createInProgress = false;
  marker: any;

  constructor(
    private automapper: AutomapperService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private venueService: VenueService,
    private cr: ChangeDetectorRef,

  ) { }

  ngOnInit() {}

  async ngAfterViewInit() {
    const mapProp = {
      center: new google.maps.LatLng(44.787197, 20.457273),
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      streetViewControl: false,
      disableDoubleClickZoom: true,
      zoomControl: false,
      scrollwheel: false
    };
    this.currentUser = this.authService.getCurrentUser();
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.venue = await this.venueService.getBySlug(slug);
    this.cr.detectChanges();
    this.getCreateTableDays();
    this.getCreateTableTimeSlots();
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.prepareMapLocation();
  }

  seatNumberHandle(action: string) {
    if (action === 'decrement') {
      this.seatNumber > 1 ? this.seatNumber -= 1 : this.seatNumber = 1;
    }
    if (action === 'increment') {
      this.seatNumber += 1;
    }
  }

  dateHandler() {
    console.log(this.createTableForm, 'DATE HANDLER VALUE');
    console.log(this.createTableForm.get('date').value, 'DATE');
  }

  timeHandler() {
    console.log(this.createTableForm, 'time handler value');
    console.log(this.createTableForm.get('time').value, 'time');
  }

  getCreateTableDays = () => {
    const dates = [];
    for (let i = 0; i < 10; i++) {
      const day = this.venue.workingHours[`${moment().add(i, 'days').format('dddd').toLowerCase()}`];
      if (day && day.open) {
        if (i === 0) {
        dates.push({ label: 'Today', value: moment() });
        } else if (i === 1) {
        dates.push({ label: 'Tomorrow', value: moment().add(1, 'days') });
        } else {
        dates.push({
            label: moment().add(i, 'days').format('ddd, MMM DD'),
            value: moment().add(i, 'days')
        });
        }
      }
    }
    this.createTableDays = dates;
  }

  getCreateTableTimeSlots = () => {
    if (this.venue) {
      const workingHours = this.venue.workingHours;
      let currentDay = moment().format('dddd').toLowerCase();
      const date = this.createTableForm.get('date').value;

      if (date && date.value && moment.isMoment(date.value)) {
        currentDay = date.value.format('dddd').toLowerCase();
      }
      const start = !!workingHours[currentDay].start ? workingHours[currentDay].start.replace('T', '') : null; // 03:30 PM
      const end = !!workingHours[currentDay].end ? workingHours[currentDay].end.replace('T', '') : null;
      const startTime = moment(`${start}`, 'hh:mm a'); // 03:00:00 pm
      const endTime = moment(`${end}`, 'hh:mm a');

      if (startTime.isSame(endTime)) {
        return this.createTableTimeSlots =  HourSlots.slice(1);
      }

      if (startTime.isAfter(endTime)) {
        endTime.add(24, 'hours');
      }

      const timeRange = moment.range(startTime, endTime);
      const array = Array.from(timeRange.by('minutes', {step: 30, excludeEnd: true}));

      const formattedArray = array.map(m => HourSlots.find(item => item.label === m.format('hh:mm A')));
      this.createTableTimeSlots = formattedArray;
    }
  }

  toArray(object: any) {
    return Object.keys(object).map((key) => {
      return {
        ... object[key],
        day: key[0].toUpperCase() + key.slice(1),
      };
    });
  }

  async createTable() {
    if (!this.createTableForm.valid) {
      this.createTableForm.markAsDirty();
      return this.notificationService.error('Insufficient data. Date & time are required.');
    }
    this.createInProgress = true;
    // tslint:disable-next-line:max-line-length
    this.notificationService.error('Unfortunately, due to COVID-19 situation, we cannot create tables at the moment. Please try again when restaurants re-open');
    this.createInProgress = false;
    // try {
    //   const rawEvent = {
    //       startDate: this.createTableForm.get('date').value,
    //       startTime: this.createTableForm.get('time').value,
    //   };
    //   let event: EventModel = this.automapper.map('EventCreateForm', 'EventModel', rawEvent);
    //   event.type = EventTypes.PRIVATE;
    //   event.ownerId = this.currentUser.id;
    //   event.venueId = this.venue.id;
    //   event = await this.eventService.create(event);
    //   if (event) {
    //     this.notificationService.success('Table booking successfull!');
    //   }
    //   this.created.emit(event);
    //   this.createInProgress = false;
    // } catch (err) {
    //   this.createInProgress = false;
    // }
  }

  private prepareMapLocation() {
    const icon = {
       path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
       fillColor: '#242847',
       fillOpacity: .9,
       anchor: new google.maps.Point(0, 0),
       strokeWeight: 0,
       scale: 0.55
    };
    this.marker = new google.maps.Marker({
       position: {
          lat: this.venue.location.latitude,
          lng: this.venue.location.longitude
       },
       map: this.map,
       draggable: false,
       icon
    });
    this.map.setCenter(this.marker.getPosition());
 }

}
