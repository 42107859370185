import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap";
import { AutomapperService } from "src/app/common/automapper.service";
import { NotificationService } from "src/app/common/notification.service";
import { SessionStorageService } from "src/app/common/sessionStorage.service";
import { EventService } from "src/app/event/event.service";
import { EventModel } from "src/app/models/event.model";
import { MenuItemOptionModel } from "src/app/models/menu-item.model";
import { OrderModel } from "src/app/models/order.model";
import { UserModel } from "src/app/models/user.model";
import { OrderService } from "src/app/order/order.service";
import { PaymentService } from "src/app/payment/payment.service";
import { UserService } from "src/app/user/user.service";
import { VenueService } from "src/app/venue/venue.service";

@Component({
  selector: "app-split-payment-modal",
  templateUrl: "./payment.pay.component.html",
  styleUrls: ["./payment.pay.component.scss"],
})
export class PaymentPayModal implements OnInit {
  actualTipsPercentage!: number;
  actualTips!: number;
  subTotal!: number;
  taxPercentage = 8.875;
  actualTax!: number;
  total!: number;
  tipError = false;
  tipsPercetages!: number[];
  user!: UserModel;
  eventId!: string;
  invoiceItems!: any[];
  venueSlug: string;
  sessionId: string;
  tableNo: string;
  orders: OrderModel[];
  type: string;
  paymentInProgress = false;
  allOrders!: boolean;
  defaultTipPercentages = [18, 20, 25];
  private event!: EventModel

  constructor(
    private notificationService: NotificationService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private automapperService: AutomapperService,
    private router: Router,
    private orderService: OrderService,
    private venueService: VenueService,
    private eventService: EventService
  ) {}
  async ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get("eventId");
    this.venueSlug = this.route.snapshot.paramMap.get("slug");
    this.sessionId = this.route.snapshot.paramMap.get("sessionId");
    this.tableNo = this.route.snapshot.paramMap.get("tableNo");
    const ordersToPay = this.sessionStorageService.getItem("ordersToPay")
    if(!ordersToPay && !ordersToPay.length) {
      this.goBack()
    }
    
    this.orders = ordersToPay.orders
    this.subTotal = ordersToPay.subTotal
    this.actualTax = ordersToPay.orderTaxes
    this.allOrders = ordersToPay.allOrders
    
    this.type = this.route.snapshot.queryParamMap.get("type");
    const storedUser = this.sessionStorageService.getItem("temporary-user");
    this.user = await this.userService.getById(storedUser.id, storedUser.token);
    this.user.token = storedUser.token;
    await this.getEvent()
    
    this.calculateTotal();
    await this.getTipRules();
  }
  async getEvent() {
    this.event = await this.eventService.getById(this.eventId, this.user.token)
  }
  async getTipRules() {
    const guests = this.event.participants.length
    const rules = await this.venueService.getVenueTipsRule(this.event.id, guests)
    this.tipsPercetages = rules.percentages.length ? rules.percentages : this.defaultTipPercentages

    this.actualTipsPercentage = rules.percentages[0];
    this.calculateTips()
  }

  async getInvoice(userId: string, token: string) {
    const invoice = await this.paymentService.getUserInvoice(
      this.eventId,
      userId,
      token
    );
    this.invoiceItems = invoice.items;
    this.subTotal = invoice.subTotal;
    this.actualTax = invoice.itemsTax;
  }

  changeTips(event: any, tip: number) {
    let value = tip;
    if (event) {
      const customTip = event.target.valueAsNumber;
      const percentage = (customTip / this.subTotal) * 100;
      value = percentage;
    }
    this.actualTipsPercentage = value;
    this.tipError = value < this.tipsPercetages[0];
    this.calculateTips();
  }
  calculateTips() {
    this.actualTips = this.subTotal * (this.actualTipsPercentage / 100);
    this.calculateTotal();
  }
  calculateTotal() {
    this.total = this.subTotal + this.actualTax + this.actualTips;
  }

  getPrice(order: OrderModel, item: MenuItemOptionModel){
    if(!this.user) {
      return
    }
    const payer = order.payers.find(p=> p.userId === this.user.id)
    return payer ? item.price.value * item.quantity * (payer.percentage / 100) : item.price.value * item.quantity
  }
  
  async pay() {
    if (this.tipError) {
      return this.notificationService.error(
        "Tip error",
        `Minimun tip is ${this.tipsPercetages[0]}`
      );
    }
    try {
      this.paymentInProgress = true
      let checkoutSession;
      if (
        this.user &&
        this.user.payment &&
        this.user.payment.checkoutSessions &&
        this.user.payment.checkoutSessions.length
      ) {
        checkoutSession = this.user.payment.checkoutSessions.find(
          (cs) => cs.eventId === this.eventId && cs.paymentMethod
        );
      }
      const payload = {
        userId: this.user.id,
        eventId: this.eventId,
        pmId: checkoutSession.paymentMethod,
        csId: checkoutSession.id,
        totalAmount: Math.round(this.total * 100),
      };
      await this.paymentService.chargeToPaymentMethod(payload, this.user.token)
      const orderIds = this.orders.map(order => order.id)
      const ordersUpdated = await this.orderService.createWebTransaction({ userId: this.user.id, eventId: this.eventId, orderIds, allOrders: this.allOrders }, this.user.token)
      this.paymentInProgress = false

      if(ordersUpdated) {
        this.notificationService.success("Payment Succesful");
        this.goToSuccess()
        this.sessionStorageService.deleteItem("ordersToPay")
      }

      return;
    } catch (error) {
      this.paymentInProgress = false
    }
  }
  goToSuccess(){
    return this.router.navigate([
      `/mobile-qr/${this.venueSlug}/${this.tableNo}/${this.eventId}/${this.sessionId}/success`,
    ]);
  }
  goBack() {
    return this.router.navigate([
      `/mobile-qr/${this.venueSlug}/${this.tableNo}/${this.eventId}/${this.sessionId}`,
    ]);
  }
}
