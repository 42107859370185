import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-referral",
  templateUrl: "./referral.component.html",
  styleUrls: ["./referral.component.scss"],
})
export class ReferralComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    const code = window.location.search.substring(11);
    if (code === "ASTORIACONNECT") {
      window.location.href = "http://cuculi.com/astoriaconnect";
    }
  }
}
