import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { UserModel } from "src/app/models/user.model";
import { EventModel } from "src/app/models/event.model";

@Component({
  selector: "app-event-details-modals",
  templateUrl: "./event.details.modals.component.html",
  styleUrls: ["./event.details.modals.component.scss"],
})
export class EventDetailsModalsComponent {
  @Input() event: EventModel;
  @Input() user: UserModel;
  @Input() isFormerParticipant: boolean;
  @Input() isUserPotentialParticipant: boolean;
  @Input() isRegistered!: boolean;
  @Output() cancelRegistration: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createRegistration: EventEmitter<boolean> = new EventEmitter<boolean>();
  isOpenRegisterModal: boolean = false;

  emailToMask: string = '';
  ngOnInit() {
    console.log(this.isRegistered, this.isFormerParticipant);
    this.emailToMask = this.maskEmail(this.user.email);
  }


  maskEmail(email: string): string {
    if (!email) return '';

    const [username, domain] = email.split('@');
    if (!username || !domain) return email;

    // Calculate visible characters based on username length
    const visibleLength = username.length <= 4 ? 2 : 3;
    const maskedUsername = username.slice(0, visibleLength) + 'x'.repeat(username.length - visibleLength);

    return `${maskedUsername}@${domain}`;
  }

  showRegisterModal() {
    this.isOpenRegisterModal = true;
  }

  confirmUnRegistered() {
    this.isOpenRegisterModal = false;
    this.cancelRegistration.emit(true);
  }
  createRegistrationForUser() {
    this.createRegistration.emit(true);
  }
  getEmailHidden(email: string) {
    return email.replace(
      /(.{3})(.*?)(?=@)(@.{3})(.*?)(\..+)/g,
      (match, gp1, gp2, gp3, gp4, gp5) => {
        let hiddenUser = gp1 + "*".repeat(3); 
        let hiddenDomain = gp3 + "*".repeat(3); 
        return hiddenUser + hiddenDomain + gp5; 
      }
    );
  }
}
