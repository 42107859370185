import { NgModule } from '@angular/core';
import { VerificationCodeComponent } from './verification-code.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        VerificationCodeComponent
    ],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, LaddaModule, LayoutModule],
    providers: [],
})
export class VerificationModule { }
