import { GenericFormType, Lookup } from '../generic-form/generic.form.component';
import { Validators } from '@angular/forms';
import { LocationModel } from './location.model';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { VenueAuxFormBuilder } from '../venue-aux-form/venue.aux.form.builder';
import { WorkingWeekContraints, WorkingWeekModel } from './working.hours.model';
import { neighborhood, neighborhoodLongIsland } from 'src/configs/neighborhood';
import { timezonesLookup } from '../data/timezones.data'

export class VenueModel {
    id: string;
    mainImage: string;
    coverImage: string;
    manager: string[];
    public: boolean;
    name: string;
    slug: string;
    gallery: string[];
    description: string;
    phoneNumber: string;
    location: LocationModel;
    siteUrl: string;
    workingHours: WorkingWeekModel;
    features: string[];
    categories: string[];
    attire: string[];
    ambience: string;
    noiseLevel: string;
    tip: string;
    taxId: string;
    seatingCapacity: number;
    timeLimit: number;
    ownerId?: string;
    priceRating?: string;
    neighborhood?: string;
    companyName?: string;
    timeZone?: string;
}

export const VenueCreateConstraints: GenericFormType[] = [
    GenericFormBuilder.text('name', 'Name', [Validators.required]),
    GenericFormBuilder.textarea('description', 'Description', [Validators.required]),
    GenericFormBuilder.phone('phone', 'Phone', [Validators.required]),
    GenericFormBuilder.text('email', 'Email', []),
    GenericFormBuilder.switch('public', 'Public'),
    GenericFormBuilder.number('priceRating', 'Price Rating', []),
    GenericFormBuilder.multiselect('categories', 'Categories', [], [], false, 'vendor-categories'),
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.multiselect('features', 'Features', [], [], false, ['features-general', 'features-food', 'features-good-for', 'features-parking']),
    GenericFormBuilder.dropdown('time', 'Time', [], timezonesLookup, false),
    GenericFormBuilder.text('siteUrl', 'Site URL', [Validators.required]),
    GenericFormBuilder.array('workingHours', 'Working Hours', WorkingWeekContraints),
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown('neighborhood', 'Neighborhood (NY)', [], [], false, neighborhood),
    GenericFormBuilder.dropdown('neighborhood', 'Neighborhood (Long Island)', [], [], false, neighborhoodLongIsland),
];

export const VenueUpdateConstraints: GenericFormType[] = [
    GenericFormBuilder.text('name', 'Name', [Validators.required], true),
    GenericFormBuilder.textarea('description', 'Description', [Validators.required]),
    GenericFormBuilder.phone('phone', 'Phone', [Validators.required]),
    GenericFormBuilder.text('email', 'Email', []),
    GenericFormBuilder.switch('public', 'Public'),
    GenericFormBuilder.number('priceRating', 'Price Rating', []),
    GenericFormBuilder.multiselect('categories', 'Categories', [], [], false, 'vendor-categories'),
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.multiselect('features', 'Features', [], [], false, ['features-general', 'features-food', 'features-good-for', 'features-parking']),
    GenericFormBuilder.text('siteUrl', 'Site URL', [Validators.required]),
    GenericFormBuilder.array('workingHours', 'Working Hours', WorkingWeekContraints),
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown('neighborhood', 'Neighborhood (NY)', [], [], false, neighborhood),
    GenericFormBuilder.dropdown('neighborhood', 'Neighborhood (Long Island)', [], [], false, neighborhoodLongIsland),
];

export const NewCategorieConstraints: GenericFormType[] = [
    GenericFormBuilder.text('value', 'Categorie', [Validators.required])
]

export const NewFeatureConstraints: GenericFormType[] = [
    GenericFormBuilder.text('value', 'Feature', [Validators.required]),
]