import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';
import { BaseService } from '../common/base.service';
import { OrderModel } from '../models/order.model';
import { OrderStatuses } from '../data/order.statuses.data';
import { IBasketItem } from '../mobile-ordering/basket/basket.service';
import { environment } from 'src/environments/environment';
import { MenuItemOptionModel } from '../models/menu-item.model';

@Injectable()
export class OrderService extends BaseService<OrderModel> {

  constructor(
    apiService: ApiService,
    automapper: AutomapperService) {
      super(apiService, automapper);
  }

  protected getModelName(): string {
    return 'OrderModel';
  }
  protected getApiName(): string {
    return 'OrderApi';
  }
  protected getApiPrefix(): string {
    return 'order';
  }
  public async createOrderRegular(eventId: string, tableNumber: string, items: MenuItemOptionModel[], token?: string): Promise <OrderModel | OrderModel[]> {
    const itemsToSend = items.map(item => ({
      menuItemId: item.menuItemId,
      optionId: item.optionId,
      quantity: item.quantity
    }))
    const payload = {
      eventId,
      tableNumber,
      items: itemsToSend
    }
    return this.apiService.post(`/order`, payload, token ? [
      { key: 'Authorization', value: `Bearer ${token}` }
    ] : [])
  }

  public async createOrder(userId: string, venueId: string, ordersId: string, table: string, eventId: string, seatNumber: string, tableNumber: string, items: IBasketItem[], source?: any, tips?: number, hidden?: boolean, stripePi?: string, stripeSessionId?: string,token?:string): Promise<OrderModel | OrderModel[]> {
    const data = {
      userId,
      ordersId,
      seatNumber,
      tableNumber,
      source,
      tips,
      hidden: !!hidden,
      stripePi,
      stripeSessionId,
      items: items.map((x) => {
        return {
          menuItemId: x.menuItemId,
          optionId: x.optionId,
          quantity: x.quantity,
          addons: x.addons.map((y) => {
            return {
              id: y.id,
              quantity: y.quantity
            };
          }),
          specialInstructions: x.specialInstructions,
          priority: x.priority
        };
      })
    };
    return this.apiService.post(`/venue/${venueId}/table/${table}/${eventId}/order`, data, token ? [
      { key: 'Authorization', value: `Bearer ${token}` }
    ] : [])
      .then((res) => {
        return this.automapper.map<OrderModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async createByParticipant(eventId: string, items: any[]) {
    // TEMP FIX FOR MISSING PRIORITY
    items.forEach((x) => {
      x.priority = '1st';
    });
    const data = {
      eventId,
      items
    };
    return this.apiService.post(`/${this.getApiPrefix()}`, data, [])
      .then((res) => {
        return this.automapper.map<OrderModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async createByVenue(eventId: string, venueId: string, items: any[]) {
    // TEMP FIX FOR MISSING PRIORITY
    items.forEach((x) => {
      x.priority = '1st';
    });
    return this.apiService.post(`/venue/${venueId}/event/${eventId}/order`, { items }, [])
      .then((res) => {
        return this.automapper.map<OrderModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async createWebTransaction(payload: { userId: string, eventId: string, orderIds: string[], allOrders: boolean }, token?: string): Promise<any>{
    const {eventId, ...rest} = payload
    return this.apiService.post(`/event/${eventId}/orders/participants`, rest, token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : [])
  }

  public async approveOrder(order: OrderModel, token?: string) {
    return this.upadteOrderStatus(order, OrderStatuses.APPROVED, token);
  }

  public async rejectOrder(order: OrderModel, token?: string) {
    return this.upadteOrderStatus(order, OrderStatuses.REJECTED, token);
  }

  public async completeOrder(order: OrderModel, token?: string) {
    return this.upadteOrderStatus(order, OrderStatuses.COMPLETED, token);
  }

  public async payBulkOrder(rawOrders: OrderModel[], source: any, tip: number, hide: boolean, eventId: string, useCredit?: boolean) {
    const orders = [];
    rawOrders.forEach(item => orders.push(item.id));
    const payload = {
      orders,
      source,
      useCredit: !!useCredit,
      tips: tip,
      hidden: hide,
      eventId,
    };
    return this.apiService.post(`/${this.getApiPrefix()}s/pay`, payload, [])
      .then((res) => {
        return this.automapper.map<OrderModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async payOrder(order: OrderModel, source: any, tip: number, hide: boolean) {
    const payload = {
      orderId: order.id,
      source,
      useCredit: false,
      tips: tip,
      hidden: hide
    };
    return this.apiService.post(`/${this.getApiPrefix()}/${order.id}/pay`, payload, [])
      .then((res) => {
        return this.automapper.map<OrderModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async getCheckoutInfo (sessionId: string) {
    const res = await this.apiService.get(`/payment/checkout-info/${sessionId}`, []);
    return res;
  }

  public async createCheckoutLink(items: object[], totalPrice: number, tipAndTaxes: number, userId?: string, successUrl?: string, cancelUrl?: string, metadata?: object, eventId?: string, venueId?: string) {
    const payload = {
      items,
      totalPrice,
      tipAndTaxes,
      userId,
      successUrl: successUrl,
      cancelUrl: cancelUrl || `${environment.homePage}`,
      metadata,
      eventId,
      venueId
    };
    const res = this.apiService.post(`/payment/checkout-session`, payload, []);
    return(res);
  }

  public async createPaymentLink(name: string, price: number, url:string) {
    const payload = {
      productName: name,
      priceAmount: price,
      priceCurrency: 'USD',
      successUrl: url,
    };
    const res = this.apiService.post(`/payment/payment-link`, payload, []);
    return(res);
  }

  public async cancelCheckoutSession(userId: string, eventId: string, stripeSessionId: string){
    const payload = {
      userId,
      stripeSessionId
    }
    const res = this.apiService.post(`/webPayment/${eventId}/cancel`, payload, []);
    return res;
  }

  public async deleteOrderById( orderId: string, token?: string) {
    return this.apiService.delete(`/order/${orderId}`, token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : [])
  }

  // tslint:disable-next-line:max-line-length
  public async createMobileOrders(venueId: string, table: string, seatNumber: string, items: IBasketItem[], source: any, tips?: number, hidden?: boolean): Promise<OrderModel | OrderModel[]> {
    const data = {
      seatNumber,
      source,
      tips,
      hidden: !!hidden,
      items: items.map((x) => {
        return {
          menuItemId: x.menuItemId,
          optionId: x.optionId,
          quantity: x.quantity,
          addons: x.addons.map((y) => {
            return {
              id: y.id,
              quantity: y.quantity
            };
          }),
          specialInstructions: x.specialInstructions,
          priority: x.priority
        };
      })
    };
    return this.apiService.post(`/venue/${venueId}/table/${table}/order`, data, [])
      .then((res) => {
        return this.automapper.map<OrderModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  public async getOrders(eventId?: string, userId?: string, token?: string): Promise<OrderModel[]> {
    let queryParameter = ''
    eventId ? queryParameter += `eventId=${eventId}&` : null
    userId ? queryParameter += `userId=${userId}&` : null
    const res = this.apiService.get(
      `/order?${queryParameter}`,
      token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : []
    );
    return(res);
  }
  
  public async updateOrder(orderId: string, order: OrderModel, token?: string): Promise<OrderModel> {
    return this.apiService.put(`/order/${orderId}`, order, token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : [])
  }

  public async splitOrder(payload: { orders: string[], payers: { userId: string, percentage: number }[] }, token: string){
    return this.apiService.post(
      '/payment/split-orders',
       payload,
       [ { key: 'Authorization', value: `Bearer ${token}` } ]
      )
  }
  public async  updateMultipleOrderStatus(orderIds: string[], status: string, token?: string) {
    return this.apiService.post(`/${this.getApiPrefix()}/status`, { orderIds, status }, token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : [])
  }
  private upadteOrderStatus(order: OrderModel, status: string, token?: string): Promise<OrderModel> {
    return this.apiService.post(`/${this.getApiPrefix()}/${order.id}/status`, { status }, token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : [])
      .then((res) => {
        return this.automapper.map<OrderModel>(this.getApiName(), this.getModelName(), res);
      });
  }
}
