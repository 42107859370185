import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { QrPaymentPageComponent } from './qr.payment.page.component';
import { QRPaymentTabComponent } from './tab.payment/tab.payment.component';
import { QrMenuComponent } from './qr-menu/qr.menu.component';
import { TabsModule } from 'ngx-bootstrap';
import { PaymentSuccesModalComponent } from './paymet-success-modal/payment.success.modal.component';
import { SplitPaymentModal } from './split-payment-modal/split-payment-modal.component';
import { QRItemComponent } from './payment-item/payment.item.component';
import { QRTabMyOrders } from './tab-my-order/tab.my.order.payment.component';
import { QRTabSplitOrders } from './tab-split-order/tab.split.order.payment.component';
import { PaymentPayModal } from './payment-pay/payment.pay.component';
import { MobileOrderingModule } from '../mobile-ordering/mobile-ordering.module';
@NgModule({
  declarations: [
    QrPaymentPageComponent,
    QRPaymentTabComponent,
    QrMenuComponent,
    PaymentSuccesModalComponent,
    SplitPaymentModal, 
    QRItemComponent,
    QRTabMyOrders,
    QRTabSplitOrders,
    PaymentPayModal
  ],
  entryComponents: [
    SplitPaymentModal
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    FileUploadModule,
    MultiSelectModule,
    DropdownModule,
    TabsModule,
    MobileOrderingModule
  ],
  exports: [
    QrMenuComponent
  ]
})
export class QrPaymentModule { }
