import { Injectable } from '@angular/core';
import { LocationModel } from '../models/location.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LocationService {

  constructor(
    private http: HttpClient,) {
  }
  getLocationUrl(location: LocationModel): string {
    const { latitude, longitude } = location;
    const timestamp = Math.floor(Date.now() / 1000);
    return `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${environment.agmCoreApiKey}`;
  }

  getTimeZone(timeZoneUrl: string): Observable<any> {
    try {
      return this.http.get(timeZoneUrl);
    } catch (error) {
      throw error;
    }
  }

}
