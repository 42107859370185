import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { NewCategorieConstraints, NewFeatureConstraints } from '../models/venue.model';
import { VenueAuxService } from './venue.aux.service';
import { EnumService } from '../common/enum.service';

@Component({
  selector: 'app-venue-aux-form',
  templateUrl: './venue-aux-form.component.html',
  styleUrls: ['./venue-aux-form.component.scss']
})
export class VenueAuxFormComponent implements OnInit {

  @ViewChild('genericForm', { static: false }) genericForm: GenericFormComponent;

  @Input() typeOfModal: string;

  controlName: string;
  formRules: GenericFormType[];
  constructor(private venueAuxService: VenueAuxService, private enumService: EnumService) { }

  ngOnInit() {
    if(this.typeOfModal === 'categorie') {
      this.formRules = NewCategorieConstraints
    }
    else this.formRules = NewFeatureConstraints
    this.controlName = this.typeOfModal + 's'
  }
  async create(){
    if(this.genericForm.isValid()){
      const { value } = this.genericForm.myForm.controls
      const newValue = value.value.charAt(0).toUpperCase() + value.value.slice(1).toLowerCase()
      const newType = this.typeOfModal === 'feature' ? "features-general" : "vendor-categories"
      try {
        const response = await this.enumService.createType(newValue, newType)
        if(response) {
          this.close()
          let newValue = this.controlName === 'features' ? response.value.toLowerCase() : response.value.toUpperCase();

          const newLabel = {
            label: response.value,
            value: newValue,
            type: response.type,
            id: response.id
          }
          this.venueAuxService.updateFormValue(this.controlName, newValue, newLabel)
        }
        
      } catch (error) {
        console.error(error)
      }
    }
  }
  close() {
    this.venueAuxService.closeModal()
  }

}
