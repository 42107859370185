import { Component, OnInit, Input, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { LocationModel } from '../models/location.model';
import { AutomapperService } from '../common/automapper.service';

@Component({
  selector: 'app-location-input',
  templateUrl: './location.input.component.html',
  styleUrls: ['./location.input.component.scss']
})
export class LocationInputComponent implements OnInit, AfterViewInit {

  @Input() enablePlaceSearch: boolean;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() location: any;
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  zoom: number;
  currentLocation: LocationModel;
  showMap: boolean;

  private geoCoder;

  constructor(private automapper: AutomapperService, private ngZone: NgZone) {
    this.showMap = false;
    this.geoCoder = new google.maps.Geocoder();
    this.zoom = 12;
  }

  ngOnInit() {
    if (this.location) {
      this.currentLocation = this.location;
      this.showMap = true;
      return;
    }
    if (this.latitude && this.longitude && !this.showMap) {
      this.getAddress(this.latitude, this.longitude);
      this.showMap = true;
    }
  }

  ngAfterViewInit() {
    this.initializePlaceSearch();
  }

  markerDragEnd($event) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  private getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          console.log(results[0], 'currentLocation', this.currentLocation);
          const city = results[0].address_components.find(item => item.types.includes("administrative_area_level_2")).long_name;
          this.currentLocation = this.automapper.map('GoogleResult', 'LocationModel', results[0]);
          this.currentLocation.city = city;
          // this.currentLocation.neighborhood = location ? location.neighborhood : null;
          console.log(this.currentLocation);
        } else {
          this.currentLocation = new LocationModel();
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  private initializePlaceSearch() {
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ['establishment'],
    });
    autocomplete.addListener('place_changed', () => {
      console.log('PLACE CHANGED');
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    });
  }
}
