import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { NotificationService } from "src/app/common/notification.service";
import { OrderModel } from "src/app/models/order.model";
import { UserModel } from "src/app/models/user.model";
import { OrderService } from "src/app/order/order.service";
import { SplitPaymentModal } from "../split-payment-modal/split-payment-modal.component";
import { MenuItemModel } from "src/app/models/menu-item.model";
import { ActivatedRoute } from "@angular/router";
import { EventService } from "src/app/event/event.service";
import { OrderStatuses } from "src/app/data/order.statuses.data";
import { SessionStorageService } from "src/app/common/sessionStorage.service";

@Component({
  selector: "app-payment-tab-split-orders",
  templateUrl: "./tab.split.order.payment.component.html",
  styleUrls: [
    "../tab.payment/tab.payment.component.scss",
  ],
})
export class QRTabSplitOrders implements OnInit {
  @Input() user!: UserModel;
  @Input() orders: OrderModel[];

  @Output() navigateToMenu = new EventEmitter<void>();
  @Output() goToPay = new EventEmitter<string>()
  
  eventId!: string;
  ordersToPay!: OrderModel[];

  TYPE_OF_PAY = "All Orders"

  navigates = {
    MENU: 'menu',
    PAY: 'pay'
  }

  sorts = {
    username: "username",
    orderTime: "orderTime",
  };
  currentSort = this.sorts.username;
  bsModalRef?: BsModalRef;
  participants!: UserModel[];
  subTotal = 0;
  total = 0;
  tipsTotal = 0;
  taxTotal = 0;
  totalPaid = 0;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private orderService: OrderService,
    private notificationService: NotificationService,
    private sessionStorageService: SessionStorageService,
    private eventService: EventService
  ) {}

  async ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get("eventId");
    await this.getAllOrders();
    await this.getParticipants();
  }

  handleSort = (event: any, sort: string) => {
    
    if (event){
      const prevSelector = this.$(".paymentTab-content-sort-button.active");
      prevSelector.classList.remove("active");
      const target = event.target;
      target.classList.add("active");
    }

    this.currentSort = sort;
    this.orders = this.orders.sort((a, b) => {
      if (sort === this.sorts.username) {
        return a.userFullName < b.userFullName ? 1 : -1;
      } else if (sort === this.sorts.orderTime) {
        return a.createdAt < b.createdAt ? -1 : 1;
      }
    });
  };

  $(selector: string) {
    return document.querySelector(selector);
  }

  async handleSplitOrder(splitData: {
    orders: string[];
    payers: { userId: string; percentage: number }[];
  }) {
    try {
      const response = await this.orderService.splitOrder(
        splitData,
        this.user.token
      );
      if (response) {
        await this.getAllOrders();
        this.notificationService.success("Order splitted successfully");
      }
    } catch (error) {
      console.error("Error updating split order", error);
    }
  }
  
  showCloseTab() {
    const orders = this.ordersToPay;
    if(orders) {
      return (
        (orders && !orders.length) ||
        orders.some((order) =>
          this.isAbleToPay(order)
        )
      );
    }
  }

  async getAllOrders() {
    this.orders = await this.orderService.getOrders(
      this.eventId,
      null,
      this.user.token
    );
    this.handleSort(null, this.currentSort)
    this.getUnPaidOrders()
    this.calculateTotals()
  }

  reloadOrders(){
    this.getAllOrders();
  }

  getUnPaidOrders(){
    this.ordersToPay = this.orders.filter(
      (order) => {
        return this.isAbleToPay(order)
      }
    );
  }
  openSplitOrderModal(event: {order: OrderModel, item: MenuItemModel}) {
    const {order, item} = event
    if(!this.isAbleToPay(order)){
      this.notificationService.info("Order is already paid")
      return
    }
    const initialState = {
      order,
      item,
      participants: this.participants,
      onConfirm: this.handleSplitOrder.bind(this),
    };
    this.modalService.show(SplitPaymentModal, { initialState });
  }  
  async getParticipants() {
    this.participants = await this.eventService.getEventParticipantsInfo(this.eventId, this.user.token);
  }
  isAbleToPay(order: OrderModel): boolean {
    const allHolded = order.payers.every(p=> p.holded)
    return !this.isOrderCompletedOrPaid(order.orderStatus) && !allHolded
  }

  isOrderCompletedOrPaid(orderStatus: string) : boolean {
    return [OrderStatuses.PAID, OrderStatuses.REJECTED].includes(orderStatus)
  }

  navigate(type: string) {
    switch (type) {
      case this.navigates.MENU:
        return this.navigateToMenu.emit();

      case this.navigates.PAY:
        let subTotal = 0, taxTotal = 0;
        this.ordersToPay.forEach((order) => {
          const payer = order.payers.find((p) => p.userId === this.user.id )
          subTotal += payer ? (order.price.value * (payer.percentage / 100)) : order.price.value;
          taxTotal += payer ? (order.price.taxTotal * (payer.percentage / 100)) : order.price.taxTotal;
        });
        const payload = {
          orders: this.ordersToPay,
          subTotal,
          orderTaxes: taxTotal,
          allOrders: true
        }
        this.sessionStorageService.setItem("ordersToPay", payload)
        return this.goToPay.emit(this.TYPE_OF_PAY)
    }
  }
  calculateTotals() {
    let subTotal = 0
    let total = 0
    let tipsTotal = 0
    let taxTotal = 0
    let totalPaid = 0

    this.orders.forEach((order) => {
      subTotal += order.price.value;
      total += order.price.total;
      tipsTotal += order.price.tipsTotal;
      taxTotal += order.price.taxTotal;
    });

    const ordersPaid = this.orders.filter(
      (order) =>
        !this.ordersToPay.some((orderToPay) => order.id === orderToPay.id)
    );

    ordersPaid.forEach((order) => {
      totalPaid += order.price.total;
    });

    this.subTotal = subTotal
    this.total = total
    this.tipsTotal = tipsTotal
    this.taxTotal = taxTotal

    this.totalPaid = totalPaid
  }
}
