import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { TabsModule, BsDropdownModule, TooltipModule, CarouselModule, TimepickerModule } from 'ngx-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { LayoutModule } from '../layout/layout.module';
import { MobileOrderingHomeComponent } from './home/home.component';
import { MobileOrderingMenuComponent } from './menu/menu.component';
import { AgmCoreModule } from '@agm/core';
import { RouterModule } from '@angular/router';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { UiSwitchModule } from 'ngx-ui-switch';
import { EventModule } from '../event/event.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HappyHourModule } from '../happy-hour/happy-hour.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BasketService } from './basket/basket.service';
import { MobileOrderingMenuItemComponent } from './menu-item/menu-item.component';
import { MobileOrderingBasketComponent } from './basket/basket.component';
import { Module as StripeModule } from 'stripe-angular';
import { MobileOrderingCheckoutComponent } from './checkout/checkout.component';
import { MobileOrderingLoadingComponent } from './loading/loading.component';
import { MobileService } from './mobile.service';
import { CheckoutRedirectComponent } from './checkout-redirect/checkout-redirect.component';

@NgModule({
  declarations: [
    MobileOrderingHomeComponent,
    MobileOrderingMenuComponent,
    MobileOrderingMenuItemComponent,
    MobileOrderingBasketComponent,
    MobileOrderingCheckoutComponent,
    MobileOrderingLoadingComponent,
    CheckoutRedirectComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    FileUploadModule,
    TabsModule,
    BsDropdownModule,
    AutoCompleteModule,
    FormsModule,
    LayoutModule,
    TooltipModule.forRoot(),
    AgmCoreModule,
    CarouselModule,
    RouterModule,
    TimepickerModule,
    NgxIntlTelInputModule,
    TimepickerModule,
    UiSwitchModule,
    EventModule,
    BsDropdownModule,
    DragDropModule,
    HappyHourModule,
    NgxSpinnerModule,
    StripeModule
  ],
  providers: [BasketService, MobileService],
  entryComponents: [
  ],
  exports: [
  ],
  bootstrap: []
})
export class MobileOrderingModule { }
