import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { VenueService } from '../../venue.service';
import { GenericFormType, GenericFormComponent } from '../../../generic-form/generic.form.component';
import { GenericFormBuilder } from '../../../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { AuthService } from '../../../common/auth.service';
import { UserModel } from '../../../models/user.model';
import { UserService } from '../../../user/user.service';
import { UserRoles } from '../../../data/user.roles.data';
import { NotificationService } from '../../../common/notification.service';
import { retypePasswordValidator } from '../../../models/user.model';

import { ActivatedRoute, Router } from '@angular/router';
import { VenueModel } from '../../../models/venue.model';
import { StorageService } from '../../../common/storage.service';
@Component({
  selector: 'app-venue-claim-co',
  templateUrl: './venue.claim.co.component.html',
  styleUrls: ['../venue.claim.component.scss']
})
export class VenueClaimCOComponent implements OnInit, AfterViewInit {

  @ViewChild('claimVenueForm', { static: false }) claimVenueForm: GenericFormComponent;
  @Input() venue:VenueModel;
  @Input() currentUser: UserModel;
  @Input() country: string;
  currency = 'COP';
  claimInProgress: boolean;
  claimVenueFormRules: GenericFormType[];

  constructor(
    private venueService: VenueService,
    private userService: UserService,
    private authService: AuthService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateFormWithStoredData();
      this.claimVenueForm.myForm.valueChanges.subscribe((val) => {
        this.storageService.setItem(this.venue.slug, val);
      });
    }, 1000);
  }

  async ngOnInit() {
    this.claimInProgress = false;
    if (this.authService.isLoggedIn().value) {
      this.authService.logout();
    }
    console.log('====================================');
    console.log('THIS IS ON COLOMBIA');
    console.log(this.venue, 'this.venue');
    console.log('====================================');
    this.currentUser = this.authService.getCurrentUser();
    const identificationLookup = [
    {
      label: '',
      value: ''
    },
    {
      label: 'Cedula de ciudadania',
      value: 'citizenId'
    }, {
      label: 'Cedula de extranjeria',
      value: 'foreignerId'
    }, {
      label: 'Pasaporte',
      value: 'passport'
    }]
    this.claimVenueFormRules = [
      // tslint:disable: max-line-length
        GenericFormBuilder.group('authorizer', 'Información \nRepresentativa ', [], null, 'row', 'col-md-12 inline-block claim-restaurant section-heading align-self-center', '', 'seaction-heading description'),
        GenericFormBuilder.text('firstName', 'Nombre', [Validators.required], !!this.currentUser, null, 'col-md-6 inline-block claim-restaurant required', this.currentUser ? this.currentUser.firstName : ''),
        GenericFormBuilder.text('lastName', 'Apellido', [Validators.required], !!this.currentUser, null, 'col-md-6 inline-block claim-restaurant required', this.currentUser ? this.currentUser.lastName : ''),
        GenericFormBuilder.datepicker('birthDay', 'Dia de Nacimiento', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant required'),
        GenericFormBuilder.text('email', 'Correo electrónico', [Validators.required], !!this.currentUser, null, 'col-md-6 inline-block claim-restaurant required', this.currentUser ? this.currentUser.email : null, 'example@email.com'),
        GenericFormBuilder.password('password', 'Contraseña', [Validators.required], null, 'col-md-6 inline-block claim-restaurant required'),
        GenericFormBuilder.password('repeatPassword', 'Confirmar Contraseña', [Validators.required, retypePasswordValidator('password')], null, 'col-md-6 inline-block claim-restaurant required', 'Contraseñas invalidas. Por favor revisar los campos.'),
        GenericFormBuilder.text('homeAddress', 'Direccion personal', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant required', this.currentUser ? this.currentUser.address : ''),
        
        GenericFormBuilder.group('venue', 'Información del \nRestaurante', [], null, 'row', 'col-md-12 inline-block claim-restaurant section-heading align-self-center'),
        GenericFormBuilder.text('name', 'Nombre del Restaurante', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.name, 'Nombre del Restaurante'),
        GenericFormBuilder.phone('phone', 'Numero de teléfono', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', '', true),
        GenericFormBuilder.text('address', 'Dirección del Negocio', [Validators.required], true, null, 'col-md-6 inline-block claim-restaurant', this.venue.location ? this.venue.location.address : '', 'Direccion del negocio'),
        GenericFormBuilder.text('website', 'Sitio Web', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.siteUrl),
        GenericFormBuilder.text('description', 'Descripción del Negocio', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.description, 'Description'),
        GenericFormBuilder.text('taxId', 'Número de Identificación Tributaria (NIT)', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.taxId, '123456789'),
        GenericFormBuilder.text('companyName', 'Razón Social (Asociado al NIT)', [], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.companyName, 'Omita si es el mismo Nombre del Restaurante'),
        
        GenericFormBuilder.group('identification', 'Información de \nIdentificación', [], null, 'row', 'col-md-12 inline-block claim-restaurant section-heading align-self-center'),
        GenericFormBuilder.dropdown('identificationType', 'Tipo de identificación', [Validators.required], identificationLookup, false),
        GenericFormBuilder.text('identificationNumber', 'Numero de identificación', [Validators.required], null, null, 'col-md-12 inline-block claim-restaurant', '', '123456789'),

      ];
  }

  updateFormWithStoredData() {
    const storedForm = this.storageService.getItem(this.venue.slug);
    console.log(storedForm, 'storedForm');
    if (!!storedForm) {
        this.claimVenueForm.myForm.setValue({
          ... storedForm,
          phone: storedForm.phone.number,
          birthDay: new Date(storedForm.birthDay)
        });
      }
  }

  removeUnwantedCharacters(formData: any) {
    formData.email = formData.email ? formData.email.replace(/\s+/g, '') : null;
    formData.website = formData.website ? formData.website.replace(/\s+/g, '') : null;
    formData.firstName = formData.firstName ? formData.firstName.replace(/\s+/g, '') : null;
    formData.lastName = formData.lastName ? formData.lastName.replace(/\s+/g, '') : null;

    formData.ssn = formData.ssn ? formData.ssn.replace(/\D/g, '') : null;
    formData.taxId = formData.taxId ? formData.taxId.replace(/\D/g, '') : null;
    formData.accountNumber = formData.accountNumber ? formData.accountNumber.replace(/\D/g, '') : null;
    formData.accountRoutingNumber = formData.accountRoutingNumber ? formData.accountRoutingNumber.replace(/\D/g, '') : null;

    return formData;
  }

  customFormValidation(formData: any) {
    // if (formData.ssn && formData.ssn.length !== 4) {
    //   this.notificationService.error('SSN has to have 4 digits');
    //   return false;
    // }
    if (formData.taxId && formData.taxId.length !== 9) {
      this.notificationService.error('El NIT debe tener 9 dígitos.');
      return false;
    }
    // if (formData.accountRoutingNumber && formData.accountRoutingNumber.length !== 9) {
    //   this.notificationService.error('Account Routing Number has to have 9 digits');
    //   return false;
    // }

    if (formData.identificationType && formData.identificationType === ''){
      this.notificationService.error('Please select identification type');
      return false
    }

    return true;
  }

  hasNumber(value: string) {
    return /\d/.test(value);
  }

  async doClaim() {
    if (this.venue.ownerId) {
      this.notificationService.error('Error', 'This venue has already been claimed');
      this.navigateBack();
    }
    if (this.claimVenueForm.isValid()) {

      let formData = this.claimVenueForm.toObject();
      formData = this.removeUnwantedCharacters(formData);
      if (!this.customFormValidation(formData)) {
        return;
      }
      try {
        this.claimInProgress = true;
        if (!this.authService.isLoggedIn().value) {
          const user: UserModel = new UserModel();
          user.firstName = formData.firstName;
          user.lastName = formData.lastName;
          user.username = formData.email;
          user.email = formData.email;
          user.address = formData.homeAddress;
          user.password = formData.password;
          user.identificationType = formData.identificationType;
          user.identificationNumber = formData.identificationNumber;
          user.role = UserRoles.REGULAR;
          await this.userService.createTemporaryUser(user);
          await this.authService.login(user.email, user.password);
        }

        let phoneNumber;
        if (formData.phone.number.indexOf(formData.phone.dialCode ) === -1) {
          phoneNumber = formData.phone.dialCode + formData.phone.number;
        } else {
          phoneNumber = formData.phone.number;
        }
        phoneNumber = phoneNumber.replace(/ /g, '');
        if (!formData.companyName) {
          formData.companyName = this.venue.name;
        }
        await this.venueService.claimVenue(
          this.venue,
          formData.firstName,//formData.accountHolder,
          "000123456789",// formData.accountNumber,
          formData.taxId,
          formData.identificationNumber,
          phoneNumber,
          this.country, 
          this.currency,
          "123456789", //formData.accountRoutingNumber,
          formData.birthDay,
          formData.companyName,
          formData.website,
          null,
          true
          );
        this.claimInProgress = false;
        this.navigateBack();
        this.notificationService.success('Venue succesfully claimed.');
        this.storageService.deleteItem(this.venue.slug);
      } catch (err) {
        this.claimInProgress = false;
        this.currentUser = this.authService.getCurrentUser();
        if (this.currentUser && this.currentUser.role === UserRoles.TEMP) {
          this.authService.logout();
        }
        console.log(err, 'this is err');
        this.notificationService.error('Something went wrong. Failed to claim venue.');
      }
    }
  }

  review = () => {
    this.claimVenueForm.isValid();
    const body = document.querySelector('body');
    body.scrollTo({ top: 0, behavior: 'smooth' });
  };

  navigateBack = () => this.router.navigateByUrl(`/venues/${this.venue.slug}`);

  private generatePass(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
