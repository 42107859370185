import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Statuses } from "src/app/data/statuses.data";
import { GenericFormComponent, GenericFormType } from "src/app/generic-form/generic.form.component";
import { EventLocation, EventModel } from "src/app/models/event.model";
import { UserModel } from "src/app/models/user.model";
import { EventService } from "../event.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/common/auth.service";
import { EnumService } from "src/app/common/enum.service";
import { VenueService } from "src/app/venue/venue.service";
import { BsModalService } from "ngx-bootstrap";
import * as momentTz from 'moment-timezone';
import * as moment from "moment";
import { NotificationService } from "src/app/common/notification.service";
import { neighborhood } from "src/configs/neighborhood";
import { Platforms } from "../tickets/ticket.enum";

@Component({
  selector: 'app-private-event-profile',
  templateUrl: './private-event-profile.component.html',
  styleUrls: ['./private-event-profile.component.scss']
})
export class PrivateEventProfileComponent implements OnInit {
  @ViewChild("OrdersFilterForm", { static: false }) ordersFilterForm: GenericFormComponent;
  @ViewChild("gmap", { static: false }) gmapElement: any;

  events: EventModel[];
  event: any;
  seats: number;
  currentUser: UserModel;
  shownDetails: string;
  statuses = Statuses;
  participants = [];
  filterParticipantsRules: GenericFormType[];
  venueNeighborhood: string;
  map: google.maps.Map;
  marker: any;
  images: any[];
  participantsToShow: any;
  remainingNumberOfParticipants: number;
  participantsLimit = 5;

  isUserRegisterForEvent: boolean = false;
  isUserFormerParticipant: boolean = false;
  isUserPotentialParticipant: boolean = false;
  isUserFormerPotentialParticipant: boolean = false;

  imagesReady: boolean = false;
  isMobile: boolean;
  availableTickets: number;
  eventLocation: EventLocation;

  defaultDescription = `Join Us for an Unforgettable Experience!

  Immerse yourself in the unique atmosphere of our upcoming event, designed to bring together like-minded individuals for a day/evening of engaging activities and enriching encounters. Whether you're a first-timer or a seasoned attendee, our Table promises something for everyone.

  What to Expect:

  Engaging Activities: Participate in a variety of sessions tailored to foster learning, creativity, and connection. Each Table is crafted to enhance your experience and engagement.

  Networking Opportunities: Meet and mingle with a diverse group of participants, each bringing their own unique perspectives and stories. Expand your network, exchange ideas, and build lasting relationships.

  Delicious Refreshments: Enjoy a selection of gourmet bites and refreshing beverages. Individual ordering makes it possible to cater to all tastes and dietary preferences, ensuring you stay energized and satisfied.

  Inspirational Atmosphere: Our venue is chosen specifically to inspire and delight. Whether indoors or outdoors, the space will complement the Table's theme and enhance your overall experience.

  We are excited to welcome you and look forward to creating memorable moments together!`;
  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private enumService: EnumService,
    private venueService: VenueService,
    private router: Router,
    private notificationService: NotificationService,
    private cr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    if (this.authService.isLoggedIn().value) {
      this.currentUser = this.authService.getCurrentUser();
    }
    this.isMobile = window.innerWidth < 768;
    const id: string = this.route.snapshot.paramMap.get("id");
    this.cr.detectChanges();
    await this.loadEvent(id);
    await this.getNeighborhood();
    this.getImages();
    // this.loadMap();
    this.getEventLocation(this.event.venue, this.event)
  }

  private async loadEvent(id: string) {
    this.event = await this.eventService.getWebEvent(id);
    
    if (this.currentUser) {
     if(this.currentUser.role !== 'POTENTIAL') {
      this.isUserRegisterForEvent = this.event.participants.some((participant) => participant.id === this.currentUser.id);

      if (this.event.formerParticipants) {
        this.isUserFormerParticipant = this.event.formerParticipants.some((participant) => participant.email === this.currentUser.email);
      }
     }

      if(this.currentUser.role === 'POTENTIAL') {
        if (this.event.potentialUsers.length) {
          this.isUserPotentialParticipant = this.event.potentialUsers.some((participant) => participant.email === this.currentUser.email);
          if (this.isUserPotentialParticipant) {
            this.isUserRegisterForEvent = true;
          }
        }
  
        if (this.event.formerPotentialParticipants) {
          this.isUserFormerParticipant = this.event.formerPotentialParticipants.some((participant) => participant.email === this.currentUser.email);
          if (this.isUserFormerParticipant) {
            this.isUserRegisterForEvent = false;
            this.isUserFormerPotentialParticipant = true;
          }
        }
      }
    }

    if (this.event.tickets) {
      this.availableTickets = (this.event.tickets.totalAmount - this.event.tickets.available);
    }
    if (this.event.potentialUsers && this.event.potentialUsers.length) {
      this.seats = this.event.potentialUsers.length + this.event.participants.length;
      this.participants = [...this.event.participants, ...this.event.potentialUsers];
    } else {
      this.seats = this.event.participants.length;
      this.participants = this.event.participants;
    }

    if (this.event.eventStatus === Statuses.CANCELED) {
      this.notificationService.error(
        "Cancelled event",
        "This event has been cancelled"
      );
      this.router.navigate(["/events"]);
    }

    if (
      this.participants &&
      this.participants.length > this.participantsLimit
    ) {
      this.participantsToShow = this.participants.splice(
        0,
        this.participantsLimit
      );
      this.remainingNumberOfParticipants = this.participants.length;
    } else {
      this.participantsToShow = this.participants;
      this.remainingNumberOfParticipants = 0;
    }

    if ((this.event.entranceFee && this.event.tickets) && this.participantsToShow.length < this.availableTickets) {
      const remainParticipants = this.setUnclaimedTicketsAsDummyUsers(this.event, this.participantsToShow, this.availableTickets);
      const totalParticipants = [...this.participantsToShow, ...remainParticipants];
      this.participantsToShow = totalParticipants;
      if (
        totalParticipants &&
        totalParticipants.length > this.participantsLimit
      ) {
        this.participantsToShow = totalParticipants.splice(
          0,
          this.participantsLimit
        );
        this.remainingNumberOfParticipants = totalParticipants.length;
      } else {
        this.participantsToShow = totalParticipants;
        this.remainingNumberOfParticipants = 0;
      }
    }

    const timeZone = this.event.venue.timeZone ? this.event.venue.timeZone : 'America/New_York' ;
    const startZ = this.convertToTimezone(this.event.startDate, timeZone);
    const endZ = this.convertToTimezone(this.event.endDate, timeZone);
    this.event.startDate = startZ;
    this.event.endDate = endZ;
  }
  getEventLocation(venue: any, event: EventModel) {
    let location = venue.location
    if(venue.needLocation) {
      location = (event.location.latitude && event.location.longitude) ? event.location : venue.location
    }
    this.eventLocation = location
  }

  convertToTimezone(date: string | Date, timezone: string) {
    return momentTz(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
  }

  setUnclaimedTicketsAsDummyUsers = (reservationInfo, attendees, availableTickets) => {
    if (
      !reservationInfo ||
      !reservationInfo.entranceFee ||
      !reservationInfo.tickets
    ) {
      return attendees;
    }
    const forGuests = (availableTickets - attendees.length);
    const usersWithdummyTicketUsers = [];
    if (forGuests) {
      for (let index = 0; index < forGuests; index++) {
        const userEmail = 'dummy';
        usersWithdummyTicketUsers.push(userEmail);
      }
    }
    return usersWithdummyTicketUsers;
  };

  loadMap() {
    const mapProp = {
      center: new google.maps.LatLng(44.787197, 20.457273),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      streetViewControl: false,
      disableDoubleClickZoom: true,
      zoomControl: false,
      scrollwheel: false,
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    const icon = {
      path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
      fillColor: "#242847",
      fillOpacity: 0.9,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 0.55,
    };
    this.marker = new google.maps.Marker({
      position: {
        lat: this.event.venue.location.latitude,
        lng: this.event.venue.location.longitude,
      },
      map: this.map,
      draggable: false,
      icon,
    });
    this.map.setCenter(this.marker.getPosition());
  }

  getImages() {
    if (!!this.event.images && !!this.event.images.length) {
      this.images = this.event.images;
      this.imagesReady = true;
      return;
    }
    if (!!this.event.mainImage) {
      this.images = [this.event.mainImage];
      this.imagesReady = true;
      return;
    }
    this.images = this.event.venue.gallery;
    this.imagesReady = true;
    return;
  }

  isParticipant() {
    if (!this.currentUser) {
      return false;
    }
    return !!this.event.participants.find((x) => x.id === this.currentUser.id);
  }

  isEventEnded(){
    const now = moment();
    const endDate = moment(this.event.startDate).add(6, "hours").toISOString();
    return now.isAfter(endDate) || this.event.eventStatus === Statuses.COMPLETED
  }

  isEventActive() {
    const now = moment();
    // tslint:disable-next-line:max-line-length
    if (
      this.event.eventStatus === Statuses.APPROVED ||
      this.event.eventStatus === Statuses.PENDING ||
      this.event.eventStatus === Statuses.ACTIVE
    ) {
      if (!this.event.endDate) {
        const endDate = moment(this.event.startDate)
          .add(6, "hours")
          .toISOString();
        return now.isBefore(endDate);
      }
      return now.isBefore(this.event.endDate);
    } else {
      return false;
    }
  }

  getEventAddress() {
    if (this.eventLocation && this.eventLocation.address) {
      return { address: this.eventLocation.address, name: this.eventLocation.name };
    }
    return { address: this.eventLocation.address, name: this.venueNeighborhood };
  }

  openGetTheAppPopup() {
    const id: string = this.route.snapshot.paramMap.get("id");
    this.router.navigateByUrl(`/rsvp/${id}`);
  }

  async getNeighborhood() {
    // tslint:disable-next-line:max-line-length
    const neighborhoods = await this.enumService.getByTypes(neighborhood);
    if (!neighborhoods || !this.event.venue.neighborhood) {
      return (this.venueNeighborhood = "New York");
    }
    const element = neighborhoods.find(
      (n) => n.key === this.event.venue.neighborhood
    );
    this.venueNeighborhood =
      element && element.value ? element.value : this.event.venue.neighborhood;
  }

  async getAllEventsForUser() {
    this.events = await this.eventService.search({
      participants: this.currentUser.email,
    });
  }

  async openGoogleRatingInBrowser(venue) {
    const googlePlaceId = await this.venueService.getVenuesGoogleInfo(venue.id);
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      venue.name
    )}&query_place_id=${googlePlaceId}`;
    if (!googlePlaceId) {
      return;
    }
    return window.open(url, "_blank");
  }

  async handleCancelRegistration(value: boolean) {

    if (this.event.eventId) {
      this.event.id = this.event.eventId;
    };

    try {
      const res = await this.eventService.leaveEvent(this.event, this.currentUser.email, this.isUserPotentialParticipant);
      if (res) {
        this.isUserRegisterForEvent = false;
        this.isUserFormerParticipant = true;
      }
    } catch (error) {
      const errorHost = "Host can not leave reservation unless there is a co-Host assigned"
      if (error.statusCode === 400 && error.data === errorHost) {
        const msg = `Hosts can only cancel Tables using the app. Please use the app to proceed`
        this.notificationService.clear();
        this.notificationService.error(msg);
      }
    }

  }

  async handleCreateRegistration(value: boolean) {
    if (this.event.eventId) {
      this.event.id = this.event.eventId;
    };

    if (this.isUserPotentialParticipant || this.isUserFormerPotentialParticipant) {
      await this.eventService.JoinPotentialUSer(this.event.eventId, this.currentUser.email).catch(e => this.notificationService.error('Error joining the potential user to the table'));
      this.isUserRegisterForEvent = true;
      this.isUserFormerParticipant = false;
      this.cr.detectChanges();
      return;
    }

    const res = await this.eventService.sendJoinRequest(this.event.id, {
      userId: this.currentUser.id,
      venueId: this.event.venue.id,
      platform: Platforms.WEB
    });

    if (res && this.event.autoJoin) {
      this.isUserRegisterForEvent = true;
      this.isUserFormerParticipant = false;
    }

    this.cr.detectChanges();
  }
}
