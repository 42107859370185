import { Injectable } from "@angular/core";
import { ApiService } from "../common/api.service";

@Injectable()
export class PaymentService {
  constructor(private apiService: ApiService) {}

  createCustomer(
    name: string,
    email: string,
    address: any,
    source: any,
    saveSource: boolean
  ): Promise<any> {
    return this.apiService.post(
      `/payment/customer`,
      {
        name,
        email,
        address,
        source,
        hideSource: !saveSource,
      },
      []
    );
  }

  createAccount(email: string, country: string): Promise<any> {
    return this.apiService.post(
      `/payment/merchant`,
      {
        country,
        email,
      },
      []
    );
  }

  async getTicketExpensesConfig(): Promise<any> {
    const config = await this.apiService.get(`/ticket-expenses-config`, []);
    return config;
  }

  async getUserInvoice(eventId: string, userId: string, token?: string): Promise<any>{
    return await this.apiService.get(`/event/${eventId}/participants/${userId}/invoice`,
      token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : []
    )
  }
  async chargeToPaymentMethod(payload: {userId: string, pmId: string, eventId: string}, token?: string) {
    return await this.apiService.post(
      `/payment/charge/pm`,
      payload,
      token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : []
    )
  }
  
  async payAllOrderOfUser(eventId: string, userId: string, payload: any, token?: string): Promise<any>{
    return await this.apiService.post(`/payment/event/${eventId}/user/${userId}`, payload,
      token ? [ { key: 'Authorization', value: `Bearer ${token}` } ] : []
    )
  }
}

export interface IChargeData {
  amount: number;
  currency: string;
  source: string;
  description: string;
  vendor: string;
  customer: string;
  date: string;
  useCredit: boolean;
  tips: number;
  eventCreator: boolean;
}
