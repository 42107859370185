import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AutomapperService } from "src/app/common/automapper.service";
import { NotificationService } from "src/app/common/notification.service";
import { StorageService } from "src/app/common/storage.service";
import { OrderStatuses } from "src/app/data/order.statuses.data";
import { MenuItemModel } from "src/app/models/menu-item.model";
import { OrderModel } from "src/app/models/order.model";
import { UserModel } from "src/app/models/user.model";
import { OrderService } from "src/app/order/order.service";

@Component({
  selector: "app-payment-tab-my-orders",
  templateUrl: "./tab.my.order.payment.component.html",
  styleUrls: [
    "../tab.payment/tab.payment.component.scss",
    "./tab.my.order.payment.component.scss",
  ],
})
export class QRTabMyOrders implements OnInit {
  @Input() user!: UserModel;
  @Input() orders: OrderModel[] = [];

  @Output() navigateToMenu = new EventEmitter<void>();
  @Output() goToPay = new EventEmitter<string>()

  eventId!: string;
  venueSlug!: string;
  tableNumber!: string;
  ordersToPay!: OrderModel[];
  categorizedOrders!: { [key: string]: OrderModel[] };
  subTotal = 0;
  total = 0;
  tipsTotal = 0;
  taxTotal = 0;
  totalPaid = 0;
  TYPE_OF_PAY = "My Order"
  isLoading!: boolean
  allOrders!: OrderModel[];

  navigates = {
    MENU: 'menu',
    PAY: 'pay'
  }

  async ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get("eventId");
    this.venueSlug = this.route.snapshot.paramMap.get("slug");
    this.tableNumber = this.route.snapshot.paramMap.get("tableNo");
    this.renderItems()
  }
  
  async renderItems() {
    await this.getAllOrders();
    await this.getMyOrders();
    this.categorizeOrders();
    this.calculateTotals();
  }
  async getMyOrders() {
    const orders = []

    this.allOrders.filter((order) => {
      if(order.payers.some(p => p.userId === this.user.id)){
        orders.push(order)
      }
    })

    this.orders = orders

    this.getUnPaidOrders();
  }

  getUnPaidOrders(){
    this.ordersToPay = this.orders.filter(
      (order) => {
        const payer = order.payers.find(p => p.userId === this.user.id)
        return !payer.transactionId && !this.isOrderCompletedOrPaid(order.orderStatus)
      }
    );
  }
  async getAllOrders() {
    this.allOrders = await this.orderService.getOrders(
      this.eventId,
      null,
      this.user.token
    );
  }

  isPaid(order: OrderModel) {
    return this.isOrderCompletedOrPaid(order.orderStatus);
  }

  handleRemoveItem(event: {order: OrderModel, item: MenuItemModel}) {
    if(event.order.userId === this.user.id || event.order.payers.length === 1) {
      this.onRemoveItem(event.order, event.item)
    }else {
      this.removeParticipantFromItem(event.order, event.item)
    }

  }

  async onRemoveItem(order: OrderModel, item: MenuItemModel){
    try {
        await this.orderService.deleteOrderById(order.id, this.user.token)
        this.notificationService.success('Order Deleted succesfully')
        this.orders = this.orders.filter(order => order.id !== order.id)
        this.renderItems()
    } catch (error) {
      
    }
  }

  removeParticipantFromItem(order: OrderModel, item: MenuItemModel){
    const payers = order.payers.filter(p => p.userId !== this.user.id)
    let percentage = 100 / payers.length
    const payersToUpdate = payers.map(p => {
      p.percentage = percentage
      return p
    })
    const orderToUpdate = {
      ...order, 
      payers: payersToUpdate
    }
    this.orderService.updateOrder(order.id, orderToUpdate, this.user.token)
    return this.renderItems()
  }

  showCloseTab() {
    const orders = this.ordersToPay;
    if(!orders || !orders.length) {
      return false
    }
    const sw =  
      (orders && !orders.length) ||
      orders.some((order) =>
        this.isAbleToPay(order)
      )
    ;
    return sw
  }
  isAbleToPay(order: OrderModel): boolean {
    const payer = order.payers.find(p=> p.userId === this.user.id)
    return !this.isOrderCompletedOrPaid(order.orderStatus) && !payer.holded
  }

  isOrderCompletedOrPaid(orderStatus: string) : boolean {
    return [
      OrderStatuses.PAID,
      OrderStatuses.REJECTED,
      OrderStatuses.COMPLETED,
    ].includes(orderStatus)
  }

  navigate(type: string) {
    switch (type) {
      case this.navigates.MENU:
        return this.navigateToMenu.emit();

      case this.navigates.PAY:
        let subTotal = 0, taxTotal = 0;
        this.ordersToPay.forEach((order) => {
          const payer = order.payers.find((p) => p.userId === this.user.id )
          subTotal += payer ? (order.price.value * (payer.percentage / 100)) : order.price.value;
          taxTotal += payer ? (order.price.taxTotal * (payer.percentage / 100)) : order.price.taxTotal;
        });
        const payload = {
          orders: this.ordersToPay,
          subTotal: subTotal,
          orderTaxes: taxTotal,
          allOrder: false
        }
        this.storageService.setItem("ordersToPay", payload)
        return this.goToPay.emit(this.TYPE_OF_PAY)
    }
  }

  categorizeOrders() {
    const categorized = {};
    this.orders.forEach((order) => {
      const type = order.items[0].species;
      if (!categorized[type]) {
        categorized[type] = [];
      }
      categorized[type].push(order);
    });
    this.categorizedOrders = categorized;
  }
  reloadOrders(){
    this.renderItems()
  }

  onClick(event: {order: OrderModel, item: MenuItemModel}) {
    return this.router.navigate([`/mobile-ordering/menu-item/${this.venueSlug}/${event.item.menuItemId}/${this.tableNumber}/${this.eventId}`])
  }

  calculateTotals() {
    let subTotal = 0
    let total = 0
    let tipsTotal = 0
    let taxTotal = 0
    let totalPaid = 0

    this.orders.forEach((order) => {
      const payer = order.payers.find((p) => p.userId === this.user.id )
      subTotal += payer ? (order.price.value * (payer.percentage / 100)) : order.price.value;
      total += payer ? (order.price.total * (payer.percentage / 100)) : order.price.total;
      tipsTotal += order.price.tipsTotal;
      taxTotal += payer ? (order.price.taxTotal * (payer.percentage / 100)) : order.price.taxTotal;
    });

    const ordersPaid = this.orders.filter(
      (order) =>
        !this.ordersToPay.some((orderToPay) => order.id === orderToPay.id)
    );

    ordersPaid.forEach((order) => {
      totalPaid += order.price.total;
    });

    this.subTotal = subTotal
    this.total = total
    this.tipsTotal = tipsTotal
    this.taxTotal = taxTotal

    this.totalPaid = totalPaid
  }
  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private automapperService: AutomapperService,
    private router: Router
  ) {}
}
