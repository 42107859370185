import { Injectable } from '@angular/core';
import { ApiService } from '../../common/api.service';
import { BehaviorSubject } from 'rxjs';
import { MenuItemModel, MenuItemOptionModel } from 'src/app/models/menu-item.model';
import { StorageService } from 'src/app/common/storage.service';

@Injectable({
  providedIn: 'root',
})
export class BasketService {

  private basket: BehaviorSubject<IBasket> = new BehaviorSubject<IBasket>({
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    items: [],
    total: 0
  });

  constructor(
    private apiService: ApiService, private storageService: StorageService) {
  }

  addToBasket(item: MenuItemModel, option: MenuItemOptionModel, priority?: string): BehaviorSubject<IBasket> {
    const currentBasket = this.basket.getValue();
    let addonsPrice = 0;
    const addonsWithPrice = [];
    const addons = [];
    if (item.addonsCategories && item.addonsCategories.length) {
      item.addonsCategories.forEach(ac => ac.addons.forEach(addon => {
        if (addon.includeAddon) {
          addons.push({ ...addon });
          if (!!addon.addonTotal) {
            addonsWithPrice.push({ ...addon });
          }
        }
      }));
      if (addonsWithPrice && addonsWithPrice.length) {
        addonsWithPrice.forEach(a => addonsPrice += a.addonTotal * a.quantity * option.quantity);
      }
    }
    currentBasket.items.push({
      menuItemId: item.id,
      optionId: option.id,
      name: `${item.name} - ${option.name}`,
      price: option.price.value * option.quantity + addonsPrice,
      quantity: option.quantity,
      addons,
      specialInstructions: option.specialInstructions,
      priority
    });

    currentBasket.total += (option.price.value * option.quantity + addonsPrice);

    this.basket.next(currentBasket);

    return this.basket;
  }
  setBasket(basket: IBasket): BehaviorSubject<IBasket> {
    this.basket.next(basket)
    return this.basket
  }

  updateContact(firstName: string, lastName: string, email: string, phone: string) {
    const currentBasket = this.basket.getValue();

    currentBasket.firstName = firstName;
    currentBasket.lastName = lastName;
    currentBasket.email = email;
    currentBasket.phone = phone;

    this.basket.next(currentBasket);

    return this.basket;
  }

  removeFromBasket(item: IBasketItem): BehaviorSubject<IBasket> {
    const currentBasket = this.basket.getValue();

    const index = currentBasket.items.indexOf(item);

    const removedItem = currentBasket.items.splice(index, 1);

    currentBasket.total -= (removedItem[0].price * removedItem[0].quantity);

    this.basket.next(currentBasket);

    return this.basket;
  }

  clearBasket() {
    const currentBasket = this.basket.getValue();

    const removedItem = currentBasket.items.length = 0;

    currentBasket.total = 0;

    this.basket.next(currentBasket);

    this.storageService.deleteItem('temporary-basket');
    return this.basket;
  }

  getBasket(): BehaviorSubject<IBasket> {
    return this.basket;
  }

  getBasketValue(): IBasket {
    return this.basket.getValue();
  }
}

export interface IBasket {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  items: IBasketItem[];
  total: number;
}

export interface IBasketItem {
  menuItemId: string;
  optionId: string;
  name: string;
  price: number;
  quantity: number;
  addons?: any[];
  specialInstructions?: string;
  priority?: string;
}
