import {
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";
import { VenueModel } from "../models/venue.model";
import { ActivatedRoute, Router } from "@angular/router";
import { VenueService } from "../venue/venue.service";
import { StorageService } from "../common/storage.service";
import { EventService } from "../event/event.service";
import { UserModel } from "../models/user.model";
import { Statuses } from "../data/statuses.data";
import { EventModel } from "../models/event.model";
import { UserService } from "../user/user.service";
import { Platforms } from '../event/tickets/ticket.enum';
import { SessionStorageService } from "../common/sessionStorage.service";

@Component({
  selector: "app-qr-payment-page",
  templateUrl: "./qr.payment.page.component.html",
  styleUrls: ["./qr.payment.page.component.scss"],
})
export class QrPaymentPageComponent implements OnInit, AfterViewInit {
  tableNumber: string;
  TABS = {
    details: "details",
    menu: "menu",
    payment: "payment",
  };
  menueTabs = [];
  currentTab = this.TABS.details;
  venue: VenueModel;
  user: UserModel;
  event: EventModel;
  userName = "";
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private venueService: VenueService,
    private eventService: EventService,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private router: Router
  ) {}

  async ngOnInit() {
    const slug = this.route.snapshot.paramMap.get("slug");
    const tableNo = this.route.snapshot.paramMap.get("tableNo");

    this.storageService.deleteItem("temporary-user");
    const user = await this.sessionStorageService.getItem("temporary-user");
    const venue = await this.venueService.getBySlug(slug);
    this.venue = venue;
    this.tableNumber = tableNo;

    await this.getActiveTable()

    if(user) {
      await this.getUser(user.id, user.token)
      this.handleRedirect()
    }
    this.loading = false
  }

  async createUser() {
    const newUser = await  this.userService.createDinerTemporaryUser(this.userName);
    const loggedUser = await this.userService.logDinerTemporaryUser(newUser.id);
    this.sessionStorageService.setItem("temporary-user", loggedUser);
    this.user = loggedUser;
  }
  async getUser(id: string, token: string) {
    this.user = await this.userService.getById(id, token)
    this.user.token = token
  }
  async getActiveTable() {
    const events = await this.eventService.getEventsByVenueAndTable(
      this.venue.id,
      this.tableNumber
    );
    if (events.length) {
      this.event = events[0];
    }
  }
  handleRedirect() {
    if(this.event && this.existsUserInParticipants(this.user.id, this.event.participants || [])) {
      this.navigateToYourTabScreen(this.event.eventId)
    }
  }


  async handleTable() {
    const name = this.user ? `${this.user.firstName} ${this.user.lastName}` : '';
    if (!this.user || name !== this.userName) {
        await this.createUser();
    }
    if(!this.event) {
      await this.getActiveTable()
    }
    if (this.event) {
      const payload = {
        venueId: this.venue.id,
        userId: this.user.id,
        isDinerTemporaryTable: true,
        platform: Platforms.WEB
      };
      if(this.user.id !== this.event.ownerId && !this.existsUserInParticipants(this.user.id, this.event.participants || [])) {
        try {
          await this.eventService.sendJoinRequest(this.event.eventId, payload, this.user.token);
        } catch (error) {
          console.log(error);
          return;
        }
      }
      return this.navigateToMenu(this.event.eventId);
    } else {
      await this.createTable();
    }
  }
  existsUserInParticipants(userId: string, participants): boolean {
    return participants.some((participant) => (
      participant === userId || participant.id === userId
    ));
  }

  async createTable() {
    const formattedDate = this.formattedDate();
    const payload = {
      ownerId: this.user.id,
      venueId: this.venue.id,
      name: `Event for table ${this.tableNumber} on ${formattedDate}`,
      eventStatus: Statuses.ACTIVE,
      table: this.tableNumber,
      isDinerTemporaryTable: true,
      autoJoin: true,
    };
    const event = await this.eventService.createEventForTempUsers(payload, this.user.token);
    this.event = event;
    this.navigateToMenu(event.id)
  }
  navigateToYourTabScreen(eventId: string){
    if(!this.user && !this.user.payment && !this.user.payment.checkoutSession.length) return

    const checkoutSession = this.user.payment.checkoutSessions.find((cs) => {
      return cs.eventId === eventId && cs.status === "complete" && cs.paymentMethod;
    });

    if(checkoutSession){
      return this.router.navigate([`/mobile-qr/${this.venue.slug}/${this.tableNumber}/${eventId}/${checkoutSession.id}`])
    }
  }

  navigateToMenu(eventId: string) {
    return this.router.navigate([`/mobile-ordering/menu/${this.venue.slug}/${this.tableNumber}/${eventId}`]);
  }

  formattedDate() {
    const date = new Date();
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`;
    return formattedDate;
  }
  async viewTheMenu() {
    await this.handleTable();
  }

  async ngAfterViewInit() {}
}
