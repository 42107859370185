import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { GenericFormComponent, GenericFormType } from 'src/app/generic-form/generic.form.component';
import { PaymentCreateComponent } from 'src/app/payment/payment.create.component';
import { GenericFormBuilder } from 'src/app/generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { StripeCard } from 'stripe-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueModel } from 'src/app/models/venue.model';
import { VenueService, ITipsRule } from 'src/app/venue/venue.service';
import { BasketService, IBasket } from '../basket/basket.service';
import { UserService } from 'src/app/user/user.service';
import { AuthService } from 'src/app/common/auth.service';
import { OrderService } from 'src/app/order/order.service';
import { NotificationService } from 'src/app/common/notification.service';
import { UserModel } from 'src/app/models/user.model';
import { Countries } from 'src/app/data/countries.data';
import { AutomapperService } from 'src/app/common/automapper.service';
import { MobileService } from '../mobile.service';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/event/event.service';

@Component({
  selector: 'app-mobile-ordering-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class MobileOrderingCheckoutComponent implements OnInit, AfterViewInit {

  @ViewChild('mayInfoForm', { static: false }) mayInfoForm: GenericFormComponent;
  @ViewChild('addressForm', { static: false }) addressForm: GenericFormComponent;
  @ViewChild('stripeCard', { static: false }) stripeCard: StripeCard;

  options: any = {
    hidePostalCode: true,
    disableLink:true
  };
  venue: VenueModel;
  taxValue = 8.875;
  table: string;
  eventId: string;
  selectedPayment: string;
  availableTips = [18, 20, 25];
  selectedTip: number;
  customTipAmount: string;
  basket: IBasket;
  payInProgress: boolean;
  loggedInUser: UserModel;
  selectedCard: any;
  paymentOptions: any[];
  tipsRule: ITipsRule;
  saveCard = false;
  tableNumber: string = this.mobileService.tableNumber;
  tableNumberMandatory: boolean = false;
  event: any;
  participants = [];

  myInfoRules: GenericFormType[] = [
    GenericFormBuilder.text('firstName', 'First Name', [Validators.required], false, null),
    GenericFormBuilder.text('lastName', 'Last Name', [Validators.required], false, null),
    GenericFormBuilder.text('email', 'Email', [Validators.required], false, null),
  ];

  addressFormRules: GenericFormType[] = [
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown('state', 'Country', [Validators.required], [{ value: null, label: 'Please select country' }, ...Countries.map((x) => ({ value: x.code, label: x.name }))], false, null),
    GenericFormBuilder.text('postalCode', 'Zip Code', [Validators.required], false, null),
  ];

  constructor(
    private basketService: BasketService,
    private activeRoute: ActivatedRoute,
    private venueService: VenueService,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private orderService: OrderService,
    private notificationService: NotificationService,
    private autoMapper: AutomapperService,
    private mobileService: MobileService,
    private eventService: EventService,
  ) { }

  async ngOnInit() {
    const venueSlug = this.activeRoute.snapshot.paramMap.get('slug');
    this.table = this.activeRoute.snapshot.paramMap.get('table');
    // this.eventId = this.activeRoute.snapshot.paramMap.get('id');
    this.eventId = this.mobileService.eventId;
    this.event = await this.eventService.getWebEvent(this.eventId);
    this.tableNumberMandatory = await this.mobileService.getEventTableNumberMandatory(this.eventId);
    this.selectedPayment = 'card';
    this.basketService.getBasket().subscribe((value) => {
      this.basket = value;
    });
    this.payInProgress = false;
    await this.loadVenue(venueSlug);
    this.tipsRule = await this.venueService.getVenueTipsRule(this.eventId, 1);
    this.availableTips = this.tipsRule.percentages ? this.tipsRule.percentages : this.availableTips;
    const selectTip = Math.min(...this.availableTips);
    this.selectTip(selectTip);
  }

  async ngAfterViewInit() {
    if (this.mayInfoForm && this.basket) {
      this.mayInfoForm.myForm.get('firstName').setValue(this.basket.firstName);
      this.mayInfoForm.myForm.get('lastName').setValue(this.basket.lastName);
      this.mayInfoForm.myForm.get('email').setValue(this.basket.email);
    }
    this.loggedInUser = this.authService.getCurrentUser();
    if (this.loggedInUser) {
      this.mayInfoForm.myForm.get('firstName').setValue(this.loggedInUser.firstName);
      this.mayInfoForm.myForm.get('lastName').setValue(this.loggedInUser.lastName);
      this.mayInfoForm.myForm.get('email').setValue(this.loggedInUser.email);
      this.loggedInUser = await this.authService.refreshUserData();
      // tslint:disable-next-line:max-line-length
      this.paymentOptions = this.loggedInUser.payment && this.loggedInUser.payment.sources ? this.loggedInUser.payment.sources.filter(x => !x.hidden) : [];
      this.selectedCard = this.paymentOptions && this.paymentOptions.length ? this.paymentOptions[0] : null;
    }
  }
  @HostListener('window:unload')
  unRecharge() {
    this.deletePaymentInfo();
  }

  handleChange(e: any) {
    this.changeTableNumber(e.target.value)
  }

  changeTableNumber(tableNumber: string){
    this.mobileService.tableNumber = tableNumber;
  }

  selectCard(card: any) {
    this.selectedCard = card;
  }

  selectPaymentOption(option: string) {
    this.selectedPayment = option;
  }

  getItemsSubtotal() {
    if (!this.basket || !this.basket.items || !this.basket.items.length) {
      return 0;
    }
    let sum = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.basket.items.length; i++) {
      sum += this.basket.items[i].price;
    }
    return sum;
  }

  getItemsTax() {
    const sum = this.getItemsSubtotal();
    return Number(sum * (this.taxValue / 100));
  }

  getTipsAmount() {
    if (this.tipsRule.noTipsAllowed) {
      return 0;
    }
    if (!this.selectedTip) {
      return 0;
    }
    if (this.selectedTip === -1) {
      return this.customTipAmount ? Number(this.customTipAmount) : 0;
    }
    const sum = this.getItemsSubtotal();
    return Number(sum * (this.selectedTip / 100));
  }

  getTotalAmount() {
    const subTotal = this.getItemsSubtotal();
    const tax = this.getItemsTax();
    const tips = this.getTipsAmount();
    return Number(subTotal + tax + tips);
  }

  getTipAndTax() {
    const tax = this.getItemsTax();
    const tips = this.getTipsAmount();
    return Number(tax + tips);
  }

  selectTip(tip) {
    if (this.selectedTip !== tip) {
      this.selectedTip = tip;
    }
    this.customTipAmount = '0';
  }

  toggleSaveCard() {
    this.saveCard = !this.saveCard;
  }

  async payOrder() {
    if (!this.mayInfoForm.isValid()) {
      this.notificationService.error('There are some information missing');
      window.scroll(0, 150);
      return;
    }
    const infoData = this.mayInfoForm.toRaw();
    let seats = 0;
    if (this.event.potentialUsers) {
      seats = this.event.potentialUsers.length + this.event.participants.length;
    }else{
      seats = this.event.participants.length;
    }
    if (seats === this.event.maxParticipants && !this.event.entranceFee && !this.isParticipant(infoData.email)
    ) {
      this.notificationService.error('*This event is full.');
      return;
    }
    if (this.event && this.event.entranceFee && this.event.tickets) {
      let ticket = false;
      if (this.loggedInUser) {
        ticket = await this.eventService.userHasTicket(this.eventId, this.loggedInUser.id);
      }else{
        ticket = await this.eventService.guestUserHasTicket(this.eventId, infoData.email);
      }
      if (!ticket) {
        this.notificationService.error('*For this event, a ticket purchase is required. If you\'ve already bought a ticket, please enter the same email address you used during your purchase.');
        return;
      }
    }
    if (this.tableNumberMandatory && !this.mobileService.tableNumber) {
      this.notificationService.warning("This event requires a table number");
      return;
    }
    try {
      this.payInProgress = true;

      if (!this.loggedInUser || this.loggedInUser.role.toLowerCase() === 'guest') {
        const user = await this.userService.createPotentialUser(
          infoData.firstName,
          infoData.lastName,
          infoData.email,
          this.eventId,
        ) as any;
        this.loggedInUser = this.authService.setCurrentUser(user);
        this.loggedInUser = await this.authService.refreshUserData();
        const userData = {
          firstName: infoData.firstName,
          lastName: infoData.lastName,
          email: infoData.email,
        }
        localStorage.setItem('userData', JSON.stringify(user));
      }
    } catch (err) {
      console.log(err, 'mobile ordering error');
      this.notificationService.error('Failed to create order');
      this.payInProgress = false;
      return;
    }
    const totalPrice = this.getTotalAmount();
    const tipAndTaxes = this.getTipAndTax();
    const tips = this.getTipsAmount();
    const tax = this.getItemsTax();
    let userId = null;
    if (this.loggedInUser) {
      userId = this.loggedInUser.id;
    }
    const homePague = environment.homePage;
    const successUrl = `${homePague}/mobile-ordering/redirect/${this.eventId}/{CHECKOUT_SESSION_ID}`;
    const cancelUrl = `${homePague}/mobile-ordering/home/${encodeURIComponent(this.venue.slug)}/${encodeURIComponent(this.table)}/${this.eventId}`;
    const metadata = {
      description: `Web order for event: ${this.table}`,
      venueId: this.venue.id,
      venueSlug: this.venue.slug,
      table: this.table,
      tips: tips,
      taxs: tax,
      tableNumber: this.tableNumber,
    }
    const response = await this.orderService.createCheckoutLink(this.basket.items, totalPrice, tipAndTaxes, userId, successUrl, cancelUrl, metadata);
    if (response) {
      localStorage.setItem('checkout-items', JSON.stringify(this.basket.items));
      window.location.href = response.url;
    }else{
      this.notificationService.error('An error occurred while accessing the checkout');
      return;
    }
  }
  private deletePaymentInfo () {
    window.localStorage.removeItem("user")
  }

  goBack() {
    const infoData = this.mayInfoForm.toRaw();
    this.basketService.updateContact(infoData.firstName, infoData.lastName, infoData.email, infoData.phone ? infoData.phone.number : null);
    this.router.navigateByUrl(`/mobile-ordering/basket/${this.venue.slug}/${this.table}`);
  }

  private async loadVenue(slug) {
    this.venue = await this.venueService.getBySlug(slug);
  }

  setUnclaimedTicketsAsDummyUsers = (reservationInfo, attendees, availableTickets) => {
    if (
      !reservationInfo ||
      !reservationInfo.entranceFee ||
      !reservationInfo.tickets
    ) {
      return attendees;
    }
    const forGuests = (availableTickets - attendees.length);
    const usersWithdummyTicketUsers = [];
    if (forGuests) {
      for (let index = 0; index < forGuests; index++) {
        const userEmail = 'dummy';
        usersWithdummyTicketUsers.push(userEmail);
      }
    }
    return usersWithdummyTicketUsers;
  };

  isParticipant(email: string) {
    return !!this.event.participants.find((x) => x.email === email);
  }
}
