import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { MenuService } from './menu.service';
import { BsModalRef } from 'ngx-bootstrap';
import { MenuModel } from '../models/menu.model';
import { VenueModel } from '../models/venue.model';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';

@Component({
  selector: 'app-menu-category-edit',
  templateUrl: './menu-category.edit.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuCategoryEditComponent implements OnInit, AfterViewInit {

  @ViewChild('menuCategoryForm', { static: false }) menuCategoryForm: GenericFormComponent;

  updateInProgress: boolean;
  venue: VenueModel;
  menu: MenuModel;
  updateRules: GenericFormType[];
  loadMenus: any;
  getMenuTypes: any;
  currentType: string;

  constructor(
    private bsModalRef: BsModalRef,
    private menuService: MenuService
  ) { }

  async ngOnInit() {
    this.updateInProgress = false;
    this.updateRules = [
      GenericFormBuilder.text('name', 'Name', [], this.menu.type === 'happyHour'),
      GenericFormBuilder.number('priority', 'Priority (1 - highest prio)', []),
      GenericFormBuilder.switch('disabled', 'Hide'),
    ];
  }

  ngAfterViewInit() {
    this.menuCategoryForm.fromModel<MenuModel>('MenuModel', 'MenuCategoryEditForm', this.menu);
  }

  async update() {
    this.updateInProgress = true;
    try {
      let newMenu: MenuModel = {
        venueId: this.venue.id,
        ... this.menu,
        ... this.menuCategoryForm.toModel<MenuModel>('MenuCategoryEditForm', 'MenuModel')
      };
      newMenu = await this.menuService.updateMenuByType(this.venue.id, newMenu);
      console.log(newMenu);
      this.loadMenus();
      this.getMenuTypes();
      this.updateInProgress = false;
      this.bsModalRef.hide();
    } catch (error) {
      this.updateInProgress = false;
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  dataChanged(data) {
    this.currentType = data.type;
  }

}
