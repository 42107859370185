import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {
  constructor() {}

  public setItem(key: string, data: any): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  public getItem(key: string): any {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (e) {
      console.error('Error getting data from sessionStorage', e);
      return null;
    }
  }

  public deleteItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  public clear(): void {
    sessionStorage.clear();
  }
}