import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageService } from "src/app/common/storage.service";
import { IBasket } from "src/app/mobile-ordering/basket/basket.service";
import { EventModel } from "src/app/models/event.model";
import { UserModel } from "src/app/models/user.model";
import { VenueModel } from "src/app/models/venue.model";

@Component({
  selector: "app-payment-success-modal",
  templateUrl: "./payment.success.modal.component.html",
  styleUrls: ["./payment.success.modal.component.scss"],
})
export class PaymentSuccesModalComponent {
  tableNumber!: string;
  venueSlug!: string;
  eventId!: string;
  sessionId!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.venueSlug = this.route.snapshot.paramMap.get('slug');
    this.tableNumber = this.route.snapshot.paramMap.get('tableNo');
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    this.sessionId = this.route.snapshot.paramMap.get('sessionId');
 
  }

  navigate() {
    return this.router.navigate([
      `/mobile-qr/${this.venueSlug}/${this.tableNumber}`,
    ]);
  }
}
