import { Component, OnInit } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public config: ToasterConfig = new ToasterConfig({ timeout: 15000, positionClass: 'toast-center', showCloseButton: true });
  hideHeader = false;
  hideFooter = false;
  shortSpace = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadRouteData();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.loadRouteData();
      }
    });
  }

  loadRouteData() {
    const data = this.route.firstChild.snapshot.data;
    this.hideHeader = !!data.hideHeader;
    this.hideFooter = !!data.hideFooter;
    this.shortSpace = !!data.shortSpace;
  }
}
